import React, { useContext, useEffect } from 'react';
import { Tabs } from 'antd';

import ContentWrapper from '../../../components/ContentWrapper';
import { AlarmsContext } from '../AlarmsDataProvider';
import AlarmsTable from '../components/AlarmsTable';

const UserAlarms = () => {
  const {
    activeAlarms,
    alarmsHistory,
    fetchActiveAlarms,
    fetchAlarmsHistory,
    deactivateAlarm,
  } = useContext(AlarmsContext);

  useEffect(() => {
    fetchActiveAlarms();
    fetchAlarmsHistory();
  }, []);

  return (
    <ContentWrapper style={{padding: '20px', height: '100%'}}>
      <Tabs>
        <Tabs.TabPane tab="Aktywne alarmy" key="alarms">
          <AlarmsTable
            alarms={activeAlarms}
            deactivateAlarm={deactivateAlarm}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Historia alarmów" key="history">
          <AlarmsTable alarms={alarmsHistory} isHistory />
        </Tabs.TabPane>
      </Tabs>
    </ContentWrapper>
  );
};

export default UserAlarms;
