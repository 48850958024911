import axios from 'axios';

export const postSignIn = async (email, password) => {
  const resp = await axios.post('/Account/login', {
    login: email,
    password,
  });
  return resp;
};

export const postSignInByToken = async () => {
  const resp = await axios.post('/Account/credential');
  return resp;
};

export const getEnums = async () => {
  const resp = await axios.get('/Enums');
  return resp;
};
