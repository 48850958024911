import React from 'react';

import { Button, Modal } from 'antd';

export default ({visible, onLoad, onCancel, ...props}) =>
    (<Modal 
        visible={visible}
        onCancel={onCancel}
        title="Czy chcesz załadować także dane ukryte na wykresie?"
        footer={[
            <Button key="back" onClick={onCancel}>
                Powrót
            </Button>,
            <Button key="submitNo" type="primary" onClick={() => onLoad(false)}>
                Nie
            </Button>,
            <Button key="submitYes" type="primary" onClick={() => onLoad(true)}>
                Tak
            </Button>,
          ]}
        {...props}
    >
        {`Dane ukryte na wykresie, lecz pobrane z bazy danych, mogą zostać ujęte w wygenerowanym pliku.`}
    </Modal>);
