import React from 'react';

import { ReactComponent as Logo } from './assets/logo.svg';
import { ReactComponent as LogoWhite } from './assets/logo_white.svg';

export const LOGO_SIZES = Object.assign({
    SMALL: 'small',
    REPORT_FRONT_PAGE: 'report-front-page',
    REPORT_HEADER: 'report-header',
    SIGN_IN: 'sign_in',
});

const sizes = {
    [LOGO_SIZES.SMALL]: {
        height: "56",
        width: "112",
    },
    [LOGO_SIZES.SIGN_IN]: {
        height: "144",
        width: "288",
    },
    [LOGO_SIZES.REPORT_FRONT_PAGE]: {
        height: "240",
        width: "480",
    },
    [LOGO_SIZES.REPORT_HEADER]: {
        height: "100",
        width: "200",
    },
}

export default ({size = LOGO_SIZES.SMALL, type, ...props}) => type === 'white' ? (
    <LogoWhite alt="KAPE monitoring" {...props} {...sizes[size]} />
) : (
    <Logo alt="KAPE monitoring" {...props} {...sizes[size]} />
);
