import React, { useState, useContext, Fragment } from 'react';
import { isNil, sortBy } from 'lodash';
import XLSX from 'xlsx';

import { Button, message } from 'antd';

import { AppContext } from '../../../AppDataProvider';
import ConfirmExcel from './ConfirmExcel';

const prepareKeys = (loadFalse, usedData) => [
    {
        key: 'time',
        description: 'Data',
    },
    ...usedData.filter(({show}) => loadFalse || show)
    .map(({dataSource, fields}) => ({
        key: dataSource,
        description: fields.description,
    })),
];

const mergeData = (loadedData) => loadedData.reduce((result, {id, data}) => [
    ...result,
    ...data.map(({time, ...rest}) => Object.keys(rest).reduce((obj, key) => ({
        ...obj,
        [`${id}-${key}`]: rest[key],
    }), { time })),
], []);

const makeDataArray = (sorted, keys) => {
    const check = keys.map(({key}) => key);
    return sorted.map(d => check.map(key =>isNil(d[key]) ? null : d[key]));
}

const prepareData = async (loadFalse, usedData, loadedData) => {
    return new Promise((resolve) => {
        const keys = prepareKeys(loadFalse, usedData);
        const mergedData = mergeData(loadedData);
        const sorted = sortBy(mergedData, 'time');
        const final = [];
        let value = null;
        for (let i = 0; i < sorted.length; i += 1) {
          if (sorted[i].time === value) {
            const last = final.pop();
            final.push({ ...last, ...sorted[i] });
          } else {
            final.push(sorted[i]);
          }
          value = sorted[i].time;
        }
        const formatted = makeDataArray(final, keys).map(([time, ...rest]) => [new Date(time), ...rest]);
        resolve([
            keys.map(({description}) => description),
            ...formatted]);
      });
}

export default ({loadedData = [], usedData = [], disabled}) => {
    const {
        startAsyncTask,
        finishAsyncTask,
      } = useContext(AppContext);
    const [askExcel, setAskExcel] = useState(false);
    const onLoadAttemp = () => {
        if(usedData.some(e => !e.show)) {
            setAskExcel(true);
        }
        else {
            onLoad(false);
        }
    }

    const onLoad = async (loadFalse) => {
        startAsyncTask();
        try {
            const data = await prepareData(loadFalse, usedData, loadedData);
            const ws = XLSX.utils.aoa_to_sheet(data, {cellDates: true});
            const wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "Dane pobrane z KAPE Monitor",
                Author: "KAPE Monitor",
                Company: "Krajowa Agencja Poszanowania Energii",
                CreatedDate: new Date(),
            }
            XLSX.utils.book_append_sheet(wb, ws, "Pobrane dane");
            XLSX.writeFile(wb, "kapemonitor.xlsx")
        }
        catch(e) {
            message.error('Generacja pliku .xlsx nie powiodła się.')
        }
        finishAsyncTask();
    }


    return (
        <Fragment>
            <Button
                disabled={disabled}
                onClick={onLoadAttemp}
                type="primary"
                size="large"
                icon="download"
            >
                Pobierz dane
            </Button>
            <ConfirmExcel
                visible={askExcel}
                onCancel={() => setAskExcel(false)}
                onLoad={(should) => {
                    onLoad(should);
                    setAskExcel(false);
                }}
            />
        </Fragment>);
}
