import React, { Component } from 'react';

const initialDashboardState = {
  buildings: [],
  currentBuildingId: null,
  currentTime: 'week',
};

const DashboardContext = React.createContext({});

const getBuildingPosition = (id, buildings) => buildings.findIndex(e => e.id === id);

class DashboardDataProvider extends Component {
  set = field => value => this.setState(() => ({ [field]: value }));

  getBuilding = (id) => this.state.buildings.find(e => e.id === id);

  currentBuilding = () =>
    this.state.currentBuildingId !== null ?
      this.getBuilding(this.state.currentBuildingId)
      :
      {
        finished: {},
      }

  getCurrentTime = () => this.state.currentTime;

  setBuildings = (buildings, id) => this.setState(() => ({
    buildings: buildings.map(building => ({...building, finished: {}})),
    currentBuildingId: id,
  }));

  setCurrentTime = this.set('currentTime');

  setCurrentBuilding = this.set('currentBuildingId');

  startFetching = (id, key) => {
    this.setState(({buildings}) => {
      const idx = getBuildingPosition(id, buildings);
      const finished = {
        ...buildings[idx].finished,
        [key]: false,
      };
      const nbuildings = [...buildings];
      nbuildings[idx] = {
        ...buildings[idx],
        finished,
      };
      return ({
        buildings: nbuildings,
      })
    });
  }

  endFetching = (id, value, key) => {
    this.setState(({buildings}) => {
      const idx = getBuildingPosition(id, buildings);
      const finished = {
        ...buildings[idx].finished,
        [key]: true,
      };
      const nbuildings = [...buildings];
      nbuildings[idx] = {
        ...buildings[idx],
        finished,
        [key]: value,
      };
      return ({
        buildings: nbuildings,
      })
    });
  }

  startFetchingData = (id) => this.startFetching(id, 'data');
  
  endFetchingData = (id, value) =>
    this.endFetching(id, value, 'data');

  startFetchingTime = (id, timeKey) => this.startFetching(id, timeKey);
  
  endFetchingTime = (id, value, time) =>
    this.endFetching(id, value, time);

  depricateData = (keys) => {
    this.setState(({buildings}) => {
      const nbuildings = buildings.map(
        building => {
          const nb = {...building};
          const finished = {...building.finished};
          keys.forEach(key => {
            delete nb[key];
            delete finished[key];
          });
          return {...nb, finished};
        });
      return ({
        buildings: nbuildings,
      })
    });
  }

  depricateHourData = () => this.depricateData(['data', 'day', 'week']);

  depricateDailyData = () => this.depricateData(['month', 'year']);

  state = {
    ...initialDashboardState,
    currentBuilding: this.currentBuilding,
    getBuilding: this.getBuilding,
    getCurrentTime: this.getCurrentTime,
    setBuildings: this.setBuildings,
    setCurrentTime: this.setCurrentTime,
    setCurrentBuilding: this.setCurrentBuilding,
    // Pobierane danych
    startFetchingData: this.startFetchingData,
    endFetchingData: this.endFetchingData,
    startFetchingTime: this.startFetchingTime,
    endFetchingTime: this.endFetchingTime,
    // Czyszczenie danych na potrzeby odświeżania
    depricateDailyData: this.depricateDailyData,
    depricateHourData: this.depricateHourData,
  };

  render() {
    return (
      <DashboardContext.Provider value={this.state}>
        {this.props.children}
      </DashboardContext.Provider>
    );
  }
}

export { DashboardContext };
export default DashboardDataProvider;
