import React, {useState} from 'react';
import styled from 'styled-components';
import {Input} from 'antd';

export default ({comment = '', refId, ...props}) => {
    const [value, changeValue] = useState(comment);

    const _onChange = (event) => {
        const v = event && event.target && event.target.value;
        changeValue(v);
    }
return (
<StyledConclusion id={`comment-${refId}`} {...props}>
    <UsedInput>
        <Input.TextArea
            autosize
            value={value}
            onChange={_onChange}
            placeholder="(Opcjonalne)"
        />
    </UsedInput>
    {(value && value.length > 0) && (<ResultValue>
        {value}
    </ResultValue>)}
</StyledConclusion>)
};

const StyledConclusion = styled.div`
    padding: 16px 0 32px;
`;

const UsedInput = styled.div`
    @media print {
        display: none;
    }
`;

const ResultValue = styled.div`
    font-size: 18px;
    display: none;
    white-space: pre-line;
    @media print {
        display: block;
    }
`;
