import React from 'react';
import styled from 'styled-components';

import ChartCreate from './scenes/ChartCreate';

export default () => {
  return (
  <Analysis>
     <ChartCreate />
  </Analysis>
  );
}

const Analysis = styled.div`
  background: #EEF1F1;
  height: 100%;
  flex: 1;
`;
