import React from "react";
import styled from "styled-components";
import { Icon } from "antd";

import { formatNumber } from "../../../utils/formaters";

export default ({ data, dataKey }) => {
    const gain = formatNumber(calculateGain(data, dataKey), { position: -1 });

    return (
        <GainTip>
            <IconContainer>
                <Up gain={gain}>
                    <Icon type="caret-up" />
                </Up>
                <Down gain={gain}>
                    <Icon type="caret-down" />
                </Down>
            </IconContainer>
            <DataContainer gain={gain}>
                {`${gain}%`}
            </DataContainer>
        </GainTip>
    );
}

const gainColor = "#C9776B";
const looseColor = "#74B885";

const calculateGain = (data, dataKey) => {
    if(data.length !== 2) return 0;
    const [first, last] = data;
    return (last[dataKey] - first[dataKey]) / first[dataKey] * 100;
}

const GainTip = styled.div`
    position: relative;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding: 4px;
    background: #F8F8F8;
    font-size: 1.2em;
    @media (max-width: 1600px) {
        font-size: 1.1em;
    }
    @media (max-width: 1440px) {
        font-size: 0.95em;
    }
`;

const IconContainer = styled.div`
    position: relative;
`;

const Up = styled.div`
    margin-bottom: -14px;
    ${({ gain }) => (gain > 0) ? `color: ${gainColor}` : ''}
`;

const Down = styled.div`
    margin-top: -14px;
    ${({ gain }) => (gain < 0) ? `color: ${looseColor}` : ''}
`;

const DataContainer = styled.div`
    color: #0E4194;
    ${({ gain }) => (gain > 0) ? `color: ${gainColor}` : ''}
    ${({ gain }) => (gain < 0) ? `color: ${looseColor}` : ''}
    margin-left: 4px;
`;
