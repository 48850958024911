import React from 'react';
import styled from 'styled-components';

import CustomChart from '../../../components/CustomChart';

export default styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    @media print {
        page-break-inside: avoid;
    }
    & + & {
        padding-top: 24px;
    }
`;

export const CHART_SIZES = Object.freeze({
    SMALL: 'small',
    MEDIUM: 'medium',
    SPREAD: 'spread',
    BIG: 'big',
});

const sizes = {
    [CHART_SIZES.SMALL]: {
        height: '200px',
        width: '300px',
    },
    [CHART_SIZES.MEDIUM]: {
        height: '300px',
        width: '464px',
    },
    [CHART_SIZES.SPREAD]: {
        height: '300px',
        width: '944px',
    },
    [CHART_SIZES.BIG]: {
        height: '600px',
        width: '944px',
    },
};

const defaultSize = CHART_SIZES.MEDIUM;

export const ChartHolderItem = styled.div`
    height: ${({size}) => sizes[size || defaultSize].height}
    width: ${({size}) => sizes[size || defaultSize].width}
    margin: auto;
    padding: 0 8px;
`;

export const WrappedChart = ({chartSize, ...props}) =>
    (<ChartHolderItem size={chartSize}>
        <CustomChart {...props} />
    </ChartHolderItem>);
