import React, { useState, useContext } from 'react';
import { message } from 'antd';

import { AppContext } from '../../AppDataProvider';
import {
  getBuildings,
  postNewBuilding,
  postBuildingUpdate,
  postBuildingRemoval,
  getUsers,
  postNewUser,
  postUserUpdate,
  postUserRemoval,
  postUserPasswordChange,
  getIndicators,
  putNewIndicators,
} from './services/api';

const AdministrationContext = React.createContext({});

const AdministrationDataProvider = ({ children }) => {
  const {
    startAsyncTask,
    finishAsyncTask,
    buildings,
    setBuildings,
    users,
    setUsers,
    wrapRequest,
  } = useContext(AppContext);

  const [indicatorsCategories, setIndicatorsCategories] = useState([]);
  const [currentId, setCurrentId] = useState(null);
  const [mode, setMode] = useState(null);
  const [kind, setKind] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const getCurrentEntry = () => {
    const data = kind === 'Users' ? users : buildings;
    const currentEntry = data.find(entry => entry.id === currentId);
    return currentEntry;
  };
  const getIndexById = id => {
    const data = kind === 'Users' ? users : buildings;
    const index = data.findIndex(entry => entry.id === id);
    return index;
  };

  async function fetchUsers() {
    startAsyncTask();
    await wrapRequest(() => getUsers(), {
      onSuccess: (data) => {
        setUsers(data);
      },
    });
    finishAsyncTask();
    
  }
  async function createUser(newUser) {
    startAsyncTask();
    await wrapRequest(() => postNewUser(newUser),
    {
      onError: (error = {}) => {
        message.error(error.data || 'Nie udało się dodać użytkownika. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: fetchUsers,
    });
    finishAsyncTask();
  }
  async function updateUser(updatedUser) {
    startAsyncTask();
    await wrapRequest(() => postUserUpdate(updatedUser),
    {
      onError: () => {
        message.error('Nie udało się zaktualizować danych użytkownika. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: (data) => {
        const newUsers = [...users];
        const updateIndex = getIndexById(data.id);
        newUsers[updateIndex] = data;
        setUsers(newUsers);
      },
    });
    finishAsyncTask();
  }
  async function deleteUser(userToDelete) {
    startAsyncTask();
    await wrapRequest(() => postUserRemoval(userToDelete.id),
    {
      onError: () => {
        message.error('Nie udało się usunąć użytkownika. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: fetchUsers,
    });
    finishAsyncTask();
  }
  async function changePassword(userId, passwords) {
    startAsyncTask();
    await wrapRequest(() => postUserPasswordChange(userId, passwords),
    {
      onError: () => {
        message.error('Nie udało się zaktualizować hasła. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
    });
    finishAsyncTask();
  }

  async function fetchBuildings() {
    startAsyncTask();
    await wrapRequest(() => getBuildings(),
    {
      onSuccess: (data) => {
        setBuildings(data);
      },
    });
    finishAsyncTask();
  }
  async function createBuilding(newBuilding) {
    startAsyncTask();
    await wrapRequest(() => postNewBuilding(newBuilding),
    {
      onError: () => {
        message.error('Nie udało się utworzyć budynku. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: fetchBuildings,
    });
    finishAsyncTask();
  }
  async function updateBuilding(updatedBuilding) {
    startAsyncTask();
    await wrapRequest(() => postBuildingUpdate(updatedBuilding),
    {
      onError: () => {
        message.error('Nie udało się zaktualizować danych o budynku. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: (data) => {
        const newBuildings = [...buildings];
        const updateIndex = getIndexById(data.id);
        newBuildings[updateIndex] = data;
        setBuildings(newBuildings);
      },
    });
    finishAsyncTask();
  }
  async function deleteBuilding(BuildingToDelete) {
    startAsyncTask();
    await wrapRequest(() => postBuildingRemoval(BuildingToDelete.id),
    {
      onError: () => {
        message.error('Nie udało się usunąć budynku. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: fetchBuildings,
    });
    finishAsyncTask();
  }

  async function fetchIndicators() {
    startAsyncTask();
    await wrapRequest(() => getIndicators(),
    {
      onSuccess: (data) => setIndicatorsCategories(data.categories),
    });
    finishAsyncTask();
  }

  async function updateIndicators(indicatorsPostData) {
    startAsyncTask();
    await wrapRequest(() => putNewIndicators(indicatorsPostData),
    {
      onError: () => {
        message.error('Nie udało się zakualizować wskaźników. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: fetchIndicators,
    });
    finishAsyncTask();
  }

  const state = {
    buildings,
    fetchBuildings,
    createBuilding,
    updateBuilding,
    deleteBuilding,

    users,
    fetchUsers,
    createUser,
    updateUser,
    deleteUser,
    changePassword,

    indicatorsCategories,
    fetchIndicators,
    updateIndicators,

    mode,
    setMode,
    kind,
    setKind,
    currentConfig: `${mode}${kind}`,

    setCurrentId,
    currentEntry: getCurrentEntry(),

    isModalVisible,
    setIsModalVisible,
  };

  return (
    <AdministrationContext.Provider value={state}>
      {children}
    </AdministrationContext.Provider>
  );
};

export { AdministrationContext };
export default AdministrationDataProvider;
