import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import { Input, Button } from 'antd';

import { AppContext } from '../../AppDataProvider';
import { DashboardRoute } from '../../Routes';
import Logo, { LOGO_SIZES } from '../../components/Logo';

const SignIn = () => {
  const { isSignedIn, submitLogin } = useContext(AppContext);
  const [email, setFormEmail] = useState('');
  const [password, setFormPassword] = useState('');

  return isSignedIn ? (
    <Redirect to={DashboardRoute} />
  ) : (
    <Form>
      <LogoWrapper>
        <Logo size={LOGO_SIZES.SIGN_IN} />
      </LogoWrapper>
      <StyledInput
        placeholder="Email"
        value={email}
        onChange={e => setFormEmail(e.target.value)}
      />
      <StyledInput
        placeholder="Hasło"
        type="password"
        value={password}
        onChange={e => setFormPassword(e.target.value)}
      />
      <StyledButton
        onClick={() => submitLogin(email, password)}
        type="primary"
        icon="login"
        size="large"
      >
        Zaloguj
      </StyledButton>
    </Form>
  );
};

const Form = styled.form`
  width: 600px;
  display: flex;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  align-items: center;
  padding: 24px 50px 50px;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
`;

const LogoWrapper = styled.div`
  margin-bottom: 8px;
`;

const StyledInput = styled(Input)`
  margin: 10px !important;
`;

const StyledButton = styled(Button)`
  margin-top: 15px;
`;

export default SignIn;
