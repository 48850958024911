import axios from 'axios';

export const getUsers = async () => {
  const resp = await axios.get('/Users');
  return resp;
};
export const postNewUser = async user => {
  const resp = axios.post('/Account/register', user);
  return resp;
};
export const postUserUpdate = async user => {
  const resp = axios.put(`/Users/${user.id}`, user);
  return resp;
};
export const postUserRemoval = async userId => {
  const resp = await axios.delete(`/Users/${userId}`, {});
  return resp;
};
export const postUserPasswordChange = async (userId, { newPassword }) => {
  const resp = await axios.post('/Account/updatePass', {
    userId,
    newPassword,
  });
  return resp;
};

export const getBuildings = async () => {
  const resp = await axios.get('/Buildings');
  return resp;
};
export const postNewBuilding = async building => {
  const resp = await axios.post('/Buildings', building);
  return resp;
};
export const postBuildingUpdate = async building => {
  const resp = await axios.put(`/Buildings/${building.id}`, building);
  return resp;
};
export const postBuildingRemoval = async buildingId => {
  const resp = await axios.delete(`/Buildings/${buildingId}`, {});
  return resp;
};

export const getIndicators = async () => {
  const resp = await axios.get('/Rates');
  return resp;
};
export const putNewIndicators = async indicators => {
  const resp = await axios.put('/Rates', indicators);
  return resp;
};

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);
export const postNewLabelName = async (name, buildingId, labelType) => {
  const endpoint = `/${capitalize(labelType)}`;
  const resp = await axios.post(endpoint, { name, buildingId });
  return resp;
};
export const postLabelNameRemoval = async (id, labelType) => {
  const endpoint = `/${capitalize(labelType)}/${id}`;
  const resp = await axios.delete(endpoint, {});
  return resp;
};

export const postLabelNameUpdate = async (id, name, buildingId, labelType) => {
  const endpoint = `/${capitalize(labelType)}/${id}`;
  const resp = await axios.put(endpoint, { id, name, buildingId });
  return resp;
};
