import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Form } from 'antd';

import { UserSelect } from '../../../components/BuildingSelect';
import PeriodSelect from '../../../components/PeriodSelect';
import FormItemWrapper, { validate } from '../../../components/FormItemWrapper';

export default ({loadData, config = {}, onChange}) => {
    const [_userId, setUserId] = useState();
    const [_period, setPeriod] = useState();
    const [triggered, setTriggered] = useState(false);

    useEffect(() => {
        setUserId(config.userId);
    }, [config.userId]);

    useEffect(() => {
        setPeriod(config.period || []);
    }, [config.period]);

    const rules = Object.keys(formRules).reduce((prev, key) => ({
        ...prev,
        [key]: {
            ...formRules[key],
            triggered,
        },
    }), {});

    const onReset = () => {
        setTriggered(false);
      }

    const onSubmit = async (event) => {
        event.preventDefault();
        setTriggered(true);
        const toValidate = [
            {key: 'community', value: _userId},
            {key: 'from', value: _period[0]},
            {key: 'to', value: _period[1]},
        ];
        const isValid = toValidate.map(({key, value}) => validate({
            ...rules[key],
            value,
            rules: rules[key] ? (rules[key].rules || []) : [],
          })).reduce((prev, {validateStatus}) => prev && validateStatus !== 'error', true);
          if(!isValid) {
            setTriggered(true);
          }
          else {
                loadData({
                    community: _userId,
                    period: _period,
                });
                onReset();
          }
    }

    const onChangeUserId = (id, isValid) => isValid
        ? onChange({...config, userId: id})
        : setUserId(id);
    
    const onChangePeriod = (per, isValid) => isValid
        ? onChange({...config, period: per})
        : setPeriod(per);

    return (
<ReportConfigForm>
    <Form
        layout="vertical"
        hideRequiredMark={false}
        onSubmit={onSubmit}
    >
        <TopSelectItem>
            <CommunityContainer>
                <FormItemWrapper
                    onChange={onChangeUserId}
                    value={_userId}
                    {...rules.userId}
                >
                    <UserSelect />
                </FormItemWrapper>
            </CommunityContainer>
        </TopSelectItem>
        <TopSelectItem>
            <PeriodSelect onChange={onChangePeriod} value={_period} formRules={rules}/>
        </TopSelectItem>
        <TopSelectItem>
            <ButtonContainer>
                <Button type="primary" htmlType="submit">Pobierz dane</Button>
            </ButtonContainer>
        </TopSelectItem>
    </Form>
</ReportConfigForm>);
}

const formRules = {
    userId: {
        required: true,
        hasIcon: true,
    },
    from: {
        required: true,
    },
    to: {
        required: true,
    },
};

const ReportConfigForm = styled.div`
    margin-bottom: -16px;
`;

const CommunityContainer = styled.div`
    width: 280px;
`;

const ButtonContainer = styled.div`
    padding: 0 0 32px 16px;
`;

const TopSelectItem = styled.div`
    display: inline-block;
    vertical-align: middle;
    & + & {
        margin-left: 16px;
    }
`;
