import React from 'react';
import styled from 'styled-components';

import { Drawer } from 'antd';

export default ({children, ...props}) =>
    <StyledDrawer
        bodyStyle={{flex: 1}}
        {...props}
    >
        {children}
    </StyledDrawer>

const StyledDrawer = styled(Drawer)`
    & .ant-drawer-wrapper-body {
        overflow: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
`;
