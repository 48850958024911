import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const Loading = ({size, message, color}) => (
  <Spinner>
    <CubeOne color={color} size={size}/>
    <CubeTwo color={color} size={size}/>
    {message && (
      <Message>{message}</Message>
    )}
  </Spinner>
);

const Spinner = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 72px;
  height: 72px;
  ${({size}) => size && `
    width: ${42 + size}px;
    height: ${42 + size}px;
  `}
  position: relative;
`;

const CubeMove = keyframes`
  25% { 
    transform: translateX(42px) rotate(-90deg) scale(0.5);
  } 50% { 
    transform: translateX(42px) translateY(42px) rotate(-179deg);
  } 50.1% { 
    transform: translateX(42px) translateY(42px) rotate(-180deg);
  } 75% { 
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
  } 100% { 
    transform: rotate(-360deg);
  }
`;

const CubeCss = css`
  background-color: white;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${CubeMove} 1.8s infinite ease-in-out;
`;

const CubeOne = styled.div`
  ${CubeCss}
  ${({size}) => size && `
    width: ${size}px;
    height: ${size}px;
  `}
  ${({color}) => color && `background-color: ${color}`}
`;

const CubeTwo = styled.div`
  ${CubeCss}
  ${({size}) => size && `
    width: ${size}px;
    height: ${size}px;
  `}
  ${({color}) => color && `background-color: ${color}`}
  animation-delay: -0.9s;
`;

const Message = styled.div`
  color: white;
  font-size: 16px;
  width: 320px;
  position: absolute;
  top: 88px;
  left: -124px;
  text-align: center;
`;

export default Loading;
