import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { find, filter } from "lodash";
import { List } from "antd";

import DataInputForm from '../../../../components/DataInputForm';
import StyledDrawer from '../../../../components/StyledDrawer';
import { ButtonForList, SetupAdd, SetupList} from '../../../../components/SetupList';

import AxisDialog from "../Dialogs/AxisDialog";
import XAxisDialog from "../Dialogs/XAxisDialog";
import EditSettingsDialog from "../Dialogs/EditSettingsDialog";
import { ConfigType, DataType, UsedDataType } from "../utils/types";
import { MODES } from "../utils/constants";

// TODO przerobic na funkcyjne
class ConfigChart extends Component {
  static propTypes = {
    config: PropTypes.shape(ConfigType).isRequired,
    usedData: PropTypes.arrayOf(PropTypes.shape(UsedDataType)).isRequired,
    loadedData: PropTypes.arrayOf(PropTypes.shape(DataType)).isRequired,
    update: PropTypes.func.isRequired,
  };

  state = {
    openedDialogSettings: false,
    openedDialogXAxis: false,
    openedDialogYAxis: false,
    editedAxis: null,
  };

  onCloseDialogSettings = () => {
    this.setState({ openedDialogSettings: false });
  };

  onCloseDialogXAxis = () => {
    this.setState({ openedDialogXAxis: false });
  };

  onCloseDialogYAxis = () => {
    this.setState({
      openedDialogYAxis: false,
      editedAxis: null,
    });
  };

  openDialogSettings = () => {
    this.setState({ openedDialogSettings: true });
  };

  openDialogXAxis = () => {
    this.setState({ openedDialogXAxis: true });
  };

  openDialogYAxis = axis => {
    this.setState({
      openedDialogYAxis: true,
      editedAxis: axis,
    });
  };

  newIndex = () =>
    this.props.config.yAxises.reduce(
      (prev, {id}) => Math.max(prev, id),
      -1,
    ) + 1;

  addAxis = newAxis => {
    const { config, update } = this.props;
    const ncofig = { ...config };
    const nAxises = [...config.yAxises, {
      id: this.newIndex().toString(),
      fields: newAxis,
    }];
    ncofig.yAxises = nAxises;
    update(ncofig);
  };

  updateAxis = (newAxis, key) => {
    const { config, update } = this.props;
    const ncofig = { ...config };
    const id = config.yAxises.findIndex(e => e.id === key);
    const nAxises = [...config.yAxises];
    nAxises[id] = {...nAxises[id], fields: newAxis};
    ncofig.yAxises = nAxises;
    update(ncofig);
  };

  canRemove = key =>
    !find(
     this.props.usedData,
      used => used.fields.axis === key,
    );

  removeAxis = axisId => {
    const { config, update } = this.props;
    const ncofig = { ...config };
    const nAxises = filter(ncofig.yAxises,
      e => e.id !== axisId);
    ncofig.yAxises = nAxises;
    update(ncofig);
  };

  updateSettings = newSettings => {
    const { config, update } = this.props;
    const ncofig = { ...config };
    ncofig.settings = { ...ncofig.settings, ...newSettings };
    update(ncofig);
  };

  updateXAxis = newAxis => {
    const { config, update } = this.props;
    const ncofig = { ...config };
    ncofig.xAxis = { ...ncofig.xAxis, ...newAxis };
    update(ncofig);
  };

  render() {
    const { config, loadedData, usedData } = this.props;
    const { editedAxis } = this.state;
    const isEdit = editedAxis !== null;
    return (
      <DataInputForm>
        <SetupList>
          <List.Item
            actions={[
              <ButtonForList onClick={this.openDialogSettings}>edytuj</ButtonForList>,
            ]}
          >
            <List.Item.Meta title="Konfiguracja ogólna wykresu" />
          </List.Item>
        </SetupList>
        <ConfigChartSection>
          Oś pozioma
        </ConfigChartSection>
        <SetupList>
          <List.Item
            actions={[
              <ButtonForList onClick={this.openDialogXAxis}>edytuj</ButtonForList>,
            ]}
          >
            <List.Item.Meta title="Oś pozioma (odniesienia)" />
          </List.Item>
        </SetupList>
        <ConfigChartSection>
          Osie pionowe
        </ConfigChartSection>
        <SetupList>
          {config.yAxises.map(({id, fields}) => (
            <List.Item
              key={id}
              actions={[
                <ButtonForList onClick={() => this.openDialogYAxis(id)}>edytuj</ButtonForList>,
                <ButtonForList onClick={() => this.removeAxis(id)} disabled={!this.canRemove(id)}>usuń</ButtonForList>,
              ]}
            >
              <List.Item.Meta title={fields.description} />
            </List.Item>
          ))}
        </SetupList>
        <SetupAdd
          description="Dodaj oś odniesienia"
          icon="plus"
          onClick={() => this.openDialogYAxis(null)}
        />
        <StyledDrawer
          title="Edytuj sposób prezentacji wykresu"
          placement="left"
          onClose={this.onCloseDialogSettings}
          visible={this.state.openedDialogSettings}
          width={480}
        >
          <EditSettingsDialog
            onClose={this.onCloseDialogSettings}
            settings={config.settings}
            updateData={this.updateSettings}
          />
        </StyledDrawer>
        <StyledDrawer
          title="Edytuj oś poziomą"
          placement="left"
          onClose={this.onCloseDialogXAxis}
          visible={this.state.openedDialogXAxis}
          width={480}
        >
          <XAxisDialog
            onClose={this.onCloseDialogXAxis}
            editedAxis={config.xAxis}
            loadedData={loadedData}
            usedData={usedData}
            updateData={this.updateXAxis}
          />
        </StyledDrawer>
        <StyledDrawer
          title={isEdit ? "Edytuj ustawienia osi" : "Dodaj nową oś"}
          placement="left"
          onClose={this.onCloseDialogYAxis}
          visible={this.state.openedDialogYAxis}
          width={480}
        >
          <AxisDialog
            mode={isEdit ? MODES.EDIT : MODES.LOAD}
            onClose={this.onCloseDialogYAxis}
            settings={config.settings}
            loadData={this.addAxis}
            updateData={newAxis => this.updateAxis(newAxis, editedAxis)}
            editedAxis={isEdit ? config.yAxises[editedAxis] : {}}
          />
        </StyledDrawer>
      </DataInputForm>
    );
  }
}

export default ConfigChart;

const ConfigChartSection = styled.div`
  padding: 8px 0;
  margin-top: 16px;
  font-weight: bold;
  background: linear-gradient(to right, #999 0%, #999 50%, #fff 50%, #fff 100%);
  background-position: top right;
  background-repeat: repeat-x;
  background-size: 10px 1px;
`;
