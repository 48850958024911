import React from "react";
import { YAxis } from "recharts";
import styled from 'styled-components';
// imports
import { chartRangesFromConfig } from "./utils/calculate";
import MathParser from "../MathParser";

export default ({
    description,
    showDescription,
    dataSpread,
    id,
    color,
    showAxis,
    side,
    unit,
    rangeFrom,
    rangeTo,
    rangeSpan,
    ...props
  }) => {
    const { domainMin, domainMax, ticks } = chartRangesFromConfig(dataSpread, {
      rangeFrom,
      rangeTo,
      rangeSpan,
    });
    return (
      <YAxis
        allowDataOverflow
        yAxisId={id}
        hide={!showAxis}
        orientation={side}
        stroke={color}
        type="number"
        width={Math.max(calculateAxisWidth(showDescription, ticks), 36)}
        domain={[
          domainMin === undefined ? 'dataMin' : domainMin,
          domainMax === undefined ? 'dataMax' : domainMax]}
        ticks={ticks}
        tickFormatter={tick => parseFloat(tick.toFixed(2))}
        label={
          showDescription
            ? <CustomYLabel
                  description={description}
                  color={color}
                  unit={unit}
                  side={side}
                />
            : undefined
        }
        {...props}
      />
    );
  };

    const CustomYLabel = ({ description, side, unit, color, ...props }) => {
    const { x, y, height, width } = props.viewBox;
    const customWidth = 20;
    const nx = side === "right" ? x + width - customWidth : x;
    return (
      <g transform={`translate(${nx}, ${y})`}>
        <foreignObject width={customWidth} height={height}>
          <StyledLabel style={{ color }}>
            <StyledLabelInner>
              <StyledLabelInnerRotate>
                <MathParser>{`${description}${unit ? ` [${unit}]` : ''}`}</MathParser>
              </StyledLabelInnerRotate>
            </StyledLabelInner>
          </StyledLabel>
        </foreignObject>
      </g>
    );
  };

const calculateAxisWidth = (showDescription, ticks = []) =>
  ticks.reduce(
    (prev, tick) => Math.max(prev, tick.toString().length),
  0) * 7 + (showDescription ? 15 : 0) + 20;


const StyledLabel = styled.div`
  text-align: center;
  height: 100%;
  width: 100%;
  font-size: 13px;
  @media (max-width: 1600px) {
    font-size: 12px;
  }
  @media (max-width: 1440px) {
    font-size: 11px;
  }
  @media (max-width: 1366px) {
    font-size: 10px;
  }
  @media print {
    font-size: 16px !important;
  }
`;

const StyledLabelInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const StyledLabelInnerRotate = styled.div`
  transform: translateX(-50%) translateY(-50%) rotate(-90.1deg);
  white-space: nowrap;
`;
