import React from 'react';
import styled from 'styled-components';

import chartConfig from './chartsConfig';

import ChartHolder, { WrappedChart } from '../components/ChartHolder';
import Page from '../components/Page';
import BuildingsLegend from '../components/BuildingsLegend';
import Conclusion from '../components/Conclusion';
import PageHeading from '../components/PageHeading';
import Table from '../components/Table';
import MathParser from '../../../components/MathParser';
import { formatNumber, formatPeriod } from '../../../utils/formaters';

export default ({config, comments = {}, data=[], pms, ...props}) =>
    (<Page {...props}>
        <PageHeading
            id="AllBuildings"
            to="Navigation"
            prefix="3."
        >
            Analiza dla wszystkich budynków
        </PageHeading>
        <TableWrapper>
            <TableHeader>
                {`Zestawienie zużycia mediów w budynkach w okresie: ${formatPeriod(config.startDate, config.endDate)}`}
            </TableHeader>
            <Table>
                <Table.Row>
                    <Table.Cell>Budynek</Table.Cell>
                    <Table.Cell>
                        <MathParser>Zużycie energii elektrycznej [kWh]</MathParser>
                    </Table.Cell>
                    <Table.Cell>
                        <MathParser>Zużycie ciepła [kWh]</MathParser>
                    </Table.Cell>
                    <Table.Cell>
                        <MathParser>Zużycie wody [m^3]</MathParser>
                    </Table.Cell>
                </Table.Row>
                {data.map(({buildingId, fullName, electricityTotalWithInvoices, heatTotallWithInvoices, waterTotallWithInvoices}) => (
                    <Table.Row key={buildingId}>
                        <Table.Cell>{fullName}</Table.Cell>
                        <Table.Cell>{formatNumber(electricityTotalWithInvoices, {position: -1})}</Table.Cell>
                        <Table.Cell>{formatNumber(heatTotallWithInvoices, {position: -1})}</Table.Cell>
                        <Table.Cell>{formatNumber(waterTotallWithInvoices, {position: -1})}</Table.Cell>
                    </Table.Row>
                ))}
            </Table>
        </TableWrapper>
        <Conclusion
            comment={comments.allBuildings}
            refId='allBuildings'
        />
        <ChartHolder>
            <WrappedChart data={data} {...chartConfig.allBuildings.heatToArea}/>
        </ChartHolder>
        <ChartHolder>
            <WrappedChart data={data} {...chartConfig.allBuildings.electricityToArea}/>
        </ChartHolder>
        <UsageWrapper>
            <Table noBorder>
                <Table.Row>
                    <Table.Cell>
                        Udział budynków w całkowitym zużyciu ciepła
                    </Table.Cell>
                    <Table.Cell>
                        Udział budynków w całkowitym zużyciu energii elektrycznej
                    </Table.Cell>
                    <Table.Cell>
                        Udział budynków w całkowitym zużyciu wody
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <WrappedChart data={data} {...chartConfig.allBuildings.electricityUsageByBuildings} />
                    </Table.Cell>
                    <Table.Cell>
                        <WrappedChart data={data} {...chartConfig.allBuildings.heatUsageByBuildings} />
                    </Table.Cell>
                    <Table.Cell>
                        <WrappedChart data={data} {...chartConfig.allBuildings.waterUsageByBuildings} />
                    </Table.Cell>
                </Table.Row>
            </Table>
            <BuildingsLegendWrapper>
                <BuildingsLegend
                    buildings={data}
                    buildingColorMapper={chartConfig.allBuildings.buildingColorMapper}
                />
            </BuildingsLegendWrapper>
            <ChartHolder>
                <WrappedChart data={pms.data} {...chartConfig.allBuildings.pm} />
            </ChartHolder>
        </UsageWrapper>
        <Conclusion
            comment={comments.allBuildingsCharts}
            refId='allBuildingsCharts'
        />
    </Page>);

const TableHeader = styled.div`
    padding: 8px 0;
    height: 40px;
    font-size: 18px;
`;

const TableWrapper = styled.div`
    padding: 24px 0;
`;

const UsageWrapper = styled.div`
    padding-top: 72px;
`;

const BuildingsLegendWrapper = styled.div`
    padding-bottom: 24px;
`;
