import React from 'react';
import styled from 'styled-components';

import Logo, { LOGO_SIZES } from '../../../components/Logo';

const Top = styled.div`
    text-align: left;
    height: 100px;
`;

export default () => (
    <Top>
        <Logo size={LOGO_SIZES.REPORT_HEADER}/>
    </Top>);