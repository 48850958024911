import { filter, isNil } from "lodash";

const getAllDataFromKey = (data, key) =>
  filter(data, e => !isNil(e[key])).map(item => item[key]);

export const filterDataByKeys = (data, keys = []) =>
  filter(data, e => keys.reduce((prev, k) => prev && !isNil(e[k]), true));

export const dataFromAxis = (data, usedData, axis, maxMin, minMax) =>
  filter(usedData, e => e.axis === axis).reduce(
    (prev, used) => [...prev, ...getAllDataFromKey(data, used.dataSource)],
    [...(maxMin ? [maxMin] : []), ...(minMax ? [minMax] : [])],
  );

export const dataFromLimits = (limits = [], axis) =>
  filter(limits, e=> e.axis === axis).map(({value}) => value);

export const dataFromAreas = (areas = [], axis) =>
  filter(areas, e=> e.axis === axis).reduce((prev, {from, to}) => [...prev, from, to], []);

export const hasLeftAxis = (yAxises) =>
yAxises.reduce((prev, {side}) => prev || side === "left", false);

export const hasRightAxis = (yAxises) =>
yAxises.reduce((prev, {side}) => prev || side === "right", false);

export const dataFromXAxis = (data, xKey) => getAllDataFromKey(data, xKey);

export const isDataFunction = (data, xKey, yKey) => {
    let y = null;
    let x = null;
    for (let i = 0; i < data.length; i += 1) {
      if (data[i][xKey] && data[i][yKey]) {
        if (data[i][xKey] === x && data[i][yKey] !== y) {
          return false;
        }
        x = data[i][xKey];
        y = data[i][yKey];
      }
    }
    return true;
  };

  const TIME = "time";

  export const isTimeChart = xKey => xKey === TIME;
