import React from 'react';
import styled from 'styled-components';
import { Card } from 'antd';
import Loading from '../../../components/Loading';
import MathParser from '../../../components/MathParser';

export default ({ children, loading, title, spread, hide }) =>
    <ChartCard>
        <StyledCard
            size="small"
            title={<StyledTitle><MathParser>{title}</MathParser></StyledTitle>}
            bodyStyle={{ height: 'calc(100% - 40px)', padding: '0 12px 12px' }}
            headStyle={{ textAlign: 'center', border: 'none' }}
            spread={spread ? 1 : 0}
        >
            {!hide && (loading
            ? <Loading color="blue" size="20"/>
            : children)}
        </StyledCard>
    </ChartCard>

const StyledTitle = styled.span`
    font-size: 14px;
    @media (max-width: 1600px) {
        font-size: 13px;
    }
    @media (max-width: 1440px) {
        font-size: 12px;
    }
    @media (max-width: 1366px) {
        font-size: 11px;
    }
`;

const ChartCard = styled.div`
    padding: 8px;
    height: 100%;
    margin: 0;
`;

const StyledCard = styled(Card)`
    background: #F8F8F8 !important;
    width: ${({spread}) => `calc((100vw - 448px) / ${spread ? 2 : 3} - 16px)`};
    min-width: 285px;
    height: calc((100vh - 136px) / 2 - 24px);
    min-height: 276px;
    &.ant-card {
        transition: none;
    }
    @media (max-height: 800px) {
        height: calc((100vh - 120px) / 2 - 24px);
    }
    @media (max-width: 1600px) {
        width: ${({spread}) => `calc((100vw - 412px) / ${spread ? 2 : 3} - 16px)`};
    }
    @media (max-width: 1440px) {
        width: ${({spread}) => `calc((100vw - 376px) / ${spread ? 2 : 3} - 16px)`};
    }
`;
