import React from "react";
import styled from "styled-components";
import { Card, Icon } from "antd";

export default ({label, value, unit, loading, ...props}) => (
<Card
    size="small"
    style={{
        background: '#F8F8F8',
    }}
    {...props}
>
<CardValue>
    <Label>{label}</Label>
    <Value>
        {loading ?
        <Icon type="loading" spin />
        :
        value
    }
    </Value>
    {unit && <Label>{unit}</Label>}
</CardValue>
</Card>);

const CardValue = styled.div`
    text-align: center;
`;

const Label = styled.div`
    font-size: 0.9em;
    @media (max-width: 1600px) {
        font-size: 0.85em;
    }
    @media (max-width: 1440px) {
        font-size: 0.75em;
    }
`;

const Value = styled.div`
    font-weight: bold;
    font-size: 1.2em;
    @media (max-width: 1600px) {
        font-size: 1.1em;
    }
    @media (max-width: 1440px) {
        font-size: 0.95em;
    }
`;
