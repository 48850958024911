import { dayConfig } from './day';
import { weekConfig } from './week';
import { monthConfig } from './month';
import { yearConfig } from './year';

export default Object.assign({
    day: dayConfig,
    week: weekConfig,
    month: monthConfig,
    year: yearConfig,
});

export const chartDivisions = Object.assign({
    day: [
        [
            {
                key: 'heatPowerConsumption',
                enableHidden: false,
                title: 'Pobór mocy cieplnej',
            },
            {
                key: 'electricityPowerConsumption',
                enableHidden: false,
                title: 'Pobór mocy elektrycznej',
            },
            {
                key: 'OZEPowerProduction',
                enableHidden: true,
                title: 'Produkcja energii elektrycznej z OZE',
            },
        ],
        [
            {
                key: 'temperatures',
                enableHidden: false,
                title: 'Temperatury',
            },
            {
                key: 'co2',
                enableHidden: false,
                title: 'Stężenie CO_2',
            },
            {
                key: 'humidity',
                enableHidden: true,
                title: 'Wilgotność względna',
            },
        ],
    ],
    week: [
        [
            {
                key: 'heatPowerConsumption',
                enableHidden: false,
                title: 'Pobór mocy cieplnej',
            },
            {
                key: 'electricityPowerConsumption',
                enableHidden: false,
                title: 'Pobór mocy elektrycznej',
            },
            {
                key: 'totalConsumption',
                enableHidden: false,
                title: 'Całkowite zużycie energii',
            },
        ],
        [
            {
                key: 'temperatures',
                enableHidden: false,
                title: 'Temperatury',
            },
            {
                key: 'co2',
                enableHidden: false,
                title: 'Stężenie CO_2',
            },
            {
                key: 'OZEProduction',
                enableHidden: true,
                title: 'Produkcja energii elektrycznej z OZE',
            },
        ],
    ],
    month: [
        [
            {
                key: 'dailyHeatConsumption',
                enableHidden: false,
                title: 'Dzienne zużycie ciepła',
            },
            {
                key: 'dailyElectricityConsumption',
                enableHidden: false,
                title: 'Dzienne zużycie energii elektrycznej',
            },
            {
                key: 'comparedConsumption',
                enableHidden: false,
                title: 'Porównanie zużycia energii',
            },
        ],
        [
            {
                key: 'heatingCurve',
                enableHidden: false,
                title: 'Charakterystyka energetyczna budynku',
            },
            {
                key: 'energyUsageAreas',
                enableHidden: false,
                title: 'Obszary zużycia energii',
            },
            {
                key: 'dailyOZEProduction',
                enableHidden: true,
                title: 'Produkcja energii elektrycznej z OZE',
            },
        ],
    ],
    year: [
        [
            {
                key: 'monthlyHeatConsumption',
                enableHidden: false,
                title: 'Miesięczne zużycie ciepła',
            },
            {
                key: 'monthlyElectricityConsumption',
                enableHidden: false,
                title: 'Miesięczne zużycie energii elektrycznej',
            },
            {
                key: 'comparedConsumption',
                enableHidden: false,
                title: 'Porównanie zużycia energii',
            },
        ],
        [
            {
                key: 'heatingCurve',
                enableHidden: false,
                title: 'Charakterystyka energetyczna budynku',
            },
            {
                key: 'energyUsageAreas',
                enableHidden: false,
                title: 'Obszary zużycia energii',
            },
            {
                key: 'monthlyOZEProduction',
                enableHidden: true,
                title: 'Produkcja energii elektrycznej z OZE',
            },
        ],
    ],
});
