import React from 'react';
import styled from 'styled-components';
import { Select } from 'antd';

const { Option } = Select;

export default ({ ...props }) => (
  <Select placeholder="Kolor linii" {...props}>
    {colors.map(({ color, description }) => (
      <Option key={color} value={color}>
        <div>
          <ColorSelectColor color={color} />
          <span>{description}</span>
        </div>
      </Option>
    ))}
  </Select>
);

const ColorSelectColor = styled.div`
  background: ${({ color }) => color};
  display: inline-block;
  vertical-align: text-bottom;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin-right: 4px;
`;

export const colors = [
  {
    description: 'Ciemnoniebieski',
    color: '#063951',
  },
  {
    description: 'Ciemnoczerwony',
    color: '#C13015',
  },
  {
    description: 'Pomarańczowy',
    color: '#E36F13',
  },
  {
    description: 'Jasnoniebieski',
    color: '#0D95BC',
  },
  {
    description: 'Żółty',
    color: '#E3CB38',
  },
  {
    description: 'Zielonkawy',
    color: '#A2B969',
  },
  {
    description: 'Ciemnoszary',
    color: '#333',
  },
  {
    description: 'Czarny',
    color: 'black',
  },
  {
    description: 'Niebieski',
    color: 'blue',
  },
  {
    description: 'Czerwony',
    color: 'red',
  },
  {
    description: 'Zielony',
    color: '#556B3E',
  },
];

export const multiChartLineColors = [
  {
    color: '#0E4194',
  },
  {
    color: '#10C8CA',
  },
  {
    color: '#7FD2BE',
  },
  {
    color: '#4462ba',
  },
  {
    color: '#2490be',
  },
  {
    color: '#03cca7',
  },
];
