import axios from 'axios';

export const getActiveAlarms = async () => {
  const resp = await axios.get('/AlarmsHistory/active');
  return resp;
};
export const getAlarmsHistory = async () => {
  const resp = await axios.get('/AlarmsHistory/inactive');
  return resp;
};

export const postAlarmDeactivation = async alarmId => {
  const resp = await axios.post(`/AlarmsHistory/${alarmId}/deactivate`, {});
  return resp;
};
export const postNewAlarmSetting = async newAlarm => {
  const resp = await axios.put(`/Alarms/${newAlarm.id}`, newAlarm);
  return resp;
};
