import { sortBy } from 'lodash';

import { colors } from '../../../../../../components/ColorSelect';

export const defaultChartConfig = {
  settings: {
    title: "",
    showTitle: false,
    showLegend: true,
    horizontalGrid: true,
    verticalGrid: true,
    // dwie poniższe nieedytowalne
    stopInteractive: false,
    stopAnimation: true,
  },
  xAxis: {
    description: "Czas",
    showDescription: false,
    color: "#333",
    datesNumber: 5,
    withTime: true,
    xKey: "time",
    unit: "",
    rangeFrom: "auto",
    rangeTo: "auto",
    rangeSpan: "auto",
  },
  yAxises: [
    {
      id: "0",
      fields: {
        color: "#333",
        showAxis: true,
        side: "left",
        description: "Oś Y",
        showDescription: false,
        unit: "",
        rangeFrom: "auto",
        rangeTo: "auto",
        rangeSpan: "auto",
      },
    },
  ],
};

export const nextColorsPallete = (alreadyUsed) => {
  const possible = colors.reduce((prev, color, id) => ({
    ...prev,
    [color.color]: {
      counter: 0,
      id,
    },
  }), {});
  alreadyUsed.forEach(({fields}) => {
    possible[fields.lineColor].counter += 1;
  });
  const pallete = Object.keys(possible).map(key => ({
    color: key,
    counter: possible[key].counter,
    id: possible[key].id,
  }))
  return sortBy(pallete, ['counter', 'id']);
}

export const getUsedData = ({dataSource, description, colorPallete, yAxises}, idx) => ({
  dataSource,
  fields: {
    description,
    axis: yAxises[0].id,
    showRegresion: false,
    dotted: false,
    lineWidth: 2,
    lineColor: colorPallete[idx % colorPallete.length].color,
    dashed: false,
    dashLength: 5,
    dashSpacing: 5,
  },
  show: true,
});
