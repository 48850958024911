import React, { useState, useContext } from 'react';
import { message } from 'antd';

import { AppContext } from '../../AppDataProvider';
import {
  getHookUps,
  getInvoices,
  postDatabaseBuildingUpdate,
  postHookUpRemoval,
  postHookUpUpdate,
  postInvoiceRemoval,
  postInvoiceUpdate,
  postNewHookUp,
  postNewInvoice,
} from './services/api';

const DbContext = React.createContext({});

const DbDataProvider = ({ children }) => {
  const {
    startAsyncTask,
    buildings,
    setBuildings,
    finishAsyncTask,
    wrapRequest,
  } = useContext(AppContext);

  const [hookUps, setHookUps] = useState([]);
  const [invoices, setInvoices] = useState([]);

  const getData = () => {
    let data;
    if (kind === 'Buildings') {
      data = buildings;
    } else if (kind === 'HookUps') {
      data = hookUps;
    } else {
      data = invoices;
    }
    return data;
  };
  const getCurrentEntry = () => {
    const data = getData();
    const currentEntry = data.find(entry => entry.id === currentId);
    return currentEntry;
  };
  const getIndexById = id => {
    const data = getData();
    const index = data.findIndex(entry => entry.id === id);
    return index;
  };

  async function fetchHookUps() {
    startAsyncTask();
    await wrapRequest(() => getHookUps(),
    {
      onSuccess: (data) => setHookUps(data),
    });
    finishAsyncTask();
  }

  async function fetchInvoices() {
    startAsyncTask();
    await wrapRequest(() => getInvoices(),
    {
      onSuccess: (data) => setInvoices(data),
    });
    finishAsyncTask();
  }

  // Buildings

  async function updateBuilding(updatedBuilding) {
    startAsyncTask();
    await wrapRequest(() => postDatabaseBuildingUpdate(updatedBuilding),
    {
      onError: (error = {}) => {
        message.error(error.data || 'Nie udało się zaktualizować danych budynku. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: (data) => {
        const newBuildings = [...buildings];
        const updateIndex = getIndexById(data.id);
        newBuildings[updateIndex] = data;
        setBuildings(newBuildings);
      },
    });
    finishAsyncTask();
  }

  // HookUps

  async function createHookUp(newHookUp) {
    startAsyncTask();
    await wrapRequest(() => postNewHookUp(newHookUp),
    {
      onError: (error = {}) => {
        message.error(error.data || 'Nie udało się dodać przyłącza. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: fetchHookUps,
    });
    finishAsyncTask();
  }

  async function updateHookUp(updatedHookUp) {
    startAsyncTask();
    await wrapRequest(() => postHookUpUpdate(updatedHookUp),
    {
      onError: (error = {}) => {
        message.error(error.data || 'Nie udało się zaktualizować danych o przyłączu. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: (data) => {
        const newHookUps = [...hookUps];
        const updateIndex = getIndexById(data.id);
        newHookUps[updateIndex] = data;
        setHookUps(newHookUps);
      },
    });
    finishAsyncTask();
  }

  async function deleteHookUp(hookUpToDelete) {
    startAsyncTask();
    await wrapRequest(() => postHookUpRemoval(hookUpToDelete.id),
    {
      onError: (error = {}) => {
        message.error(error.data || 'Nie udało się usunąć przyłącza. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: fetchHookUps,
    });
    finishAsyncTask();
  }

  async function createInvoice(newInvoice) {
    startAsyncTask();
    await wrapRequest(() => postNewInvoice(newInvoice),
    {
      onError: (error = {}) => {
        message.error(error.data || 'Nie udało się utworzyć faktury. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: fetchInvoices,
    });
    finishAsyncTask();
  }

  async function updateInvoice(updatedInvoice) {
    startAsyncTask();
    await wrapRequest(() => postInvoiceUpdate(updatedInvoice),
    {
      onError: (error = {}) => {
        message.error(error.data || 'Nie udało się zaktualizować danych faktury. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: (data) => {
        const newInvoicess = [...invoices];
        const updateIndex = getIndexById(data.id);
        newInvoicess[updateIndex] = data;
        setInvoices(newInvoicess);
      },
    });
    finishAsyncTask();
  }

  async function deleteInvoice(invoiceToDelete) {
    startAsyncTask();
    await wrapRequest(() => postInvoiceRemoval(invoiceToDelete.id),
    {
      onError: (error = {}) => {
        message.error(error.data || 'Nie udało się usunąć faktury. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: fetchInvoices,
    });
    finishAsyncTask();
  }

  // COMMMON

  const [currentId, setCurrentId] = useState(undefined);
  const [mode, setMode] = useState(undefined);
  const [kind, setKind] = useState(undefined);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const state = {
    buildings,
    hookUps,
    invoices,

    fetchHookUps,
    fetchInvoices,

    // Buildings
    updateBuilding,
    // HookUps
    createHookUp,
    updateHookUp,
    deleteHookUp,
    // Invoices
    createInvoice,
    updateInvoice,
    deleteInvoice,
    mode,
    setMode,
    kind,
    setKind,
    currentConfig: `${mode}${kind}`,

    setCurrentId,
    currentEntry: getCurrentEntry(),

    isModalVisible,
    setIsModalVisible,
  };

  return <DbContext.Provider value={state}>{children}</DbContext.Provider>;
};

export { DbContext };
export default DbDataProvider;
