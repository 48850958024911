import React, { useContext } from 'react';
import styled from 'styled-components';

import { Layer } from './MainLoading';
import { AppContext } from '../AppDataProvider';

export default () => {
    const { error } = useContext(AppContext);
    return error ? (
        <Layer>
            <Error error={error} />
        </Layer>) : null;
}

const Error = ({error: {message, title, footer, ...props} = {}}) => (
    <StyledError {...props}>
        {title && (<Title>{title}</Title>)}
        {message && (<Message>{message}</Message>)}
        {footer && (<Footer>{footer}</Footer>)}
    </StyledError>
);

const StyledError = styled.div`
    padding: 36px 56px 56px;
    width: 600px;
    background: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    color: #C13015;
`;

const Footer = styled.div`
    margin-top: 40px;
    text-align: center
`;

const Title = styled.div`
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    padding: 0 0 11px;
    border-bottom: 2px solid #C13015;
`;

const Message = styled.div`
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    padding: 11px 0 0;
`;
