import React from 'react';
import styled from 'styled-components';

import MathParser from '../../../components/MathParser';
import {formatMoney, formatNumber } from '../../../utils/formaters';
import CardValue from './CardValue';

export default ({data, loading}) => {
    const getValue = ({key, value}) => loading || value || data[key];
    const getData = ({key, value, valueFormatter}) =>
        ((v) => valueFormatter ? (valueFormatter(v)) : v)(getValue({key, value}));
    const shouldHide = ({key, value, hide}) =>
        ((v) => (hide && hide(v)))(getValue({key, value}));

    return (
        <BuildingData>
            {
                loading ?
                elements.map(element =>
                    <MyRow key={element.key}>
                        {element.formatter({
                            loading,
                            label: element.label,
                            unit: element.unit,
                        })}
                    </MyRow>,
                )
                :
                elements.map(element =>
                    !shouldHide(element) ?
                    (<MyRow key={element.key}>
                        {element.formatter({
                            value: getData(element),
                            label: element.label,
                            unit: element.unit,
                        })}
                    </MyRow>) : null,
                )
            }
        </BuildingData>
    );
};

const renderElement = ({value, label, unit, ...props}) =>
    <CardValue
        label={label}
        value={value}
        unit={unit}
        {...props}
    />

const elements = [
    {
        key: "heatUsage",
        label: "Zużycie ciepła",
        unit: 'kWh',
        valueFormatter: (value) => (`${formatNumber(value || 0, {position: 2})}`),
        formatter: renderElement,
    },
    {
        key: "electricityUsage",
        label: "Zużycie energii elektrycznej",
        unit: 'kWh',
        valueFormatter: (value) => (`${formatNumber(value || 0, {position: 2})}`),
        formatter: renderElement,
    },
    {
        key: "carbDioxEmission",
        label: <MathParser>Emisja CO_2</MathParser>,
        unit: 'ton',
        valueFormatter: (value) => (`${formatNumber(value || 0)}`),
        formatter: renderElement,
    },
    {
        key: "renewableEnergySourcesProduction",
        label: "Produkcja z OZE",
        unit: 'kWh',
        valueFormatter: (value) => (`${formatNumber(value || 0, 1)}`),
        formatter: renderElement,
    },
    {
        key: "renewableEnergySourcesSavings",
        label: "Oszczędności z OZE",
        unit: "PLN",
        valueFormatter: (value) => (`${formatMoney(value || 0)}`),
        formatter: renderElement,
    },
];

const BuildingData = styled.div`
    padding: 8px;
`;

const MyRow = styled.div`
    & + & {
        margin-top: 16px;
        @media (max-height: 800px) {
            margin-top: 8px;
        }
    }
`;
