import React, { useContext, useState } from 'react';

import { getBuildingMeasurements } from './services/api';

import { AppContext } from '../../AppDataProvider';

const AnalysisContext = React.createContext({});

const AnalysisDataProvider = ({children}) => {
  const { startAsyncTask, finishAsyncTask, wrapRequest } = useContext(AppContext);

  const [buildingsMeasurements, setBuildingMeasurements] = useState({});

  async function fetchMeasurements(buildingId) {
    if(!buildingsMeasurements[buildingId]) {
      startAsyncTask("Pobieranie opomiarowanych parametrów. Operacja może trwać do kilkunastu sekund.");
      await wrapRequest(() => getBuildingMeasurements(buildingId), {
        onSuccess: (data) => {
          setBuildingMeasurements({
            ...buildingsMeasurements,
            [buildingId]: data,
          });
        },
      });
      finishAsyncTask();
    }
  }

  const state = {
    buildingsMeasurements,
    fetchMeasurements,
  }

  return (
    <AnalysisContext.Provider value={state}>
      {children}
    </AnalysisContext.Provider>
  );
}

export { AnalysisContext };
export default AnalysisDataProvider;
