import React, { useState, useContext } from 'react';

import { getMyBuildingsBenchmark, getDatabaseBenchmark } from './services/api';

import { AppContext } from '../../AppDataProvider';

const BenchmarkingContext = React.createContext({});

const BenchmarkingDataProvider = ({ children }) => {
  const { startAsyncTask, finishAsyncTask, buildings, wrapRequest } = useContext(AppContext);

  const [myBuildingsBenchmark, setMyBuildingsBenchmark] = useState();
  const [databaseBenchmark, setDatabaseBenchmark] = useState();

  async function fetchMyBuildingsBenchmark() {
    startAsyncTask("Porównywanie wszystkich posiadanych budynków może zająć dużo czasu.");
    await wrapRequest(() => getMyBuildingsBenchmark(buildings),
    {
      onSuccess: (data) => {
        setMyBuildingsBenchmark(data);
      },
    });
    finishAsyncTask();
  }

  async function fetchDatabaseBenchmark(params) {
    startAsyncTask("Porównywanie wszystkich budynków może zająć dużo czasu.");
    await wrapRequest(() => getDatabaseBenchmark(params),
    {
      onSuccess: (data) => {
        setDatabaseBenchmark(data);
      },
    });
    finishAsyncTask();
  }

  const state = {
    fetchMyBuildingsBenchmark,
    fetchDatabaseBenchmark,

    myBuildingsBenchmark,
    databaseBenchmark,
  };

  return (
    <BenchmarkingContext.Provider value={state}>
      {children}
    </BenchmarkingContext.Provider>
  );
};

export { BenchmarkingContext };
export default BenchmarkingDataProvider;
