import React, { useState, useContext } from 'react';
import { Select, Switch, InputNumber } from 'antd';

import { getUnit } from '../services/const';

import { AppContext } from '../../../AppDataProvider';

const { Option } = Select;

const AlarmsSettings = ({ changeSetting }) => {
  const [selectedUserId, setSelectedUserId] = useState();
  const [selectedBuildingId, setSelectedBuildingId] = useState();

  const { standardUsers, buildings, enums } = useContext(AppContext);
  const handleUserChange = value => {
    setSelectedUserId(value);
    setSelectedBuildingId(undefined);
  };
  const handleBuildingChange = value => setSelectedBuildingId(value);

  const userOptions = standardUsers.map(user => (
    <Option value={user.id} key={user.id}>
      {user.name}
    </Option>
  ));
  const buildingOptions = selectedUserId
    ? buildings
        .filter(building => building.userId === selectedUserId)
        .map(building => (
          <Option value={building.id} key={building.id}>
            {building.shortName}
          </Option>
        ))
    : null;

  const selectedBuilding = buildings.find(
    building => building.id === selectedBuildingId,
  );

  return (
    <div>
      <div>
        <Select
          placeholder="Wybierz użytkownika"
          style={{ width: 350, margin: '20px 20px 20px 4px' }}
          value={selectedUserId}
          onChange={handleUserChange}
        >
          {userOptions}
        </Select>
        {selectedUserId && (
          <Select
            placeholder="Wybierz budynek"
            style={{ width: 350 }}
            value={selectedBuildingId}
            onChange={handleBuildingChange}
          >
            {buildingOptions}
          </Select>
        )}
      </div>
      <div>
        {selectedBuildingId && (
          <SettingsList
            alarmTypes={enums.alarmTypes}
            settings={selectedBuilding.alarms}
            changeSetting={changeSetting}
            buildingId={selectedBuildingId}
          />
        )}
      </div>
    </div>
  );
};

const SettingsList = ({ settings, changeSetting, alarmTypes, buildingId }) => {
  return (
    <Table>
      {settings.map(setting => (
        <Row key={`${setting.alarmTypeId}-${buildingId}`}>
          <Cell>
            {alarmTypes.find(type => type.id === setting.alarmTypeId).name}
          </Cell>
          <SettingActions setting={setting} changeSetting={changeSetting} />
        </Row>
      ))}
    </Table>
  );
};

const Table = ({ children, ...props }) => (
  <table style={{ tableLayout: 'auto' }} {...props}>
    <tbody>{children}</tbody>
  </table>
);

const Row = props => <tr {...props} />;
const Cell = props => <td style={{ padding: '4px 8px' }} {...props} />;

const SettingActions = ({ setting, changeSetting, ...rest }) => {
  const [settingValue, setSettingValue] = useState(setting.threshold);
  const [isChecked, setIsChecked] = useState(setting.isEnabled);
  const handleValueChange = value => setSettingValue(value);
  const handleValueBlur = () => {
    changeSetting({
      ...setting,
      threshold: settingValue,
      isEnabled: isChecked,
    });
  };
  const handleCheck = checked => {
    setIsChecked(checked);
    changeSetting({
      ...setting,
      threshold: settingValue,
      isEnabled: checked,
    });
  };

  return (
    <>
      <Cell>
        <InputNumber
          value={settingValue}
          onBlur={handleValueBlur}
          onChange={handleValueChange}
        />
      </Cell>
      <Cell>{getUnit(setting.alarmTypeId)}</Cell>
      <Cell>
        <Switch checked={isChecked} onChange={handleCheck} />
      </Cell>
    </>
  );
};

export default AlarmsSettings;
