import React, { Component } from 'react';

const initialReportsState = {
  reports: [],
};

const ReportsContext = React.createContext({});

class ReportsDataProvider extends Component {
  set = field => value => this.setState(() => ({ [field]: value }));

  state = {
    ...initialReportsState,
  };

  render() {
    return (
      <ReportsContext.Provider value={this.state}>
        {this.props.children}
      </ReportsContext.Provider>
    );
  }
}

export { ReportsContext };
export default ReportsDataProvider;
