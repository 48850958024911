import styled from 'styled-components';

export default styled.div`
    padding: 32px 0;
    position: relative;
    @media print {
        padding: 0;
        page-break-before: always;
        page-break-after: always;
        color: black !important;
    }
`;
