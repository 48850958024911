import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { message } from 'antd';

import { minAppHeight, minAppWidth, navWidth, topBarHeight } from '../../constants';

import BuildingDetails from './scenes/BuildingDetails';
import Charts from './scenes/Charts';
import NoBuildings from './scenes/NoBuildings';
import TopBar from './scenes/TopBar';

import {
  getBuildingData,
  getBuildingTime,
} from './services/api';

import { DashboardContext } from './DashboardDataProvider';
import { AppContext } from '../../AppDataProvider';

export default () => {
  const {
    currentTime,
    getCurrentTime,
    currentBuildingId,
    currentBuilding,
    setBuildings,
    // Pobierane danych
    endFetchingData,
    endFetchingTime,
    depricateHourData,
    depricateDailyData,
  } = useContext(DashboardContext);
  const { measuredBuildings, wrapRequest } = useContext(AppContext);
  if(!measuredBuildings || measuredBuildings.length === 0) return (<NoBuildings />);
  const [dailyInterval, setDailyInterval] = useState(null);

  useEffect(() => {
    fetchBuildings();
    const actualInterval = setInterval(
      () => {
        resetHourData();
      }, 1000 * 60 * 60);
    const dailyStart = setTimeout(
      () => {
        depricateDailyData();
        resetDailyData()
        setDailyInterval(setInterval(
          () => {
            depricateDailyData();
            resetDailyData();
          }, 1000 * 60 * 60 * 24));
      }, 1000 * secondsToMidnight());

      return () => {
        clearInterval(actualInterval);
        clearTimeout(dailyStart);
        if(dailyInterval) {
          try {
            clearInterval(dailyInterval);
          }
          catch {
            console.warn('Pusty interval dzienny wykryty');
          };
        }
      }
  }, []);

  return (
  <Dashboard>
    <TopBar />
    <DashboardInner>
      <BuildingDetails />
      <ChartsContainer>
        <Charts />
      </ChartsContainer>
    </DashboardInner>
  </Dashboard>
  );

  async function resetHourData() {
    const building = currentBuilding();
    if( building.id !== null ) {
      depricateHourData();
      fetchBuildingData(building.id);
      const time = getCurrentTime();
      if(['day', 'week'].includes(time)) {
        fetchBuildingTime(building.id, time);
      }
    }
  }

  async function resetDailyData() {
    const building = currentBuilding();
    if( building.id !== null ) {
      depricateDailyData();
      fetchBuildingData(building.id);
      const time = getCurrentTime();
      if(['month', 'year'].includes(time)) {
        fetchBuildingTime(building.id, time);
      }
    }
  }

  async function fetchBuildings() {
    if(currentBuildingId !== null) {
      fetchBuildingData(currentBuildingId);
    }
    else {
      const { id } = measuredBuildings[0];
      setBuildings(measuredBuildings, id);
      fetchBuildingData(id);
      fetchBuildingTime(id, currentTime);
    }
  }

  async function fetchBuildingData(id) {
    await wrapRequest(() => getBuildingData(id), {
      onError: () => {
        message.error('Nie udało się pobrać danych o zużyciach. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: (data) => {
        endFetchingData(id, data);
      },
    });
  }

  async function fetchBuildingTime(id, time) {
    await wrapRequest(() => getBuildingTime(id, time), {
      onError: () => {
        message.error('Nie udało się pobrać danych do wykresów. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: (data) => {
        endFetchingTime(id, data, time);
      },
    });
  }
}

const secondsToMidnight = () => {
  const mmt = moment();
  const mmtMidnight = mmt.clone().endOf('day');
  return mmtMidnight.diff(mmt, 'seconds') + 1;
}


const Dashboard = styled.div`
  background: #EEF1F1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  min-height: ${minAppHeight - topBarHeight}px;
  min-width: ${minAppWidth - navWidth}px;
`;

const DashboardInner = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

const ChartsContainer = styled.div`
  flex: 1;
  position: relative;
`;
