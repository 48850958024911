import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

import AlarmsWarning from './AlarmWarning';
import { AppContext, clearStorageOnLogout } from '../AppDataProvider';
import Logo from './Logo';

const AppBar = () => {
  const { setIsSignedIn, appHeader, anyActiveAlarms } = useContext(AppContext);

  return (
    <Bar>
      <LeftGroup>
        <Title><Logo size="small" type="white" /></Title>
      </LeftGroup>
      <CenterGroup>
        <MonitorTitle>{appHeader}</MonitorTitle>
      </CenterGroup>
      <RightGroup>
        {anyActiveAlarms && <AlarmsWarning />}
        <Button style={{background: '#F8F8F8'}} onClick={logOut} icon="logout">Wyloguj</Button>
      </RightGroup>
    </Bar>
  );

  function logOut() {
    setIsSignedIn(false);
    clearStorageOnLogout();
  }
};

const Bar = styled.div`
  display: flex;
  background: #0E4194;
  justify-content: space-between;
  height: 56px;
`;

const Title = styled.div`
`;

const LeftGroup = styled.div`
  text-align: left;
  width: 167px;
  padding-left: 20px;
`;

const MonitorTitle = styled.div`
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
`;

const CenterGroup = styled.div`
  margin: 0 25px;
  display: flex;
  align-items: center;
`;

const RightGroup = styled.div`
  text-align: right;
  width: 167px;
  padding: 12px 20px 12px 0;
`;

export default AppBar;
