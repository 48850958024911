import React from 'react';
import styled from 'styled-components';

const Section = ({title, children, ...props}) => (
    <StyledSection {...props}>
        {title && (<Title>{title}</Title>)}
        {children}
    </StyledSection>
);

const Title = styled.div`
    font-weight: bold;
    padding: 3px 0 16px;
`;

const StyledSection = styled.div`
    & + & {
        padding-top: 16px;
    }
`;

export default Object.assign(
    Section,
    {
        Title,
    },
);
