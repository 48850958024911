import React, { useContext } from 'react';

import CrudList from '../../../../components/CRUDs/CrudList';
import { AdministrationContext } from '../../AdministrationDataProvider';

const BuildingsList = () => {
  const {
    buildings,
    setCurrentId,
    setMode,
    setKind,
    setIsModalVisible,
  } = useContext(AdministrationContext);

  const showBuildingsModal = id => {
    setIsModalVisible(true);
    setCurrentId(id);
    setKind('Buildings');
  };

  const handleCreateBuilding = () => {
    showBuildingsModal();
    setMode('create');
  };

  const handleReadBuilding = id => {
    showBuildingsModal(id);
    setMode('read');
  };

  const handleUpdateBuilding = id => {
    showBuildingsModal(id);
    setMode('update');
  };

  const handleDeleteBuilding = id => {
    showBuildingsModal(id);
    setMode('delete');
  };

  return (
    <CrudList
      items={buildings.map(building => ({
        name: building.fullName,
        description: `${building.street}, ${building.city}`,
        id: building.id,
      }))}
      addText="Dodaj obiekt"
      onCreate={handleCreateBuilding}
      onRead={handleReadBuilding}
      onUpdate={handleUpdateBuilding}
      onDelete={handleDeleteBuilding}
    />
  );
};

export default BuildingsList;
