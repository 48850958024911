import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from '../AppDataProvider';
import Loading from './Loading';

const MainLoading = () => {
  const { isLoading } = useContext(AppContext);
  return isLoading ? (
    <Layer>
      <Loading message={(typeof(isLoading) === 'string' && isLoading.length > 0) ? isLoading : undefined }/>
    </Layer>
  ) : null;
};

export const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgb(24, 144, 255, 0.5);
`;

export default MainLoading;
