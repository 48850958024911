import React from 'react';
import moment from 'moment';

import { formatNumber } from '../../../../utils/formaters';
import Dictionaries from '../../../../utils/dictionaries';
import { CHART_TYPES } from '../../../../components/CustomChart';
import GainTip from '../../components/GainTip';

const { mapUsageAreaColor, mapUsageAreaDescription, mapUsageAreaShortcut } = Dictionaries;

const barProps = {
  minTickGap: 15,
  interval: "preserveStart",
};

export const monthConfig = {
    dailyHeatConsumption: () => ({
        chartConfig: {
          settings: {
            stopAnimation: false,
            stopInteractive: false,
            title: "",
            showTitle: false,
            showLegend: false,
            horizontalGrid: false,
            verticalGrid: false,
            xFormatter: (e) => moment(e).format("YYYY-MM-DD"),
            yFormatter: (e) => formatNumber(e, {position: -1}),
          },
          xAxis: {
            xKey: "time",
            description: "Czas",
            showDescription: false,
            color: "#333",
            datesNumber: 3,
            withTime: false,
            useDefault: true,
            ...barProps,
          },
          yAxises: [
            {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Zużycie ciepła",
              showDescription: true,
              unit: "kWh",
              rangeFrom: "0",
              rangeTo: "auto",
              rangeSpan: "auto",
            },
          ],
        },
        chartType: CHART_TYPES.BAR_CHART,
        usedData: [
          {
            id: "0",
            description: "Zużycie ciepła",
            dataSource: "dailyHeatConsumption",
            axis: "0",
            showRegresion: false,
            dotted: false,
            lineWidth: 2,
            lineColor: "#4462ba",
            dashed: false,
            dashLength: 5,
            dashSpacing: 5,
            unit: "kWh",
          },
        ],
    }),
    dailyElectricityConsumption: () => ({
        chartConfig: {
          settings: {
            stopAnimation: false,
            stopInteractive: false,
            title: "",
            showTitle: false,
            showLegend: false,
            horizontalGrid: false,
            verticalGrid: false,
            xFormatter: (e) => moment(e).format("YYYY-MM-DD"),
            yFormatter: (e) => formatNumber(e, {position: -1}),
          },
          xAxis: {
            xKey: "time",
            description: "Czas",
            showDescription: false,
            color: "#333",
            datesNumber: 3,
            withTime: true,
            useDefault: true,
            ...barProps,
          },
          yAxises: [
            {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Zużycie energii elekt.",
              showDescription: true,
              unit: "kWh",
              rangeFrom: "0",
              rangeTo: "auto",
              rangeSpan: "auto",
            },
          ],
        },
        chartType: CHART_TYPES.BAR_CHART,
        usedData: [
          {
            id: "0",
            description: "Dzienne zużycie energii elekt.",
            dataSource: "dailyElectricityConsumption",
            axis: "0",
            showRegresion: false,
            dotted: false,
            lineWidth: 2,
            lineColor: "#10C8CA",
            dashed: false,
            dashLength: 5,
            dashSpacing: 5,
            unit: "kWh",
          },
        ],
    }),
    comparedConsumption: ({data}) => ({
        chartConfig: {
          settings: {
            stopAnimation: false,
            stopInteractive: false,
            title: "",
            showTitle: false,
            showLegend: false,
            horizontalGrid: false,
            verticalGrid: false,
            xFormatter: (e) => e,
            yFormatter: (e) => formatNumber(e, {position: -1}),
          },
          xAxis: {
            xKey: "time",
            description: "Czas",
            showDescription: false,
            color: "#333",
            datesNumber: 3,
            withTime: true,
            useDefault: true,
            ...barProps,
          },
          yAxises: [
            {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Zużycie energii",
              showDescription: true,
              unit: "kWh",
              rangeFrom: "0",
              rangeTo: "auto",
              rangeSpan: "auto",
            },
          ],
        },
        chartType: CHART_TYPES.BAR_CHART,
        usedData: [
          {
            id: "0",
            description: "Znormalizowane zużycie energii",
            dataSource: "consumption",
            axis: "0",
            showRegresion: false,
            dotted: false,
            lineWidth: 2,
            lineColor: "#7FD2BE",
            dashed: false,
            dashLength: 5,
            dashSpacing: 5,
            unit: "kWh",
          },
        ],
        extra: <GainTip data={data} dataKey='consumption' />,
    }),
    heatingCurve: () => ({
        chartConfig: {
          settings: {
            // stopAnimation: true,
            stopAnimation: false,
            stopInteractive: false,
            forceDateTooltip: true,
            title: "",
            showTitle: false,
            showLegend: false,
            horizontalGrid: false,
            verticalGrid: false,
            regresionPosition: 'left',
            xFormatter: (e) => formatNumber(e, {position: -1}),
            yFormatter: (e) => formatNumber(e, {position: -1}),
          },
          xAxis: {
            xKey: "outsideTemperature",
            color: "#333",
            showAxis: true,
            description: "Temperatura zewnętrzna",
            showDescription: true,
            unit: "^oC",
            rangeFrom: "-20",
            rangeTo: "12",
            rangeSpan: "4",
          },
          yAxises: [
            {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Dzienne zuż. ciepła",
              showDescription: true,
              unit: "kWh",
              rangeFrom: "auto",
              rangeTo: "auto",
              rangeSpan: "auto",
            },
          ],
        },
        chartType: CHART_TYPES.LINE_CHART,
        usedData: [
          {
            id: "0",
            description: "Dzienne zużycie ciepła",
            dataSource: "heatConsumption",
            axis: "0",
            dotted: true,
            lineWidth: 2,
            lineColor: "#063951",
            dashed: false,
            dashLength: 5,
            dashSpacing: 5,
            unit: "kWh",
            showRegresion: true,
            addRegresionBoundary: true,
            spreadRegresion: true,
          },
        ],
    }),
    energyUsageAreas: () => ({
        isAnimationActive: false,
        chartType: CHART_TYPES.PIE_CHART,
        showEmpty: false,
        showLabel: false,
        showLegend: true,
        outerRadius: "72%",
        descriptionMapper: mapUsageAreaDescription,
        shortcutMapper: mapUsageAreaShortcut,
        colorMapper: mapUsageAreaColor,
        dataKey: "value",
        unit: "kWh",
        showTooltip: true,
        dataFormatter: (e) => formatNumber(e, {position: -1}),
    }),
    dailyOZEProduction: () => ({
        chartConfig: {
          settings: {
            stopAnimation: false,
            stopInteractive: false,
            title: "",
            showTitle: false,
            showLegend: false,
            horizontalGrid: false,
            verticalGrid: false,
            xFormatter: (e) => moment(e).format("YYYY-MM-DD"),
            yFormatter: (e) => formatNumber(e, {position: -1}),
          },
          xAxis: {
            xKey: "time",
            description: "Czas",
            showDescription: false,
            color: "#333",
            datesNumber: 3,
            withTime: true,
            useDefault: true,
            ...barProps,
          },
          yAxises: [
            {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Produkcja energii",
              showDescription: true,
              unit: "kWh",
              rangeFrom: "0",
              rangeTo: "auto",
              rangeSpan: "auto",
            },
          ],
        },
        chartType: CHART_TYPES.BAR_CHART,
        usedData: [
          {
            id: "0",
            description: "Dzienna produkcja elekt. OZE",
            dataSource: "dailyOZEProduction",
            axis: "0",
            showRegresion: false,
            dotted: false,
            lineWidth: 2,
            lineColor: "#2490be",
            dashed: false,
            dashLength: 5,
            dashSpacing: 5,
            unit: "kWh",
          },
        ],
    }),
}
