import React, { useContext, useState } from 'react';
import { Select } from 'antd';

import { AppContext } from '../../../../AppDataProvider';
import CrudList from '../../../../components/CRUDs/CrudList';
import { DbContext } from '../../DbDataProvider';

const { Option } = Select;

const HookUpsList = () => {
  const {
    hookUps,
    setCurrentId,
    setMode,
    setKind,
    setIsModalVisible,
  } = useContext(DbContext);

  const { buildings, enums } = useContext(AppContext);

  const showHookUpsModal = id => {
    setIsModalVisible(true);
    setCurrentId(id);
    setKind('HookUps');
  };

  const handleCreateHookUp = () => {
    showHookUpsModal();
    setMode('create');
  };

  const handleReadHookUp = id => {
    showHookUpsModal(id);
    setMode('read');
  };

  const handleUpdateHookUp = id => {
    showHookUpsModal(id);
    setMode('update');
  };

  const handleDeleteHookUp = id => {
    showHookUpsModal(id);
    setMode('delete');
  };

  const [selectedBuildingId, setSelectedBuildingId] = useState();
  const handleBuildingChange = value => setSelectedBuildingId(value);
  const buildingOptions = buildings.map(building => (
    <Option value={building.id} key={building.id}>
      {building.shortName}
    </Option>
  ));

  const filteredHookUps = hookUps
    .filter(hookUp => hookUp.buildingId === selectedBuildingId)
    .map(hookUp => ({
      name: hookUp.name,
      description: `${enums.hookUpTypes[hookUp.type - 1].name}, nr ${hookUp.number}`,
      id: hookUp.id,
    }));

  return (
    <>
      <Select
        placeholder="Wybierz budynek"
        style={{ width: 350, margin: '20px 20px 20px 4px' }}
        onChange={handleBuildingChange}
      >
        {buildingOptions}
      </Select>
      {selectedBuildingId && (
        <CrudList
          items={filteredHookUps}
          addText="Dodaj przyłącze"
          onCreate={handleCreateHookUp}
          onRead={handleReadHookUp}
          onUpdate={handleUpdateHookUp}
          onDelete={handleDeleteHookUp}
        />
      )}
    </>
  );
};

export default HookUpsList;
