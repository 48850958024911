import React, { useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import { StickyContainer, Sticky } from 'react-sticky';
import { Empty, message } from 'antd';

import { AppContext } from '../../../AppDataProvider';
import { getReportData } from '../services/api';

import ReportConfig from './ReportConfig';
import ConfirmLoad from './ConfirmLoad';
import ReportGeneration from './ReportGeneration';
import Section from '../components/Section';
import StickySection from '../components/StickySection';
import ReportDownload from './ReportDownload';

// Istnieje duże obciążenie przy renderowaniu raportów
// Możliwe jest odczytanie wartości komentarzy za pomocą DOM np:
// buildings.reduce((prev, e) => [...prev, document.getElementById(`comment-${e}-dailyHeatConsumption`)], []).map(e => e.getElementsByTagName('textarea')[0].value)
// Komentarze są zatem bindowane tylko w jedną stronę (przy wgraniu)

export default () => {
    const [reportConfig, setReportConfig] = useState();
    const [reportData, setReportData] = useState();
    const [reportComments, setReportComments] = useState();
    const [reloadAlert, setReloadAlert] = useState();

    const {
        startAsyncTask,
        finishAsyncTask,
        users,
        buildings,
        wrapRequest,
    } = useContext(AppContext);

    const printRef = useRef();

    const loadData = async () => {
        if(reportData) {
            setReloadAlert(true);
        }
        else {
            _loadData(reportConfig);
        }
    }

    const _loadData = async (config) => {
        startAsyncTask("Pobieranie danych z raportu może trwać do minuty.");
        await wrapRequest(() => getReportData(config), {
            onError: () => {
                message.error('Nie udało się wygenerować raportu. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
            },
            onSuccess: (data) => {
                const groupedData = getGroupedData(data, users, buildings);
                setReportData(groupedData);
                setReportConfig(groupedData.config);
                setReportComments(groupedData.comments);
                setReloadAlert(false);
            },
        });        
        finishAsyncTask();
    }

    return (
        <StyledGeneration id="Generation">
            <Section
                title="Wygeneruj raport"
                id="LoadReportData"
            >
                <ReportConfig config={reportConfig} loadData={loadData} onChange={v => setReportConfig(v)}/>
            </Section>
            <Sticky topOffset={100}>
                {({style, isSticky}) =>
                    (<div style={{
                        ...style,
                        ...(isSticky && {
                            zIndex: 1,
                        }),
                    }}>
                        <StickySection isData={reportData} isSticky={reportData && isSticky}/>
                    </div>)}
            </Sticky>
            <Section
                id="ReportGeneration"
                style={{
                    flex: 1,
                }}
            >
                {reportData
                ? (
                <ReportGeneration
                    comments={reportComments}
                    creationDate={reportConfig.date}
                    {...reportData}
                    printRef={printRef}
                />)
                : (<Empty
                    description="Brak pobranych danych do raportu"
                />)}
            </Section>
            {reportData &&
            (<Section
                title="Pobranie raportu"
                id="ReportDownload"
            >
                <ReportDownload
                    config={reportConfig}
                    loadData={loadData}
                    onChange={v => setReportConfig(v)}
                    printRef={printRef.current}
                />
            </Section>)}
            <ConfirmLoad
                visible={reloadAlert}
                onCancel={() => setReloadAlert(false)}
                onOk={() => _loadData(reportConfig)}
            />
        </StyledGeneration>
    );
}

const getGroupedData = ({buildingsUsages, request, ...rest}, users, buildings) => {
    const user = users.find(e => e.id === request.userId);
    const measuredBuildings = buildings.filter(({id, hasMetersInstalled}) => hasMetersInstalled && user.buildingsIds.includes(id)).map(({id}) => id);
    return {
        allBuildings: buildingsUsages,
        measuredBuildings: buildingsUsages.filter(e => measuredBuildings.includes(e.buildingId)),
        request,
        user,
        ...rest,
    }
}

const StyledGeneration = styled(StickyContainer)`
    background: #EEF1F1;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    @media print {
        color: black;
    }
`;
