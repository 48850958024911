import React from 'react';
import styled from 'styled-components';
import { Form, Checkbox } from 'antd';

const CheckboxField = ({value, name, label, formData, onChange, editable, children }) => {
  return (
    <Form.Item label={label}>
      <CrudCheckbox
        value={value || formData[name]}
        onChange={onChange}
        editable={editable}
      >
          {children}
      </CrudCheckbox>
    </Form.Item>
  );
};

const CrudCheckbox = ({ value, onChange, editable, children, ...rest }) => {
  return editable ? (
    <Checkbox checked={value} onChange={onChange} {...rest}>{children}</Checkbox>
  ) : (
    <DisabledCheckbox {...rest} disabled checked={value}>{children}</DisabledCheckbox>
  );
};

const DisabledCheckbox = styled(Checkbox)`
    .ant-checkbox-disabled + span {
        color: rgba(0, 0, 0, 0.85);
        cursor: default;
    }
`;

export default CheckboxField;
