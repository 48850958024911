import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import {
  AdministrationRoute,
  AlarmsRoute,
  BenchmarkingRoute,
  DashboardRoute,
  DataAnalyticsRoute,
  DbRoute,
  HelpRoute,
  ReportsRoute,
} from '../Routes';

import { AppContext } from '../AppDataProvider';

const Nav = ({ location }) => {
  const { isAdmin } = useContext(AppContext);

  const [currentRoute, setCurrentRoute] = useState(DashboardRoute);

  useEffect(
    function reactOnRouteChange() {
      setCurrentRoute(location.pathname);
    },
    [location.pathname],
  );

  return (
    <Container>
      <StyledMenu
        mode="inline"
        selectedKeys={[currentRoute]}
        onClick={e => setCurrentRoute(e.key)}
      >
        <Menu.Item key={DashboardRoute}>
          <Link to={DashboardRoute}>
            <Icon type="dashboard" />
            Pulpit
          </Link>
        </Menu.Item>
        <Menu.Item key={AlarmsRoute}>
          <Link to={AlarmsRoute}>
            <Icon type="bell" />
            Alarmy
          </Link>
        </Menu.Item>
        <Menu.Item key={DataAnalyticsRoute}>
          <Link to={DataAnalyticsRoute}>
            <Icon type="dot-chart" />
            Analiza Danych
          </Link>
        </Menu.Item>
        <Menu.Item key={BenchmarkingRoute}>
          <Link to={BenchmarkingRoute}>
            <Icon type="fund" />
            Porównanie
          </Link>
        </Menu.Item>
        {isAdmin && (
          <Menu.Item key={ReportsRoute}>
            <Link to={ReportsRoute}>
              <Icon type="file-pdf" />
              Raporty
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key={DbRoute}>
          <Link to={DbRoute}>
            <Icon type="database" />
            Baza Danych
          </Link>
        </Menu.Item>
        {isAdmin && (
          <Menu.Item key={AdministrationRoute}>
            <Link to={AdministrationRoute}>
              <Icon type="setting" />
              Administracja
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key={HelpRoute}>
          <Link to={HelpRoute}>
            <Icon type="question-circle" />
            Pomoc
          </Link>
        </Menu.Item>
      </StyledMenu>
    </Container>
  );
};

const StyledMenu = styled(Menu)`
  border: none !important;
  .ant-menu-item-active {
    a {
      color: #d7dfed !important;
    }
  }
  .ant-menu-item:hover {
    a {
      color: #0e4194 !important;
    }
  }
`;

const Container = styled.nav`
  display: flex;
  flex-direction: column;
  margin-left: -8px;
  flex: 0 0 167px;
  max-width: 167px;
  border-right: 1px solid #eef1f1;
`;

export default withRouter(Nav);
