import React from 'react';
import { Select, Form } from 'antd';
import DisabledInput from '../DisabledInput';

const { Option } = Select;

const SelectField = ({
  value,
  disabled,
  items,
  onChange,
  placeholder,
  itemKey,
  label,
  ...rest
}) => {
  const itemDescription = (item) => itemKey ? item[itemKey] : item.name;
  const options = items.map(item => (
    <Option key={item.id} value={item.id}>
      {itemDescription(item)}
    </Option>
  ));
  const _value = disabled && ((v) => v ? itemDescription(v) : 'brak')(items.find(e => e.id === value))

  return (
    <Form.Item label={label}>
      {disabled
      ? (<DisabledInput {...rest} value={_value} />)
      : (<Select
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={{ width: '100%' }}
      >
        {options}
      </Select>)}
    </Form.Item>
  );
};

export default SelectField;
