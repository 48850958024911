import React, {useContext} from 'react';
import styled from 'styled-components';

import CustomChart from '../../../components/CustomChart';
import ChartCard from '../components/ChartCard';

import { DashboardContext } from '../DashboardDataProvider';
import chartConfig, { chartDivisions } from './chartConfig';
import { minAppHeight } from '../../../constants';

export default () => {
  const {
    currentBuilding,
    currentTime,
  } = useContext(DashboardContext);
  const building = currentBuilding();
  const fetchingTime = !building.finished[currentTime];

  const passedChartConfig = (key) =>
    building[currentTime] && building[currentTime][key] &&
    ({
      data: building[currentTime][key].data,
      ...chartConfig[currentTime][key](building[currentTime][key]),
    });

  const shouldHideChart = (key) =>
    !fetchingTime && building[currentTime] &&
    building[currentTime][key] &&
    building[currentTime][key].settings.hide;
  
  const currentElements = chartDivisions[currentTime];
  return (
    <Charts>
      {currentElements.map((row, idx) => {
        const spread = Boolean(row.reduce(
          (prev, {key, enableHidden}) => prev || (shouldHideChart(key) && enableHidden),
          false));
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Row key={`${idx}`}>
            {row.map(({key, enableHidden, title}, idx2) =>
              <Cell
                  loading={fetchingTime}
                  title={title}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${idx}-${idx2}`}
                  spread={spread}
                  hide={(shouldHideChart(key) && enableHidden)}
                >
                  <CustomChart
                    {...passedChartConfig(key)}
                  />
              </Cell>)}
          </Row>
        );
      })}
    </Charts>
  )
}

const Cell = ({children, loading, title, spread, hide}) => (
  <StyledCell
    style={{
      width: hide
      ? '0'
      : `calc((100% - 16px) / ${spread ? 2 : 3})`,
      opacity: hide ? 0 : 1,
    }}
  >
    <ChartCard
      loading={loading}
      title={title}
      spread={spread}
      hide={hide}
    >
      {children}
    </ChartCard>
  </StyledCell>
);

const Charts = styled.div`
  width: 100%;
  height: calc(100vh - 136px);
  @media (max-height: 800px) {
    height: calc(100vh - 120px);
    min-height: ${minAppHeight - 120}px;
  }
`;

const Row = styled.div`
  & + & {
    margin-top: -8px;
  }
`;

const StyledCell = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: center;
  transition: 1000ms all;
  overflow: hidden;
`;
