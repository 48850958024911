export const mapRangeTypeToColor = rangeType => {
  switch (rangeType) {
    case 'Safe':
      return 'green';
    case 'Warning':
      return 'orange';
    case 'Danger':
      return 'red';
    default:
      return 'black';
  }
};
