import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Button, Tabs } from 'antd';

import CheckboxField from '../../../../components/CRUDs/CheckboxField';
import NumberField from '../../../../components/CRUDs/NumberField';
import SelectField from '../../../../components/CRUDs/SelectField';
import TextField from '../../../../components/CRUDs/TextField';

import { AppContext } from '../../../../AppDataProvider';
import { AdministrationContext } from '../../../Administration/AdministrationDataProvider';

const { TabPane } = Tabs;

const TabWrapper = styled.div`
  height: 500px;
  overflow-y: scroll;
  padding-right: 16px;
  position: relative;
`;

const BuildingForm = ({
  editable,
  initialState,
  actionLabel,
  onCancel,
  onAction,
  removeMode = false,
}) => {
  const data = initialState || {
    // Admin
    fullName: '',
    shortName: '',
    street: '',
    city: '',
    postalCode: '',
    postOffice: '',
    hasMetersInstalled: true,
    category: 1,
    userId: 0,
    // User
    yearOfConstruction: 0,
    modernisationDescription: '',
    hasThermostaticValves: true,
    cubature: 0,
    mainElectricityHookUpPower: 0,
    mainHeatHookUpPower: 0,
    coolingType: undefined,
    lightingType: undefined,
    ventilationType: undefined,
    heatingSourceType: undefined,
    heatingEnergyCarrierType: undefined,
    renewableEnergySourceType: undefined,
    domesticHotWaterSourceType: undefined,
  };

  const { enums } = useContext(AppContext);
  const { users } = useContext(AdministrationContext);

  const [formData, setFormData] = useState(data);

  const onChange = (fieldName, e) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
    });
  };

  const handleAction = () => {
    onAction(formData);
    onCancel();
  };

  const adminProps = { editable: false, formData };

  const handleValvesCheck = e =>
    setFormData({
      ...formData,
      hasThermostaticValves: e.target.checked,
    });

  const onSelect = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const userProps = { editable, onChange: onSelect, formData };
  const textProps = { editable, onChange, formData };

  return (
    <div>
      {removeMode ? (
        <div>Czy na pewno chcesz usunąć budynek?</div>
      ) : (
        <Tabs defaultActiveKey="userData">
          <TabPane tab="Dane administratora" key="adminData">
            <TabWrapper>
              <TextField {...adminProps} name="fullName" label="Pełna Nazwa" />
              <TextField {...adminProps} name="shortName" label="Skrót" />
              <TextField {...adminProps} name="street" label="Ulica" />
              <TextField {...adminProps} name="city" label="Miejscowość" />
              <TextField
                {...adminProps}
                name="postalCode"
                label="Kod pocztowy"
              />
              <TextField {...adminProps} name="postOffice" label="Poczta" />
              <CheckboxField {...adminProps} name="hasMetersInstalled">
                Budynek opomiarowany
              </CheckboxField>
              <SelectField
                label="Kategoria budynku"
                disabled
                value={formData.category}
                items={enums.buildingTypes}
              />
              <SelectField
                label="Przypisany użytkownik"
                disabled
                value={formData.userId}
                items={users}
              />
            </TabWrapper>
          </TabPane>
          <TabPane tab="Dane użytkownika" key="userData">
            <TabWrapper>
              <NumberField
                {...userProps}
                name="yearOfConstruction"
                label="Rok budowy"
              />
              <TextField
                {...textProps}
                name="modernisationDescription"
                label="Opis modernizacji"
              />
              <CheckboxField
                {...userProps}
                name="hasThermostaticValves"
                onChange={handleValvesCheck}
              >
                Posiada zawory termostatyczne
              </CheckboxField>
              <NumberField {...userProps} name="cubature" label="Kubatura" />
              <NumberField
                {...userProps}
                name="orderedElectricPower"
                label="Moc głównego przyłącza elektrycznego"
              />
              <NumberField
                {...userProps}
                name="orderedHeatPower"
                label="Moc głównego przyłącza cieplnego"
              />
              <SelectField
                label="Chłodzenie"
                placeholder="Wybierz typ chłodzenia"
                onChange={v => onSelect('coolingType', v)}
                disabled={!editable}
                value={formData.coolingType}
                items={enums.coolingTypes}
              />
              <SelectField
                label="Oświetlenie"
                placeholder="Wybierz typ oświetlenia"
                onChange={v => onSelect('lightingType', v)}
                disabled={!editable}
                value={formData.lightingType}
                items={enums.lightingTypes}
              />
              <SelectField
                label="Wentylacja"
                placeholder="Wybierz typ wentylacji"
                onChange={v => onSelect('ventilationType', v)}
                disabled={!editable}
                value={formData.ventilationType}
                items={enums.ventilationTypes}
              />
              <SelectField
                label="Źródło ciepła"
                placeholder="Wybierz typ źródła ciepła"
                onChange={v => onSelect('heatingSourceType', v)}
                disabled={!editable}
                value={formData.heatingSourceType}
                items={enums.heatingSourceTypes}
              />
              <SelectField
                label="Nośnik energii cieplnej"
                placeholder="Wybierz typ nośnika energii cieplnej"
                onChange={v => onSelect('heatingEnergyCarrierType', v)}
                disabled={!editable}
                value={formData.heatingEnergyCarrierType}
                items={enums.heatingEnergyCarrierTypes}
              />
              <SelectField
                label="Odnawialne źródła energii"
                placeholder="Wybierz typ odnawialnego źródła energii"
                onChange={v => onSelect('renewableEnergySourceType', v)}
                disabled={!editable}
                value={formData.renewableEnergySourceType}
                items={enums.renewableEnergySourceTypes}
              />
              <SelectField
                label="Źródło ciepłej wody użytkowej"
                placeholder="Wybierz typ źródła ciepłej wody"
                onChange={v => onSelect('domesticHotWaterSourceType', v)}
                disabled={!editable}
                value={formData.domesticHotWaterSourceType}
                items={enums.domesticHotWaterSourceTypes}
              />
            </TabWrapper>
          </TabPane>
        </Tabs>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
          padding: '0 30px',
        }}
      >
        <Button onClick={onCancel}>Anuluj</Button>
        <Button type="primary" onClick={handleAction}>
          {actionLabel}
        </Button>
      </div>
    </div>
  );
};

export default BuildingForm;
