import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

const { Option } = Select;

export default Object.assign(
    ({data, ...props}) => (
      <Select {...props}>
        {data.map(({ id, description }) => (
          <Option key={id} value={id}>
            {description}
          </Option>
        ))}
      </Select>
    ),
    {
      propTypes: {
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
          }),
        ),
      },
    },
  );

export const handleAxisData = ({yAxises}) => {
    const axisData = yAxises.map(({id, fields}) => ({
      id,
      description: fields.description,
    }));
    return {
      axisData,
      defaultAxisData: axisData[0].id,
    };
  };
