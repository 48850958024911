import React from 'react';
import styled from 'styled-components';
import { Legend } from 'recharts';

import MathParser from '../MathParser';

export default () => <Legend
    formatter={(value, entry) => <LegendTick color={entry.color}><MathParser>{value}</MathParser></LegendTick> }
    verticalAlign="bottom"
/>

const LegendTick = styled.div`
    display: inline;
    ${({color}) => color ? `color: ${color};` : ''}
`;
