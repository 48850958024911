import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import interpolateHSL from './colorInterpolation';
import MathParser from '../../../../components/MathParser';
import { formatNumber } from '../../../../utils/formaters';

const Gauge = ({
  title,
  // Values
  value = 60,
  minValue = 0,
  maxValue = 100,
  // Styling
  startColor = '#33CC33',
  endColor = '#FF471A',
  segmentColors,
  segmentsCount = 10,
  // Sizing
  innerRadius = 90,
  outerRadius = 180,
  // Needle
  needleClassname = '',
}) => {
  const canvasRef = useRef();
  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    drawGauge(ctx, segmentsCount, startColor, endColor);
  }, []);

  const needleAngle = 180 * (value / maxValue) - 90;
  const normalizedNeedleAngle = (maxValue <= 0) ? 0 : parseInt(
    Math.max(-90, Math.min(needleAngle, 90)),
    10,
  );

  const normalizedFactor = Math.min(1, value / maxValue);
  const valueColor = interpolateHSL(startColor, endColor, normalizedFactor);

  return (
    <Container>
      <LeftLabel>{formatNumber(minValue, {position: -1})}</LeftLabel>
      <div>
        <TopLabel>{formatNumber(maxValue / 2, {position: -1})}</TopLabel>
        <div style={{ position: 'relative' }}>
          <canvas
            ref={canvasRef}
            width={2 * outerRadius}
            height={outerRadius}
          />
          <Needle
            outerRadius={outerRadius}
            className={needleClassname}
            angle={normalizedNeedleAngle}
          />
        </div>
        <ValueLabel color={valueColor}>{formatNumber(value, {position: -1})}</ValueLabel>
        {title && <Title><MathParser>{title}</MathParser></Title>}
      </div>
      <RightLabel>{formatNumber(maxValue, {position: -1})}</RightLabel>
    </Container>
  );

  function drawSegment(ctx, segmentNo, arcAngle, color) {
    const startAngle = segmentNo * arcAngle;
    const endAngle = (segmentNo + 1) * arcAngle;
    const ringWidth = outerRadius - innerRadius;

    ctx.beginPath();
    ctx.arc(
      outerRadius,
      outerRadius,
      outerRadius,
      Math.PI + startAngle,
      Math.PI + endAngle,
    ); // outer
    const { x, y } = getLineCords(innerRadius, endAngle);
    ctx.lineTo(ringWidth + x, outerRadius - y);
    ctx.arc(
      outerRadius,
      outerRadius,
      innerRadius,
      -(Math.PI - endAngle),
      -(Math.PI - startAngle),
      true,
    ); // inner
    const { x: endX, y: endY } = getLineCords(outerRadius, startAngle);
    ctx.lineTo(endX, outerRadius - endY);
    ctx.closePath();

    ctx.strokeStyle = 'white';
    // ctx.lineWidth = 3;
    // ctx.stroke();
    ctx.fillStyle = color;
    ctx.fill();

    function getLineCords(r, alfa) {
      return {
        x: r - r * Math.cos(alfa),
        y: r * Math.sin(alfa),
      };
    }
  }

  function drawGauge(ctx, segmentsCount, startColor, endColor) {
    const colors = getColors(startColor, endColor, segmentsCount);
    const arcAngle = Math.PI / segmentsCount;
    [...Array(segmentsCount).keys()].map(num =>
      drawSegment(ctx, num, arcAngle, colors[num]),
    );
  }

  function getColors(startColor, endColor, segmentsCount) {
    return (
      segmentColors ||
      [...Array(segmentsCount).keys()]
        .map(num => num / segmentsCount)
        .map(factor => interpolateHSL(startColor, endColor, factor))
    );
  }
};

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;
const Container = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 20px;
  font-weight: bold;
`;

const TopLabel = styled.div`
  width: 100%;
  text-align: center;
`;

const RightLabel = styled.div`
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 66px;
`;

const LeftLabel = styled.div`
  margin-right: 10px;
  margin-bottom: 66px;
`;

const ValueLabel = styled.div`
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: ${p => p.color};
`;

const Needle = styled.div`
  height: ${p => p.outerRadius * 0.85}px;
  width: 10px;
  position: absolute;
  left: ${p => p.outerRadius}px;
  top: ${p => p.outerRadius}px;
  background: #333;
  transform: translate(-50%, -100%) rotate(${p => p.angle}deg);
  transform-origin: 50% 100%;
  clip-path: polygon(50% 0, 80% 20%, 100% 100%, 0 100%);
  border-radius: 0 0 15px 15px;
`;

export default Gauge;
