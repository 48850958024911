import React, { useState, useContext } from 'react';
import { Button, Tabs, Form } from 'antd';

import CheckboxField from '../../../../components/CRUDs/CheckboxField';
import TextField from '../../../../components/CRUDs/TextField';
import NumberField from '../../../../components/CRUDs/NumberField';
import SelectField from '../../../../components/CRUDs/SelectField';
import { AppContext } from '../../../../AppDataProvider';
import { AdministrationContext } from '../../AdministrationDataProvider';

import MeterLabels from './MeterLabels';

const BuildingForm = ({
  editable,
  initialState,
  actionLabel,
  onCancel,
  onAction,
}) => {
  const data = initialState || {
    fullName: '',
    shortName: '',
    street: '',
    city: '',
    postOffice: '',
    area: 0,
    orderedElectricPower: 0,
    orderedHeatPower: 0,
    renewableEnergySourcesPower: 0,
    hasMetersInstalled: false,
    managerEmail: '',
    category: 1,
    userId: undefined,
  };

  const { enums } = useContext(AppContext);
  const { users, mode } = useContext(AdministrationContext);

  const [formData, setFormData] = useState(data);

  const onChange = (fieldName, e) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
    });
  };

  const onNumberChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleMetersCheck = e =>
    setFormData({
      ...formData,
      hasMetersInstalled: e.target.checked,
    });

  const handleAction = () => {
    onAction(formData);
    onCancel();
  };

  const handleUserSelect = userId =>
    setFormData({
      ...formData,
      userId,
    });

  const handleCategorySelect = categoryId =>
    setFormData({
      ...formData,
      category: categoryId,
    });

  const textProps = {
    editable,
    onChange,
    formData,
  };

  const numberProps = {
    editable,
    onChange: onNumberChange,
    formData,
  };

  const checkboxProps = {
    editable,
    formData,
  }

  const actions = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
        padding: '0 30px',
      }}
    >
      <Button onClick={onCancel}>Anuluj</Button>
      <Button type="primary" onClick={handleAction}>
        {actionLabel}
      </Button>
    </div>
  );

  if (mode === 'delete') {
    return (
      <>
        <div>Czy na pewno chcesz usunąć budynek?</div>
        {actions}
      </>
    );
  }

  return (
    <Tabs defaultActiveKey="data">
      <Tabs.TabPane tab="Dane" key="data">
        <div style={{paddingRight: "16px"}}>
          <Form layout="vertical">
            {mode !== 'create' && (
              <TextField
                formData={formData}
                editable={false}
                name="id"
                label="Id"
              />
            )}
            <TextField {...textProps} name="fullName" label="Pełna Nazwa" />
            <TextField {...textProps} name="shortName" label="Skrót" />
            <TextField {...textProps} name="street" label="Ulica" />
            <TextField {...textProps} name="city" label="Miejscowość" />
            <TextField {...textProps} name="postalCode" label="Kod pocztowy" />
            <TextField {...textProps} name="postOffice" label="Poczta" />
            <NumberField {...numberProps} name="area" label="Powierzchnia" />
            <NumberField
              {...numberProps}
              name="orderedElectricPower"
              label="Moc zamówiona energ. elek."
            />
            <NumberField
              {...numberProps}
              name="orderedHeatPower"
              label="Moc zamówiona ciepło"
            />
            <NumberField
              {...numberProps}
              name="renewableEnergySourcesPower"
              label="Moc instalacji OZE"
            />
            <CheckboxField
              {...checkboxProps}
              onChange={handleMetersCheck}
              name="hasMetersInstalled"
            >
              Budynek opomiarowany
            </CheckboxField>
            <TextField
              {...textProps}
              name="managerEmail"
              label="E-mail kierownika"
            />
            <SelectField
              label="Kategoria budynku"
              placeholder="Wybierz kategorię"
              onChange={handleCategorySelect}
              disabled={!editable}
              value={formData.category}
              items={enums.buildingTypes}
            />
            <SelectField
              label="Przypisany użytkownik"
              placeholder="Wybierz użytkownika"
              onChange={handleUserSelect}
              disabled={!editable}
              value={formData.userId}
              items={users.filter(user => user.roleId === 2)}
            />
          </Form>
          {actions}
        </div>
      </Tabs.TabPane>
      {mode !== 'create' && (
        <Tabs.TabPane tab="Etykiety liczników" key="labels">
          <MeterLabels buildingId={formData.id} closeModal={onCancel} />
        </Tabs.TabPane>
      )}
    </Tabs>
  );
};

export default BuildingForm;
