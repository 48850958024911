/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';
import { LocaleProvider } from 'antd';
import pl_PL from 'antd/lib/locale-provider/pl_PL';
import moment from 'moment';
import 'moment/locale/pl';
import { Route, Switch, Redirect } from 'react-router';

import { minAppHeight, minAppWidth, scrollWidth } from './constants';

import './overrides.css';

import {
  AdministrationRoute,
  AlarmsRoute,
  DashboardRoute,
  DataAnalyticsRoute,
  SignInRoute,
  ReportsRoute,
  DbRoute,
  BenchmarkingRoute,
  HelpRoute,
} from './Routes';

import CookiesInfo from './components/CookiesInfo';
import Error from './components/Error';
import Layout from './components/Layout';
import Authentication from './components/Authentication';
import MainLoading from './components/MainLoading';
import AppDataProvider from './AppDataProvider';

import SignIn from './scenes/SignIn/SignIn';
import Administration from './scenes/Administration';
import AdministrationDataProvider from './scenes/Administration/AdministrationDataProvider';
import Analysis from './scenes/Analysis';
import AnalysisDataProvider from './scenes/Analysis/AnalysisDataProvider';
import Dashboard from './scenes/Dashboard';
import DashboardDataProvider from './scenes/Dashboard/DashboardDataProvider';
import Reports from './scenes/Reports';
import ReportsDataProvider from './scenes/Reports/ReportsDataProvider';

import Alarms from './scenes/Alarms';
import AlarmsDataProvider from './scenes/Alarms/AlarmsDataProvider';

import Db from './scenes/Db';
import DbDataProvider from './scenes/Db/DbDataProvider';

import Benchmarking from './scenes/Benchmarking';
import BenchmarkingDataProvider from './scenes/Benchmarking/BenchmarkingDataProvider';

import Help from './scenes/Help';

moment.locale('pl');

const App = () => (
  <StyledApp>
    <LocaleProvider locale={pl_PL}>
      <AppDataProvider>
        <MainLoading />
        <Error />
        <CookiesInfo />
        <Switch>
          <Route path={SignInRoute}>
            <SignIn />
          </Route>
          <Authentication>
            <DashboardDataProvider>
              <AdministrationDataProvider>
                <Layout>
                  <Switch>
                    <Route exact path="/">
                      <Redirect from="/" to={DashboardRoute} />
                    </Route>
                    <Route path={DashboardRoute}>
                      <Dashboard />
                    </Route>
                    <Route path={AdministrationRoute}>
                      <Administration />
                    </Route>
                    <Route path={DataAnalyticsRoute}>
                      <AnalysisDataProvider>
                        <Analysis />
                      </AnalysisDataProvider>
                    </Route>
                    <Route path={AlarmsRoute}>
                      <AlarmsDataProvider>
                        <Alarms />
                      </AlarmsDataProvider>
                    </Route>
                    <Route path={ReportsRoute}>
                      <ReportsDataProvider>
                        <Reports />
                      </ReportsDataProvider>
                    </Route>
                    <Route path={DbRoute}>
                      <DbDataProvider>
                        <Db />
                      </DbDataProvider>
                    </Route>
                    <Route path={BenchmarkingRoute}>
                      <BenchmarkingDataProvider>
                        <Benchmarking />
                      </BenchmarkingDataProvider>
                    </Route>
                    <Route path={HelpRoute}>
                      <Help />
                    </Route>
                  </Switch>
                </Layout>
              </AdministrationDataProvider>
            </DashboardDataProvider>
          </Authentication>
        </Switch>
      </AppDataProvider>
    </LocaleProvider>
  </StyledApp>
);

const StyledApp = styled.div`
  min-width: ${minAppWidth - scrollWidth}px;
  min-height: ${minAppHeight}px;
  height: 100%;
`;

export default App;
