import React from 'react';
import { Icon, Select } from "antd";
import styled from 'styled-components';

import { getMeasurementLabel, getMultipleLabelFromLoaded } from './MeasurementsPicker';
import MathParser from '../../../components/MathParser';

const { Option } = Select;

const addMeasurements = (prev, {measurements}) => {
  const result = {...prev};
  measurements.forEach(m => {
    if(result[m]) {
      result[m] += 1;
    }
    else {
      result[m] = 1;
    }
  });
  return result;
}

export default ({loadedData, ...props}) => {
  const measurements = loadedData.reduce((prev, {fields}) => addMeasurements(prev, fields), {
    time: loadedData.length,
  });
  return (
  <StyledSelect>
    <Select {...props}>
      {Object.keys(measurements).map(key => (
        <Option key={key} value={key}>
          <StyledOption>
            {
              key === "time"
              ? (<StyledPlaceholder>
                  <Icon type="clock-circle" theme="twoTone" />
                </StyledPlaceholder>)
              : (warning =>
                <StyledPlaceholder warning={warning} >
                  <Icon type={warning ? 'warning' : 'check'} />
                </StyledPlaceholder>
              )(measurements[key] !== loadedData.length)
            }
            <MathParser>
              {key.includes('_')
              ? getMultipleLabelFromLoaded(key, loadedData)
              : getMeasurementLabel(key, [])}
            </MathParser>
          </StyledOption>
        </Option>),
      )}
    </Select>
    {(props.value && (measurements[props.value] !== loadedData.length)) &&
      <Warning>
        Część danych nie zostanie ujęta na wykresie.
      </Warning>}
  </StyledSelect>
  );
}

const StyledOption = styled.div`
  min-width: 360px;
  display: flex;
`;

const StyledPlaceholder = styled.div`
  flex: 32px 0 0;
  color: ${({warning}) => warning ? '#faad14' : '#52c41a'};
`;

const Warning = styled.div`
  position: absolute;
  bottom: -24px;
  color: #faad14;
`;

const StyledSelect = styled.div`
  position: relative;
`;
