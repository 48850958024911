import React from "react";
import PropTypes from "prop-types";
import { TreeSelect } from "antd";

import { generateDataDescription, generateDataSource } from '../scenes/ChartCreate/ChartSetup/utils/constants';
import { DataType } from "../scenes/ChartCreate/ChartSetup/utils/types";

import MathParser from "../../../components/MathParser";

export default Object.assign(
    ({loadedData, ...props}) => {
      const genTreeData = () => {
        return loadedData.map(({id, fields}) => ({
          title: fields.description,
          value: `${id}`,
          selectable: false,
          key: `${id}`,
          children: fields.measurements.map(set => ({
            title: <MathParser>{generateDataDescription(fields.description, set)}</MathParser>,
            value: generateDataSource(id, set),
            key: generateDataSource(id, set),
          })),
        }));
      };
      return (
        <TreeSelect
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          treeData={genTreeData()}
          placeholder="Wybierz załadowane dane"
          treeDefaultExpandAll
          {...props}
        />
      );
    },
    {
      propTypes: {
        loadedData: PropTypes.arrayOf(PropTypes.shape(DataType)).isRequired,
      },
    },
  );
