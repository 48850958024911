import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import {isNil} from 'lodash';
import { Select, Radio, Alert } from 'antd';

import Gauge from './Gauge';
import { AppContext } from '../../../../AppDataProvider';
import { AdministrationContext } from '../../../Administration/AdministrationDataProvider';
import { BenchmarkingContext } from '../../BenchmarkingDataProvider';

const GaugesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const GaugeWrapper = styled.div`
  margin-right: 80px;
  margin-left: 80px;
  margin-top: 20px;
  @media (max-width: 1440px) {
    margin-right: 40px;
    margin-left: 40px;
    margin-top: 10px;
  }
  @media (max-width: 1366px) {
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 10px;
  }
`;

const Database = () => {
  const { enums } = useContext(AppContext);
  const { buildings, fetchBuildings } = useContext(AdministrationContext);
  const { databaseBenchmark, fetchDatabaseBenchmark } = useContext(
    BenchmarkingContext,
  );

  const [selectedBuildingId, setSelectedBuildingId] = useState();
  const [benchmarkType, setBenchmarkType] = useState('database');

  useEffect(function getBuildings() {
    fetchBuildings();
  }, []);
  useEffect(() => {
    const params = {
      buildingId: selectedBuildingId,
      compareOnlyWithinSameCategory:
        benchmarkType === 'category',
    };
    if(!isNil(selectedBuildingId)){
      fetchDatabaseBenchmark(params);
    }
  }, [selectedBuildingId, benchmarkType]);

  const buildingsOptions = buildings.map(building => (
    <Select.Option key={building.id} value={building.id}>
      {building.shortName}
    </Select.Option>
  ));

  const selectedBuilding = buildings.find(
    building => building.id === selectedBuildingId,
  );
  const buildingCategory = selectedBuilding
    ? enums.buildingTypes.find(
        buildingType => buildingType.id === selectedBuilding.category,
      )
    : null;

  const handleBuildingSelect = id => setSelectedBuildingId(id);
  const handleBenchmarkTypeCheck = event =>
    setBenchmarkType(event.target.value);

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <Select
          style={{ width: 300 }}
          placeholder="Wybierz budynek do porównania"
          onChange={handleBuildingSelect}
        >
          {buildingsOptions}
        </Select>
        {selectedBuilding && (
          <>
            <BenchmarkRadio
              handleBenchmarkTypeCheck={handleBenchmarkTypeCheck}
              benchmarkType={benchmarkType}
              buildingCategory={buildingCategory}
            />
            <Alert
              description="Wartość nad wykresem określa wartość średnią."
              type="info"
              showIcon
            />
          </>
        )}
      </div>
      {selectedBuilding && databaseBenchmark && <Gauges data={databaseBenchmark} />}
    </div>
  );
};

const Gauges = ({ data }) => {
  return (
    <GaugesContainer>
      {data.map(({ value, title, avg }) => (
        <GaugeWrapper key={title}>
          <Gauge value={value} maxValue={2 * avg} title={title} />
        </GaugeWrapper>
      ))}
    </GaugesContainer>
  );
};

const BenchmarkRadio = ({
  handleBenchmarkTypeCheck,
  benchmarkType,
  buildingCategory,
}) => {
  return (
    <div style={{ marginLeft: 20 }}>
      <div>Porównaj w: </div>
      <Radio.Group onChange={handleBenchmarkTypeCheck} value={benchmarkType}>
        <Radio value="database">Całej bazie</Radio>
        <Radio value="category">{`Kategorii "${buildingCategory.name}"`}</Radio>
      </Radio.Group>
    </div>
  );
};

export default Database;
