import React, { Fragment } from "react";
import { filter } from "lodash";
import styled from 'styled-components';

import MathParser from "../MathParser";
import { isTimeChart } from "./utils/filters";
import { formatDate, formatDateTime, isFilledString } from "../../utils/formaters";

export default ({ usedData, xAxis, ranges }) => {
    const { xKey, withTime } = xAxis;
    const isTime = isTimeChart(xKey);
    const calculate = filter(usedData, e => e.showRegresion).map(
      ({ regresion, ...rest }) => {
        const { a, b } = regresion;
        let nb = b;
        const domainMin = ranges.domainMin || 0;
        let begin = 0;
        if (isTime) {
          nb += a * domainMin;
          begin = domainMin;
        }
        return {
          ...rest,
          regresion: {
            ...regresion,
            b: nb,
            begin,
          },
        };
      },
    );
    const msInHour = 1000 * 60 * 60;
    return calculate.length > 0 ? (
      <StyledRegresionSummary>
        {calculate.map(({ description, regresion, unit, dataSource }) => (
          <RegresionValue key={dataSource}>
            <Title>
              <MathParser>
                {description}
              </MathParser>
            </Title>
            <Value>
              {isTime
                ? `( ${(
                    regresion.a * (withTime ? msInHour : msInHour * 24)
                  ).toExponential(4)} ) · czas + ${regresion.b.toFixed(2)}`
                : `${regresion.a.toExponential(4)} · x + ${regresion.b.toFixed(2)}`}
            </Value>
            {isTime &&
              <Fragment>
                <Value>
                  {`Początek x=0 dla ${((formatter) => formatter(regresion.begin))(withTime ? formatDateTime : formatDate)}`}
                </Value>
                <Value>
                  <MathParser>
                    {`Jednostka przyrostu ${formatUnit(xAxis.unit, unit, isTime, withTime)}`}
                  </MathParser>
                </Value>
              </Fragment>}
            <Value>
              <MathParser>{`R^2 ${regresion.R2.toFixed(4)}`}</MathParser>
            </Value>
          </RegresionValue>
        ))}
      </StyledRegresionSummary>
    ) : null;
  };

  const formatUnit = (unit, yUnit, isTime, withTime) => 
    ((topUnit, lowerUnit, timeUnit) => `${topUnit}/${isTime ? timeUnit : lowerUnit}`)(
      isFilledString(yUnit) ? yUnit : "1",
      isFilledString(unit) ? unit : "1",
      withTime ? "godzinę" : "dzień",
    );

export const StyledRegresionSummary = styled.div`
  font-size: 0.9em;
  padding: 4px;
`;

const RegresionValue = styled.div`
  & + & {
    margin-top: 4px;
  }
`;

const Title = styled.div`
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 4px;
`;

const Value = styled.div``;
