import React, { useState, useContext } from 'react';
import moment from 'moment';
import { isNil } from 'lodash';
import { Button, DatePicker, Form } from 'antd';

import NumberField from '../../../../components/CRUDs/NumberField';
import SelectField from '../../../../components/CRUDs/SelectField';
import { DbContext } from '../../DbDataProvider';
import { getHookUpUnit } from './services/services';

const { MonthPicker } = DatePicker;

const InvoiceForm = ({
  editable,
  initialState,
  actionLabel,
  onCancel,
  onAction,
  removeMode = false,
}) => {
  const data = initialState || {
    month: null,
    monthlyUsage: 0,
    hookUpId: undefined,
  };

  const [formData, setFormData] = useState({
    ...data,
    month: isNil(data.month) ? data.month : moment(data.month),
  });
  const { hookUps } = useContext(DbContext);

  const onChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleAction = () => {
    onAction({
      ...formData,
      month: moment(formData.month).format(),
    });
    onCancel();
  };

  return (
    <div>
      {removeMode ? (
        <div>Czy na pewno chcesz usunąć fakturę ? </div>
      ) : (
        <>
          <Form.Item label="Miesiąc">
            <MonthPicker
              disabled={!editable}
              format="MM-YYYY"
              value={formData.month}
              onChange={v => onChange('month', v)}
              placeholder="Wybierz miesiąc"
            />
          </Form.Item>
          <NumberField
            editable={editable}
            onChange={onChange}
            formData={formData}
            name="monthlyUsage"
            label="Zużycie"
            formatter={value =>
              `${value} ${getHookUpUnit(formData.hookUpId, hookUps)}`
            }
          />
          <SelectField
            label="Przyłącze"
            placeholder="Wybierz przyłącze"
            onChange={v => onChange('hookUpId', v)}
            disabled={!editable}
            value={formData.hookUpId}
            items={hookUps}
          />
        </>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
          padding: '0 30px',
        }}
      >
        <Button onClick={onCancel}>Anuluj</Button>
        <Button type="primary" onClick={handleAction}>
          {actionLabel}
        </Button>
      </div>
    </div>
  );
};

export default InvoiceForm;
