import React from 'react';
import styled from 'styled-components'
import { message, Button, Card, Icon } from 'antd';

const email = "monitoring@kape.gov.pl";
const instructionPath = "/Instrukcja%20obsługi%20Systemu%20KAPE%20monitoring.pdf";
const instructionFileName = "InstrukcjaMonitoring.pdf";

const copyEmail = () => {
    const textArea = document.createElement("textarea");
    textArea.value = email;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        if(!successful) throw new Error();
        message.success('Skopiowano email do schowka!');
    } catch (err) {
        message.warning('Nie udało się skopiować adresu do schowka');
    }
  document.body.removeChild(textArea);
}

export default ({dashboard}) => (
    <Help>
        <StyledCard bordered={false} style={{ background: '#EEF1F1' }}>
            <Message>
                {dashboard && (<Section>
                        <b>Brak opomiarowanych budynków</b>
                    </Section>)}
                <Section>
                    W przypadku wystąpienia problemów technicznych<br />z działaniem systemu prosimy o kontakt z Administratorem.
                </Section>
                <Section>
                    <InnerSection>
                        <b>Krajowa Agencja Poszanowania Energii S.A.</b>
                    </InnerSection>
                    <InnerSection>
                        Tel. 22 626 09 10
                    </InnerSection>
                    <InnerSection>
                        <Button
                            type="link"
                            icon='mail'
                            size="large"
                            style={{fontSize: '1.5rem'}}
                            onClick={copyEmail}
                        >
                            {email}
                        </Button>
                    </InnerSection>
                </Section>
                <Section>
                    <InnerSection>
                        Instrukcja obsługi Systemu KAPE monitoring
                    </InnerSection>
                    <InnerSection>
                        <a href={instructionPath} download={instructionFileName}><Icon type="download" /> Pobierz</a>
                    </InnerSection>
                </Section>
            </Message>
        </StyledCard>
    </Help>
);

const Help = styled.div`
  background: #EEF1F1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
`;

const Message = styled.div`
    max-width: 696px;
    padding: 24px 32px 48px;
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
`;

const Section = styled.div`
    & + & {
        padding-top: 48px;
    }
`;

const InnerSection = styled.div`
    & + & {
        padding-top: 8px;
    }
`;

const StyledCard = styled(Card)`
    position: relative;
    top: -48px;
`;
