import React from 'react';
import styled from 'styled-components';

const Row = styled.div`
    display: table-row;
`;

const StyledCell = styled.div`
    display: table-cell;
    border: solid 1px black;
    @media print {
        page-break-inside: avoid;
    }
`;

const CellInner = styled.div`
    padding: 8px;
    min-height: 24px;
`;

const Table = styled.div`
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border: ${({noBorder}) => noBorder ? 'none' : 'solid 2px black'};
    color: black;
    text-align: center;

    ${Row}:first-child {
        font-weight: bold;
        ${StyledCell} {
            border: ${({noBorder}) => noBorder ? 'none' : 'solid 2px black'};
        }
    }
    ${StyledCell} {
        ${({noBorder}) => noBorder ? 'border: none' : ''}
    }
`;

const Cell = ({children, ...props}) => (
    <StyledCell {...props}>
        <CellInner>
            {children}
        </CellInner>
    </StyledCell>);

export default Object.assign(
    (props) => (<Table {...props} />),
    {
        Row,
        Cell,
    },
);
