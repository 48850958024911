import React, { Component } from 'react';
import styled from 'styled-components';
import { filter, sortBy, isNil } from 'lodash';
import moment from 'moment';

import ChartSetup from './ChartSetup';
import LineChart from '../../../../components/Charts/LineChart';

import { defaultChartConfig } from './ChartSetup/utils/defaults';
import { getDisableReason, extractEntry } from './ChartSetup/utils/constants';
import NoChartReason from './NoChartReason';
import { getMeasurementUnit } from '../../components/MeasurementsPicker';
import DownloadChart from '../../components/DownloadChart';

const OverflowDataCount = 15;

// Ujednolica zaczytywane dane dodając prefix id
// oraz wyrównując xKey do neutralnego
const updateKeys = (obj, key, xKey) => {
  const nKeys = Object.keys(obj).reduce(
    (prev, k) => ({
      ...prev,
      [k]: `${key}-${k}`
    }),
    {}
  );
  return Object.keys(nKeys).reduce(
    (prev, k) => ({
      ...prev,
      [nKeys[k]]: obj[k]
    }),
    {
      [xKey]: obj[xKey]
    }
  );
};

// Filtruje zmergowane dane po użytych kluczach
const filterLoadedData = (loadedData, usedData, xKey) => {
  const usedKeys = usedData.reduce((prev, { id }) => [...prev, id], [xKey]);
  return filter(
    loadedData.map(value =>
      filter(Object.keys(value), k => usedKeys.includes(k)).reduce(
        (prev, k) => ({
          ...prev,
          [k]: value[k]
        }),
        {}
      )
    ),
    set =>
      !isNil(set[xKey]) &&
      (ks => ks.length > 1 && ks.includes(xKey))(Object.keys(set))
  );
};

// Merguje załadowane dane, przy xKey != czas wykonuje zsumowanie do jednego pola
const mergeData = (loadedData, xKey) => {
  const merged = loadedData.reduce(
    (array, { id, data }) => [
      ...array,
      ...data.map(d => updateKeys(d, id, xKey))
    ],
    []
  );
  const sorted = sortBy(merged, xKey);
  if (xKey !== 'time') {
    return sorted;
  }
  const final = [];
  let value = null;
  for (let i = 0; i < sorted.length; i += 1) {
    if (sorted[i][xKey] === value) {
      const last = final.pop();
      final.push({ ...last, ...sorted[i] });
    } else {
      final.push(sorted[i]);
    }
    value = sorted[i][xKey];
  }
  return final;
};

// TODO element funkcyjny
class ChartCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedData: [],
      usedData: [],
      chartConfig: Object.assign({}, defaultChartConfig),
      now: moment(),
      acceptOverflow: false,
    };
  }

  update = newValue => {
    this.setState({
      ...newValue,
    });
  };

  mapSetupToChart = ({ loadedData, usedData, chartConfig }) => {
    const xAxisMapper = {
      common: ['color', 'description', 'showDescription', 'xKey'],
      time: ['datesNumber', 'withTime'],
      other: ['rangeFrom', 'rangeTo', 'rangeSpan', 'unit']
    };
    const type = chartConfig.xAxis.xKey === 'time' ? 'time' : 'other';
    const _chartConfig = {
      settings: {
        ...chartConfig.settings
      },
      xAxis: filter(Object.keys(chartConfig.xAxis), e =>
        [...xAxisMapper.common, ...xAxisMapper[type]].includes(e)
      ).reduce((prev, key) => ({ ...prev, [key]: chartConfig.xAxis[key] }), {}),
      yAxises: chartConfig.yAxises.map(({ id, fields }) => ({
        ...fields,
        id
      }))
    };
    const _usedData = filter(usedData, ({ show, dataSource }) => {
      if (!show) return false;
      return !getDisableReason(dataSource, loadedData, _chartConfig.xAxis.xKey);
    }).map(({ dataSource, fields }) => ({
      id: dataSource,
      dataSource,
      unit: getMeasurementUnit(extractEntry(dataSource)[1]),
      ...fields
    }));
    const _data = mergeData(loadedData, _chartConfig.xAxis.xKey);
    return {
      chartConfig: _chartConfig,
      usedData: _usedData,
      data: filterLoadedData(_data, _usedData, _chartConfig.xAxis.xKey)
    };
  };

  render() {
    const mapSetupResult = this.mapSetupToChart(this.state);
    const isOverflow = mapSetupResult.usedData.length >= OverflowDataCount && !this.state.acceptOverflow;
    return (
      <StyledChartCreate>
        <SetupMenu>
          <ChartSetup update={this.update} {...this.state} />
        </SetupMenu>
        <ChartPresentation>
          <ChartContainer>
            <ChartPlacement>
              {(mapSetupResult.usedData.length > 0 &&
              mapSetupResult.data.length > 0 && 
              !isOverflow)? (
                <LineChart {...mapSetupResult} />
              ) : (
                <NoChartReason
                  loadedData={this.state.loadedData}
                  usedData={this.state.usedData}
                  config={this.state.chartConfig}
                  isOverflow={isOverflow}
                  accept={() => this.update({acceptOverflow: true})}
                />
              )}
            </ChartPlacement>
          </ChartContainer>
          <ChartDowloadContainer>
            <DownloadChart
              usedData={this.state.usedData}
              loadedData={this.state.loadedData}
              disabled={!(mapSetupResult.usedData.length > 0 && mapSetupResult.data.length > 0)}
            />
          </ChartDowloadContainer>
        </ChartPresentation>
      </StyledChartCreate>
    );
  }
}

export default ChartCreate;

const SetupMenu = styled.div`
  height: calc(100vh - 56px);
  min-height: 664px;
  overflow-y: auto;
  flex: 0 0 464px;
  padding: 16px;
  padding-right: 0;
  @media (max-width: 1600px) {
    flex: 0 0 424px;
  }
  @media (max-width: 1440px) {
    flex: 0 0 384px;
  }
`;

const StyledChartCreate = styled.div`
  display: flex;
`;

const ChartPresentation = styled.div`
  padding: 16px;
  flex: 1;
`;

const ChartContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #e8e8e8;
  background: #F8F8F8;
  width: 100%;
  padding-top: 56.25%;
  position: relative;
`;

const ChartPlacement = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 16px;
`;

const ChartDowloadContainer = styled.div`
  margin-top: 16px;
  text-align: center;
`;
