import React, { useContext } from 'react';
import { Modal } from 'antd';

import { AdministrationContext } from '../AdministrationDataProvider';
import UserForm from './Users/UserForm';
import BuildingForm from './Buildings/BuildingForm';

const CommonModal = () => {
  const {
    // Buildings
    createBuilding,
    updateBuilding,
    deleteBuilding,
    // Users
    createUser,
    updateUser,
    deleteUser,
    changePassword,
    // Rest
    currentEntry,
    setMode,
    setKind,
    currentConfig,
    setIsModalVisible,
    isModalVisible,
  } = useContext(AdministrationContext);

  const closeModal = () => {
    setIsModalVisible(false);
    setMode(undefined);
    setKind(undefined);
  };

  const userModalConfigs = {
    createUsers: {
      header: 'Nowy Użytkownik',
      component: () => (
        <UserForm
          editable
          actionLabel="Stwórz"
          onAction={createUser}
          onCancel={closeModal}
        />
      ),
    },
    readUsers: {
      header: 'Szczegóły Użytkownika',
      component: () => (
        <UserForm
          actionLabel="Ok"
          initialState={currentEntry}
          onAction={closeModal}
          onCancel={closeModal}
        />
      ),
    },
    updateUsers: {
      header: 'Edytuj Użytkownika',
      component: () => (
        <UserForm
          editable
          actionLabel="Zapisz"
          initialState={currentEntry}
          onAction={updateUser}
          onCancel={closeModal}
        />
      ),
      actionLabel: 'Zapisz',
    },
    deleteUsers: {
      header: 'Usuń użytkownika',
      component: () => (
        <UserForm
          removeMode
          actionLabel="Usuń"
          initialState={currentEntry}
          onAction={deleteUser}
          onCancel={closeModal}
        />
      ),
    },
    changePasswordUsers: {
      header: 'Zmień hasło',
      component: () => (
        <UserForm
          actionLabel="Zmień"
          initialState={currentEntry}
          onAction={changePassword}
          onCancel={closeModal}
        />
      ),
    },
  };

  const buildingModalConfigs = {
    createBuildings: {
      header: 'Nowy Obiekt',
      component: () => (
        <BuildingForm
          editable
          actionLabel="Stwórz"
          onAction={createBuilding}
          onCancel={closeModal}
        />
      ),
    },
    readBuildings: {
      header: 'Szczegóły Obiektu',
      component: () => (
        <BuildingForm
          actionLabel="Ok"
          initialState={currentEntry}
          onAction={closeModal}
          onCancel={closeModal}
        />
      ),
    },
    updateBuildings: {
      header: 'Edytuj Obiekt',
      component: () => (
        <BuildingForm
          editable
          actionLabel="Zapisz"
          initialState={currentEntry}
          onAction={updateBuilding}
          onCancel={closeModal}
        />
      ),
      actionLabel: 'Zapisz',
    },
    deleteBuildings: {
      header: 'Usuń Obiket',
      component: () => (
        <BuildingForm
          removeMode
          actionLabel="Usuń"
          initialState={currentEntry}
          onAction={deleteBuilding}
          onCancel={closeModal}
        />
      ),
    },
  };
  const modalConfigs = {
    ...userModalConfigs,
    ...buildingModalConfigs,
  };

  const currentModalConfig = modalConfigs[currentConfig];
  const ModalComponent = currentModalConfig
    ? currentModalConfig.component
    : null;

  return currentModalConfig ? (
    <Modal
      title={currentModalConfig.header}
      visible={isModalVisible}
      footer={null}
      onCancel={closeModal}
      cancelText="Anuluj"
      style={{ maxHeight: '60vh', minWidth: 600 }}
    >
      <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <ModalComponent />
      </div>
    </Modal>
  ) : null;
};

export default CommonModal;
