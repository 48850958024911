import React from 'react';
import { List, Button } from 'antd';

const CrudList = ({
  items,
  addText,
  onRead,
  onUpdate,
  onDelete,
  onCreate,
  customAction,
}) => {
  return (
    <div>
      <List
        itemLayout="horizontal"
        locale={{ emptyText: 'Brak Danych' }}
        dataSource={items}
        renderItem={item => (
          <List.Item
            key={item.id}
            actions={[
              <span onClick={() => onRead(item.id)}>szczegóły</span>,
              <span onClick={() => onUpdate(item.id)}>edytuj</span>,
              ...(onDelete ? [(<span onClick={() => onDelete(item.id)}>usuń</span>)] : []),
              ...(customAction ? [(
                <span onClick={() => customAction.onClick(item.id)}>
                  {customAction.label}
                </span>
              )] : []),
            ]}
          >
            <List.Item.Meta title={item.name} description={item.description} />
          </List.Item>
        )}
      />
      {addText && (
        <Button
          type="primary"
          icon="plus"
          onClick={onCreate}
          style={{ float: 'right' }}
        >
          {addText}
        </Button>
      )}
    </div>
  );
};

export default CrudList;
