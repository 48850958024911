import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Icon, Button } from 'antd';
import { withRouter } from 'react-router';

import { AlarmsRoute } from '../Routes';

const AlarmWarning = ({ history }) => {
  const [wasClicked, setWasClicked] = useState(false);
  const navigateToAlarms = () => {
    setWasClicked(true);
    history.push(AlarmsRoute);
  };

  useEffect(() => {
    if (history.location.pathname === AlarmsRoute) {
      setWasClicked(true);
    }
  }, [history.location.pathname]);

  return (
    <Container wasClicked={wasClicked}>
      <AlarmIcon type="alert" wasClicked={wasClicked} />
      <Button
        type="danger"
        size={wasClicked ? 'default' : 'large'}
        onClick={navigateToAlarms}
      >
        Idź do alarmów
      </Button>
    </Container>
  );
};

const Container = styled.div`
  padding: ${p => (p.wasClicked ? 12 : 46)}px;
  background: #f5222d;
  display: flex;
  align-items: center;
  border-radius: 10px;
  position: absolute;
  z-index: 3;
  top: 0;
  right: 160px;
`;

const AlarmIcon = styled(Icon)`
  color: white !important;
  font-size: ${p => (p.wasClicked ? 30 : 50)}px;
  margin-right: 10px;
`;

export default withRouter(AlarmWarning);
