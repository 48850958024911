import React from "react";
import styled from "styled-components";
import { Button } from "antd";

export const SetupList = styled.div``;

export const SetupAdd = ({description, onClick, icon, ...props}) => (
  <StyledSetupAdd>
    <StyledSetupDescription>
      <Button
        type="primary"
        icon={icon}
        onClick={onClick}
        {...props}
      >
        {description}
      </Button>
    </StyledSetupDescription>
  </StyledSetupAdd>
)

const StyledSetupAdd = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 4px;
  margin-right: 8px;
`;
const StyledSetupDescription = styled.div`
  font-weight: 500;
  flex: 1;
`;

export const ButtonForList = ({onClick, disabled, children, ...props}) =>
  <StyledButtonForList disabled={disabled} onClick={disabled ? undefined : onClick} {...props}>{children}</StyledButtonForList>

const StyledButtonForList = styled.span`
  cursor: ${({disabled}) => disabled ? "default" : "pointer"}
  color: ${({disabled}) => disabled ? "#999" : "#1890ff"}
  user-select: none;
  &:hover {
    text-decoration: ${({disabled}) => disabled ? "none" : "underline"}
  }
`;
