/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Subscripter from './Subscriber';
import sleep from '../sleep';

class DelayedRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            waiting: false,
            renderedProps: props.passed,
        }
        this.subscriber = null;
    }

    subscriber;

    static propTypes = {
        delay: PropTypes.number,
        // eslint-disable-next-line react/forbid-prop-types
        passed: PropTypes.object,
    }

    static defaultProps = {
        delay: 3000,
        passed: {},
    }

    componentDidUpdate() {
        if(!this.subscriber && !this.state.waiting && this.props.passed !== this.state.renderedProps) {
            this.setState({waiting: true});
            this.subscriber = new Subscripter();
            this.subscriber.subscribe(
                async () => {await this.awaitDelay()},
                () => this.updateAsyncProps(),
            )
        }
    }

    async awaitDelay() {
        await sleep(this.props.delay);
    }

    updateAsyncProps() {
        this.subscriber.unsubscribe();
        this.subscriber = null;
        this.setState({
            waiting: false,
            renderedProps: this.props.passed, 
        });
    }

    componentWillUnmount() {
        if(this.subscriber) {
            this.subscriber.unsubscribe();
        }
    }

    render() {
        return React.cloneElement(this.props.children, {...this.state.renderedProps});
    }
}

// Eksperymentalny wrapper do przekazywania propsów do dziecka, redukujący liczbę renderingu
// przy dynamicznym update danych do długo renderowanych elementów
export const withDelayedRender = (Element) => (
    ({delay = 500, ...props}) => (
        <DelayedRenderer delay={delay} passed={props}>
            <Element />
        </DelayedRenderer>
    ));

class DelayedExecution extends Component {
    constructor(props) {
        super(props);
        this.state = {
            waiting: false,
            renderedValue: props.value,
        }
        this.subscriber = null;
    }

    subscriber;

    static propTypes = {
        delay: PropTypes.number,
        onChange: PropTypes.func.isRequired,
    }

    static defaultProps = {
        delay: 3000,
    }

    componentDidUpdate(prevProps) {
        if(!this.subscriber && !this.state.waiting && this.props.value !== this.state.renderedValue) {
            this.setState({
                waiting: true,
                ...(prevProps.value !== this.props.value && {renderedValue: this.props.value}),
            });
            this.subscriber = new Subscripter();
            this.subscriber.subscribe(
                async () => {await this.awaitDelay()},
                () => this.executeDelayedUpdate(false),
                () => this.executeDelayedUpdate(true),
            );
        }
    }

    async awaitDelay() {
        await sleep(this.props.delay);
    }

    executeDelayedUpdate(canceled) {
        this.subscriber.unsubscribe();
        this.subscriber = null;
        this.props.onChange(this.state.renderedValue);
        if(!canceled) {
            this.setState({
                waiting: false,
            });
        }
    }

    componentWillUnmount() {
        if(this.subscriber) {
            this.subscriber.unsubscribe();
        }
    }

    selfUpdate(value) {
        this.setState({
            renderedValue: value,
        })
    }

    wrappedOnChange(v) {
        let value = v;
        if(v.target){
            if(v.target.type === "checkbox") {
                value = v.target.checked;
            }
            if(v.target.type === "text") {
                value = v.target.value;
            }
        }
        this.selfUpdate(value);
    }

    render() {
        return React.cloneElement(this.props.children, {
            onChange: (v) => this.wrappedOnChange(v),
            value: this.state.renderedValue,
        });
    }
}

// Eksperymentalny wrapper do zapobiegania wielokrotnych updatom owrapowanego
// componentu - jak opóźniony input do tekstu
export const withDelayedUpdate = (Element) => (
({delay = 400, value, onChange, ...props}) => (
    <DelayedExecution delay={delay} value={value} onChange={onChange}>
        <Element {...props} />
    </DelayedExecution>));
