import energyUsageAreas, {
    mapUsageAreaColor,
    mapUsageAreaDescription,
    mapUsageAreaShortcut,
} from './energyUsageAreas';

export default {
    energyUsageAreas,
    mapUsageAreaColor,
    mapUsageAreaDescription,
    mapUsageAreaShortcut,
}
