import React from 'react';
import styled from 'styled-components';
import AppBar from './AppBar';

import Nav from './Nav';
import { minAppWidth, minAppHeight, navWidth, topBarHeight, scrollWidth } from '../constants';

const Layout = ({ children }) => {
  return (
    <Main>
      <AppBar />
      <AppContent>
        <Nav />
        <View>{children}</View>
      </AppContent>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

const View = styled.div`
  flex: 1;
  width: 0;
  position: relative;
  height: 100%;
  min-height: ${minAppHeight - topBarHeight}px;
  min-width: ${minAppWidth - scrollWidth - navWidth}px;
`;

export default Layout;
