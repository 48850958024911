import React from 'react';
import styled from 'styled-components';

const BottomContainer = styled.div`
    max-width: 959px;
    height: 74px;
    position: relative;
    border-top: solid 1px #333;
`;

const Bottom = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    color: black;
    text-align: right;
    height: 64px;
    div {
        height: 15px;
        line-height: 15px;
        font-size: 12px;
    }
    div:first-child {
        font-weight: bold;
        color: darkblue;
    }
`;

export default () => (
    <BottomContainer>
        <Bottom>
            <div>KRAJOWA AGENCJA POSZANOWANIA ENERGII SA</div>
            <div>Al. Jerozolimskie 65/79, 00-697 Warszawa</div>
            <div>tel.: 22 626-09-10, 696-89-55, fax: 22 626-09-11</div>
            <div>e-mail: kape@kape.gov.pl, www.kape.gov.pl</div>
        </Bottom>
    </BottomContainer>);
