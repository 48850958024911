import React from 'react';
import styled from 'styled-components';

export default ({buildings, buildingColorMapper, ...props}) => (
    <BuildingsLegend {...props}>
        <Legend>Legenda</Legend>
        {buildings.map((e, id) =>(
            <BuildingColor key={e.buildingId}>
                <Color color={buildingColorMapper(e, id)} />
                <span>{e.fullName}</span>
            </BuildingColor>))}
    </BuildingsLegend>
);

const BuildingsLegend = styled.div`
    color: black;
    text-align: left;
`;

const BuildingColor = styled.div`
    font-size: 16px;
    & + & {
        padding-top: 4px;
    }
`;

const Legend = styled.div`
    font-size: 20px;
    padding: 0 0 4px 20px;
    font-size: bold;
`;

const Color = styled.div`
  background: ${({color}) => color};
  display: inline-block;
  vertical-align: text-bottom;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin-right: 4px;
`;
