import React from "react";
import {
  CartesianGrid,
  Label,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  ScatterChart,
  Tooltip,
  XAxis,
} from "recharts";
import PropTypes from "prop-types";

import {
  chartRangesFromConfig,
  dateTicks,
} from "./utils/calculate";
import {
  dataFromXAxis,
  dataFromAxis,
  hasLeftAxis,
  hasRightAxis,
  isTimeChart,
} from "./utils/filters";
import {
  chartConfigType,
  usedDataType,
} from "./utils/chartTypes";
import CustomXAxis, { CustomTimeAxis } from "./CustomXAxis";
import CustomYAxis from "./CustomYAxis";
import CustomTooltip from "./CustomTooltip";
import CustomLegend from "./CustomLegend";
import { StyledChart, Container, ChartTitle } from './Common';
import MathParser from "../MathParser";

export default Object.assign(
  ({ usedData, data, chartConfig, ...props }) => {
    const { xAxis, settings, yAxises } = chartConfig;
    const {
      showTitle,
      title,
      stopInteractive,
      horizontalGrid,
      verticalGrid,
      showLegend,
      xFormatter,
      yFormatter,
    } = settings;
    const xAxisRanges = xAxis.useDefault
    ? {
      domainMin: 'dataMin',
      domainMax: 'dataMax',
    }
    : (axisFun =>
      axisFun(dataFromXAxis(data, xAxis.xKey), xAxis))(
        isTimeChart(xAxis.xKey) ? dateTicks : chartRangesFromConfig,
      );
    const xAxisChoice = () => {
      if(xAxis.useDefault) return ({...prop}) => <XAxis {...prop} />;
        return isTimeChart(xAxis.xKey) ? CustomTimeAxis : CustomXAxis
      }
    
    return (
      <StyledChart {...props}>
        {showTitle && (
          <ChartTitle><MathParser>{title}</MathParser></ChartTitle>
        )}
        <Container showTitle={showTitle}>
          <ResponsiveContainer width="100%" height="100%">
            <ScatterChart>
              {!stopInteractive && (
                <Tooltip
                  content={
                    <CustomTooltip
                      withTime={xAxis.withTime}
                      xAxis={xAxis}
                      xFormatter={xFormatter}
                      yFormatter={yFormatter}
                    />
                  }
                />
              )}
              {showLegend && (
                CustomLegend()
              )}
              <CartesianGrid
                strokeDasharray="5 5"
                horizontal={horizontalGrid}
                vertical={!xAxisRanges.ticks ? verticalGrid : false}
              />
              {verticalGrid &&
                xAxisRanges.ticks &&
                xAxisRanges.ticks.map((x) => (
                  <ReferenceLine
                    key={`cartesian-x-${x}`}
                    x={x}
                    yAxisId={yAxises[0].id}
                    strokeDasharray="5 5"
                  />
                ))}
              {usedData.map(({ id, dataSource, descriptionId, showDescription, radius, ...used }) =>
                data.map((d, idx) => d[dataSource] ?
                  CustomDot({
                      ...used,
                      x: d[xAxis.xKey],
                      y: d[dataSource],
                      r: radius,
                      showDescription,
                      description: d[descriptionId],
                      key: `usedData-${id}-${idx}`,
                    })
                : null))}
              {(axisComp =>
                axisComp({
                  ...xAxis,
                  dataKey: xAxis.xKey,
                  hasLeft: hasLeftAxis(yAxises),
                  hasRight: hasRightAxis(yAxises),
                  ranges: xAxisRanges,
                  ...(xFormatter && {tickFormatter: xFormatter}),
                }))(xAxisChoice())
              }
              {// WONTFIX recharts ma problem z przeciazaniem komponentow
                yAxises.map(yAxis =>
                  CustomYAxis({
                    ...yAxis,
                    key: `yAxis-${yAxis.id}`,
                    dataSpread: dataFromAxis(data, usedData, yAxis.id, yAxis.maxMin, yAxis.minMax),
                    ...(yFormatter && {tickFormatter: yFormatter}),
                  }),
                )}
            </ScatterChart>
          </ResponsiveContainer>
        </Container>
      </StyledChart>
    );
  },
  {
    propTypes: {
      chartConfig: PropTypes.shape(chartConfigType).isRequired,
      usedData: PropTypes.arrayOf(PropTypes.shape(usedDataType)).isRequired,
      data: PropTypes.arrayOf(PropTypes.any),
    },
  });

const CustomDot = ({
  x,
  y,
  r,
  description,
  descriptionPosition = "bottom",
  showDescription,
  axis,
  lineColor,
  lineWidth,
  ...props
}) => (
    <ReferenceDot
      x={x}
      y={y}
      r={r}
      stroke={lineColor}
      strokeWidth={lineWidth}
      yAxisId={axis}
      {...props}
    >
        {showDescription ? (<Label position={descriptionPosition}>{description}</Label>) : null}
      </ReferenceDot>
  );
