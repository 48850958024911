import React, { Fragment } from "react";
import styled from 'styled-components';
import { filter, sortBy } from "lodash";
import MathParser from "../MathParser";
import { isTimeChart } from "./utils/filters";
import {formatDate, formatHour, formatNumber} from "../../utils/formaters";

export default ({
    active,
    payload,
    label,
    xAxis,
    withTime,
    forceDateTooltip,
    xFormatter,
    yFormatter,
  }) => {
    const { xKey, description, unit } = xAxis;
    const simpleFormatter = (value) => value;
    const defaultTimeFormatter = (value) => `${formatDate(value)}${withTime ? ` ${formatHour(value)}` : ""}`
    const mainFormatter = xFormatter || (isTimeChart(xKey) ? defaultTimeFormatter : simpleFormatter);
    const dataFormatter = yFormatter || (value => formatNumber(value, {position: -2, addSpace: true}));
    const sorted = sortBy(filter(payload, e => !e.dataKey.startsWith("REG_")), [(e) => (-e.payload[e.dataKey])]);
    const forcedDate = forceDateTooltip && payload && payload[0] && payload[0].payload && payload[0].payload.time;
    if (active) {
      return (
        <StyledTooltip>
          {forcedDate && (
            <MainLabel>
              {defaultTimeFormatter(forcedDate)}
            </MainLabel>
          )}
          {!forcedDate && (isTimeChart(xKey) ? (
            <MainLabel>
              {mainFormatter(label)}
            </MainLabel>
          ) :
          (
            <MainLabel>
                <MathParser>{`${description} ${mainFormatter(label)} ${unit || ""}`}</MathParser>
            </MainLabel>
          ))}
          <Table>
            {forcedDate && (
              <Property key="mainMoved" color="#333">
                <Feature>
                  <MathParser>{description}</MathParser>
                </Feature>
                <FeatureValue>
                  <MathParser>{`${mainFormatter(label)} ${unit || ""}`}</MathParser>
                </FeatureValue>
              </Property>
            )}
            {sorted.map((e) => (
              <Property key={e.dataKey} color={e.color}>
                <Feature>
                  <MathParser>{e.name}</MathParser>
                </Feature>
                <FeatureValue>
                  <MathParser>{`${dataFormatter(e.payload[e.dataKey])} ${e.unit}`}</MathParser>
                </FeatureValue>
              </Property>
            ))}
          </Table>
        </StyledTooltip>
      );
    }
    return null;
  };
  
export const PieTooltip = ({
  active,
  payload,
  dataFormatter = (e) => e,
}) => {
  if (active) {
    return (
      <StyledTooltip>
        {payload.map(e => (
          <Fragment key={e.dataKey}>
            <MainLabel>
                <MathParser>{e.payload.description}</MathParser>
            </MainLabel>
            <Table>
                <Property>
                  <FeatureValue>
                    <MathParser>{`${dataFormatter(e.value)} ${e.payload.unit}`}</MathParser>
                  </FeatureValue>
                </Property>
            </Table>
        </Fragment>
      ))}
      </StyledTooltip>
    );
  }
  return null;
};

  const StyledTooltip = styled.div`
    background: white;
    border: solid 1px #d4d4d4;
    border-radius: 4px;
    padding: 8px 8px 16px 8px;
    min-width: 120px;
    display: table;
    box-shadow: 0 0 8px rgba(40, 40, 40, 0.3);
    font-size: 14px;
    @media (max-width: 1600px) {
        font-size: 13px;
    }
    @media (max-width: 1440px) {
        font-size: 11px;
        padding: 4px 4px 8px 4px;
    }
    @media (max-width: 1366px) {
      font-size: 10px;
    }
  `;

  const Table = styled.div`
    display: table;
  `;
  
  const MainLabel = styled.div`
    font-weight: bold;
  `;
  
  const Property = styled.div`
    display: table-row;
    ${({color}) => color ? `color: ${color}` : ''};
  `;
  
  const Feature = styled.div`
    display: table-cell;
    padding-right: 8px;
  `;
  
  const FeatureValue = styled.div`
    display: table-cell;
    text-align: right;
  `;