import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { filter } from 'lodash';
import { Transfer, Empty } from 'antd';

import { AnalysisContext } from '../AnalysisDataProvider';

import MathParser from '../../../components/MathParser';
import MultiCheckboxGroup from '../../../components/MultiCheckboxGroup';

import { removePolish } from '../../../utils/formaters';

const measurementsConfig = require('./measurementsConfig.json');

export const getMultipleLabelFromLoaded = (key, loadedData) => {
    const fields = loadedData.find(e => e.fields.measurements.includes(key)).fields;
    const index = fields.measurements.findIndex(e => e === key);
    return `${fields.description} - ${fields.measurementsLabels[index]}`;
}

export const getMeasurementLabel = (key, measurements) => {
    const prefixes = key.split('_');
    if (prefixes.length > 1) {
        const multiLabel = measurementsConfig.multipleMeasurements.find(e => e.key === prefixes[0]).label;
        const customLabel = measurements.multipleMeasurements
            .find(e => e.type === prefixes[0]).measurements.find(e => e.id.toString() === prefixes[1]).description;
        return `${multiLabel} - ${customLabel}`;
    }
    return measurementsConfig.baseMeasurements.find(e => e.key === prefixes[0]).label;
}
    
export const getMeasurementUnit = (key) => {
    const prefixes = key.split('_');
    return (prefixes.length > 1)
        ? measurementsConfig.multipleMeasurements.find(e => e.key === prefixes[0]).unit
        : measurementsConfig.baseMeasurements.find(e => e.key === prefixes[0]).unit;
}

const filterOption = (inputValue, option) =>
    removePolish(option.label.toLowerCase()).includes(removePolish(inputValue.toLowerCase()));

export const MeasurementsPickerTransfer = ({onChange, building, value, disabledValues = [], ...props}) => {
    const { buildingsMeasurements } = useContext(AnalysisContext);
    const measurements = building && buildingsMeasurements[building];
    const dataSource = measurements && [
        ...(filter(measurementsConfig.baseMeasurements,
                ({key}) => measurements.baseMeasurements.includes(key))),
        ...mapMultiple(measurements.multipleMeasurements, measurementsConfig.multipleMeasurements)
    ];

    const [selectedKeys, setSelectedKeys] = useState();

    useEffect(() => {
        setSelectedKeys([]);
    }, [building]);

    const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };
    
    return measurements ?
    (<MeasurementsPicker>
        <Header>
            <HeaderItem>Potencjalne dane</HeaderItem>
            <HeaderItem>Wybrane dane</HeaderItem>
        </Header>
        <StyledTransfer
            dataSource={dataSource.map((e) => ({
                ...e,
                disabled: disabledValues.includes(e.key),
            }))}
            showSearch
            filterOption={filterOption}
            onChange={onChange}
            onSelectChange={handleSelectChange}
            render={item => <MathParser>{item.label}</MathParser>}
            {...props}
            targetKeys={value}
            selectedKeys={selectedKeys}
        />
    </MeasurementsPicker>)
    :
    (<Empty
        description={
            <span>
                {building
                ? "Pobieranie danych o budynku..."
                : "Wybierz budynek by zobaczyć oprzyrządowane dane"}
            </span>
        }
    />);
}

const mapMultiple = (possible, config) => {
    const result = [];
    possible.filter(({ measurements}) => measurements && measurements.length > 0)
        .forEach(({type, measurements}) => {
            const passed = config.find(({key}) => key === type);
            if(passed) {
                measurements.forEach(({description, id}) => {
                    result.push({
                        key: `${passed.key}_${id}`,
                        label: `${passed.label} - ${description}`,
                        unit: passed.unit,
                    });
                });
            }
        });
    return result;
}

export default ({onChange, building, value, disabledValues = [], ...props}) => {
    const { buildingsMeasurements } = useContext(AnalysisContext);
    const measurements = building && buildingsMeasurements[building];
    const dataSource = measurements && [
        ...(filter(measurementsConfig.baseMeasurements,
                ({key}) => measurements.baseMeasurements.includes(key))),
        ...mapMultiple(measurements.multipleMeasurements, measurementsConfig.multipleMeasurements)
    ];

    return measurements ?
    (<MultiCheckboxGroup
        options={dataSource.map((e) => ({
            ...e,
            disabled: disabledValues.includes(e.key),
        }))}
        onChange={onChange}
        allowAll
        allowSearch
        filterOption={filterOption}
        renderItem={item => <MathParser>{item.label}</MathParser>}
        value={value}
        {...props}
    />)
    :
    (<Empty
        description={
            <span>
                {building
                ? "Pobieranie danych o budynku..."
                : "Wybierz budynek by zobaczyć oprzyrządowane dane"}
            </span>
        }
    />);
}

const MeasurementsPicker = styled.div``;

const HeaderItem = styled.div`
    flex: 1;
    text-align: center;
`;

const Header = styled.div`
    display: flex;
    & ${HeaderItem}:first-child {
        margin-right: 24px;
    }
    & ${HeaderItem}:last-child {
        margin-left: 24px;
    }
    margin-bottom: 8px;
    font-size: 0.9rem;
`;

const StyledTransfer = styled(Transfer)`
    display: flex;
    align-items: center;
    & .ant-transfer-list {
        width: unset;
        flex: 1 0 180px;
    }
`;
