import axios from 'axios';
import { preventNaN } from '../scenes/MyBuildings/chartConfig';

export const getMyBuildingsBenchmark = async buildings => {
    const resp = await axios.get('/Benchmarking/usages');
    return {
      ...resp,
      data: parseBackendChartData(resp.data, buildings),
    }
};

const parseBackendChartData = (backendData, buildings) => ({
  avgElectricityUsage: preventNaN(backendData.avgElectricityUsage),
  avgEnergyUsage: preventNaN(backendData.avgEnergyUsage),
  avgHeatUsage: preventNaN(backendData.avgHeatUsage),
  avgWaterUsage: preventNaN(backendData.avgWaterUsage),
  electricityUsagesPerSqMeter: getBuildingsNames(
    backendData.electricityUsagesPerSqMeter,
    buildings,
  ),
  heatUsagesPerSqMeter: getBuildingsNames(
    backendData.heatUsagesPerSqMeter,
    buildings,
  ),
  energyUsagesPerSqMeter: getBuildingsNames(
    backendData.energyUsagesPerSqMeter,
    buildings,
  ),
  waterUsagesPerSqMeter: getBuildingsNames(
    backendData.waterUsagesPerSqMeter,
    buildings,
  ),
});

const getBuildingsNames = (usages, buildings) => {
  return usages.map(usage => {
    const building = buildings.find(b => b.id === usage.buildingId);
    return {
      name: building ? building.shortName : '',
      usage: preventNaN(usage.usage),
    };
  });
};


export const getDatabaseBenchmark = async ({
  buildingId,
  compareOnlyWithinSameCategory,
}) => {
    const resp = await axios.put('/Benchmarking/comparison', {buildingId, compareOnlyWithinSameCategory});
    return {
      ...resp,
      data: parseBackendGaugesData(resp.data),
    }
};

const parseBackendGaugesData = backendData => [
  {
    title: 'Jednostkowe zużycie ciepła [kWh/m^2]',
    value: preventNaN(backendData.chosenBuildingHeatUsagePerSqMeter),
    avg: preventNaN(backendData.avgHeatUsage),
  },
  {
    title: 'Jednostkowe zużycie energii elektrycznej [kWh/m^2]',
    value: preventNaN(backendData.chosenBuildingElectricityUsagePerSqMeter),
    avg: preventNaN(backendData.avgElectricityUsage),
  },
  {
    title: 'Jednostkowe zużycie energii [kWh/m^2]',
    value: preventNaN(backendData.chosenBuildingEnergyUsagePerSqMeter),
    avg: preventNaN(backendData.avgEnergyUsage),
  },
  {
    title: 'Jednostkowe zużycie wody [m^3/m^2]',
    value: preventNaN(backendData.chosenBuildingWaterUsagePerSqMeter),
    avg: preventNaN(backendData.avgWaterUsage),
  },
];
