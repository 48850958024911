import React, { useContext, useEffect } from 'react';
import { Tabs } from 'antd';

import { DbContext } from './DbDataProvider';

import BuildingsList from './scenes/Buildings/BuildingsList';
import HookUpsList from './scenes/HookUps/HookUpsList';
import InvoicesList from './scenes/Invoices/InvoicesList';
import DataExport from './scenes/DataExport/DataExport';
import CommonModal from './scenes/CommonModal';
import { AdministrationContext } from '../Administration/AdministrationDataProvider';

const Db = () => {
  const { fetchBuildings } = useContext(AdministrationContext);
  const { fetchHookUps, fetchInvoices } = useContext(DbContext);

  useEffect(() => {
    fetchBuildings();
  }, []);
  useEffect(() => {
    fetchHookUps();
  }, []);
  useEffect(() => {
    fetchInvoices();
  }, []);

  return (
    <div style={{ padding: '20px', height: '100%', background: '#EEF1F1' }}>
      <Tabs defaultActiveKey="buildings">
        <Tabs.TabPane tab="Budynki" key="buildings">
          <BuildingsList />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Przyłącza" key="hookUps">
          <HookUpsList />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Faktury" key="invoices">
          <InvoicesList />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Eksport Danych" key="dataExport">
          <DataExport />
        </Tabs.TabPane>
      </Tabs>
      <CommonModal />
    </div>
  );
};

export default Db;
