export const getHookUpDetails = (hookUpId, hookUps, buildings) =>
  (h =>
    h ? ` - ${h.name} - ${getBuildingName(h.buildingId, buildings)}` : '')(
    hookUps.find(e => e.id === hookUpId),
  );

export const getHookUpUnit = (hookUpId, hookUps) => {
  const hookUp = hookUps.find(h => h.id === hookUpId);
  if (!hookUp) {
    return '';
  }

  switch (hookUp.type) {
    case 1:
    case 2:
      return 'kWh';
    case 3:
      return 'GJ';
    case 4:
      return 'm^3';
    default:
      return '';
  }
};

const getBuildingName = (id, buildings) =>
  (e => (e ? e.fullName : ''))(buildings.find(e => e.id === id));
