export const labelTypes = [
  {
    id: 'carbDioxMeters',
    name: 'Dwutlenek węgla',
  },
  {
    id: 'elecAirConMeters',
    name: 'Klimatyzacja',
  },
  {
    id: 'elecHeatPumpMeters',
    name: 'Pompa ciepła',
  },
  {
    id: 'elecSwitchRoomMeters',
    name: 'Włącznik',
  },
  {
    id: 'elecVentilationMeters',
    name: 'Wentylacja elek.',
  },
  {
    id: 'heatmeterVentilationMeters',
    name: 'Wentylacja ciep.',
  },
  {
    id: 'insideLightingMeters',
    name: 'Oświetlenie wew.',
  },
  {
    id: 'temperatureMeters',
    name: 'Temperatura',
  },
];

export const labelTypesIds = labelTypes.map(label => label.id);
