import React, { useContext } from 'react';
import { Table, Button } from 'antd';
import { format } from 'date-fns';

import { AppContext } from '../../../AppDataProvider';

const formatDate = date => format(date, 'HH:mm DD/MM/YYYY ');

const historyColumns = [
  {
    title: 'Data wyłączenia',
    dataIndex: 'deactivationTime',
    render: date => formatDate(date),
  },
];

const AlarmsTable = ({ alarms, deactivateAlarm, isAdmin, isHistory }) => {
  const { buildings, users } = useContext(AppContext);

  const baseColumns = [
    {
      title: 'Budynek',
      dataIndex: 'buildingFullName',
      filters: buildings.map(building => ({
        text: building.fullName,
        value: building.fullName,
      })),
      onFilter: (value, record) => record.buildingFullName === value,
    },
    {
      title: 'Data, godzina',
      dataIndex: 'activationTime',
      defaultSortOrder: 'descend',
      sorter: (a, b, sortOrder) =>
        new Date(a.activationTime).getTime() -
        new Date(b.activationTime).getTime(),
      sortDirections: ['descend', 'ascend'],
      render: date => formatDate(date),
    },
    {
      title: 'Rodzaj alarmu',
      dataIndex: 'alarmType',
    },
    {
      title: 'Wskazanie',
      dataIndex: 'reading',
    },
    {
      title: 'Próg',
      dataIndex: 'threshold',
    },
  ];

  const columnsWithUsers = [
    {
      title: 'Użytkownik',
      dataIndex: 'userName',
      filters: users.map(user => ({
        text: user.name,
        value: user.name,
      })),
      onFilter: (value, record) => record.userName === value,
    },
    ...baseColumns,
  ];

  const activeAlarmsColumns = [
    {
      title: 'Akcja',
      dataIndex: 'id',
      render: id => (
        <Button type="danger" onClick={() => deactivateAlarm(id)}>
          Akceptuj
        </Button>
      ),
    },
  ];

  const initial = isAdmin ? columnsWithUsers : baseColumns;
  const columns = isHistory
    ? [...initial, ...historyColumns]
    : [...initial, ...activeAlarmsColumns];
  return (
    <Table
      columns={columns}
      dataSource={alarms}
      pagination={{ pageSize: 10 }}
      bodyStyle={{ background: 'white' }}
    />
  );
};

export default AlarmsTable;
