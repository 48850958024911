import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  Input,
  Button,
} from "antd";

import { UsedDataType, DataType, ConfigType, EditUsedDataType } from "../utils/types";
import { MODES } from "../utils/constants";

import FormItemWrapper, { validate } from "../../../../../../components/FormItemWrapper";
import ColorSelect from "../../../../../../components/ColorSelect";
import VisibilitySwitch from "../../../../../../components/VisibilitySwitch";
import AxisSelect, { handleAxisData } from "../../../../components/AxisSelect";
import { FormFooter, FormInner, StyledFrom } from "../../../../components/StyledForm";
import PathSelect from "../../../../components/PathSelect";
import { withDelayedUpdate } from "../../../../../../utils/delayed";

const DelayedInput = withDelayedUpdate(Input);

const UseDataForm  = Object.assign(
  ({
    dataSource,
    editedData,
    loadedData,
    onClose,
    config,
    updateData,
  }) => {
  const isEdit = true;

  const [triggered, setTriggered] = useState(false);
  const [_value, setValue] = useState(editedData);

  const formSettings = {
    isEdit,
    triggered,
  };
  
  useEffect(() => {
    setValue(editedData);
  }, [editedData]);

  const updateWithValid = ((data, isValid) =>
    isValid ? updateData({...editedData, ...data}): setValue({..._value, ...data}));

  const update = (key) => ((data, isValid) => updateWithValid({[key]: data}, isValid));

  const changeDotted = (dotted, isValid) =>
    updateWithValid({
      dotted,
      ...(dotted && {
        dashed: false,
      }),
    }, isValid);

  const onReset = () => {
    setTriggered(false);
    setValue(editedData);
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const toValidate = [
      'description',
      'axis',
      'lineColor',
    ];
    const isValid = toValidate.map((key) => validate({
      required: true,
      value: _value[key],
      rules: setupSettings[key] ? (setupSettings[key].rules || []) : [],
    })).reduce((prev, {validateStatus}) => prev && validateStatus !== 'error', true);
    if(!isValid) {
      setTriggered(true);
    }
    else {
      onClose();
      onReset();
    }
  }

  const { axisData } = handleAxisData(config);

  return (
    <StyledFrom
      layout="vertical"
      hideRequiredMark={false}
      onSubmit={onSubmit}
    >
      <FormInner>
        <FormItemWrapper
          value={_value.description}
          onChange={update('description')}
          {...formSettings}
          {...setupSettings.description}
        >
            <DelayedInput placeholder="Nazwa zestawu danych" />
        </FormItemWrapper>
        <FormItemWrapper
          label="Źródło danych"
          value={dataSource}
          {...formSettings}
          disableOnEdit
        >
          <PathSelect loadedData={loadedData} />
        </FormItemWrapper>
        <FormItemWrapper
          value={_value.axis}
          onChange={update('axis')}
          {...formSettings}
          {...setupSettings.axis}
        >
            <AxisSelect data={axisData} />
        </FormItemWrapper>
        <FormItemWrapper
          value={_value.lineColor}
          onChange={update('lineColor')}
          {...formSettings}
          {...setupSettings.lineColor}
        >
            <ColorSelect />
        </FormItemWrapper>
        <FormItemWrapper
          value={_value.showRegresion}
          valueKey="checked"
          onChange={update('showRegresion')}
          {...formSettings}
          {...setupSettings.showRegresion}
        >
            <Checkbox>Pokaż linię regresji</Checkbox>
        </FormItemWrapper>
        <FormItemWrapper
          value={_value.dotted}
          valueKey="checked"
          onChange={changeDotted}
          {...formSettings}
          {...setupSettings.dotted}
        >
            <Checkbox>Pokaż jako zbiór punktów</Checkbox>
        </FormItemWrapper>
        <VisibilitySwitch visible={!_value.dotted}>
          <FormItemWrapper
            value={_value.dashed}
            valueKey="checked"
            onChange={update('dashed')}
            {...formSettings}
            {...setupSettings.dashed}
          >
              <Checkbox>Użyj przerywanej linii</Checkbox>
          </FormItemWrapper>
        </VisibilitySwitch>
      </FormInner>
      <FormFooter>
        <Button
          onClick={onReset}
          style={{ marginRight: 16 }}
        >
          Zresetuj
        </Button>
        <Button
          type="primary"
          icon={isEdit ? "redo" : "plus"}
          htmlType="submit"
        >
          {isEdit ? "Aktualizuj" : "Dodaj dane"}
        </Button>
      </FormFooter>
    </StyledFrom>
  );
},
{
  propTypes: {
    dataSource: PropTypes.string.isRequired,
    mode: PropTypes.oneOf([MODES.EDIT, MODES.LOAD]).isRequired,
    editedData: PropTypes.shape(EditUsedDataType).isRequired,
    loadedData: PropTypes.arrayOf(PropTypes.shape(DataType)).isRequired,
    onClose: PropTypes.func.isRequired,
    config: PropTypes.shape(ConfigType),
    updateData: PropTypes.func.isRequired,
  },
});

const setupSettings = {
  description: {
    label: "Opis danych",
    required: true,
    rules: [
      {
        validate: (v) => v.length >= 3,
        errorMessage: 'Wymagane min. 3 znaki',
      },
      {
        validate: (v) => v.length <= 60,
        errorMessage: 'Maksymalnie 60 znaków',
      },
    ],
  },
  axis: {
    label: "Oś odniesienia",
    required: true,
  },
  showRegresion: {},
  dotted: {},
  dashed: {},
  lineColor: {
    label: "Kolor na wykresie",
    required: true,
  },
};

export default Object.assign(
({mode, editedData, loadedData, config, onClose, updateData}) => {

const onUpdateFields = changedFields => {
  if (changedFields !== editedData.fields) {
    updateData({
      ...editedData,
      fields: { ...editedData.fields, ...changedFields },
    });
  }
};
  return (
    <UseDataForm
      mode={mode}
      onClose={onClose}
      updateData={onUpdateFields}
      dataSource={editedData.dataSource}
      editedData={editedData.fields}
      loadedData={loadedData}
      config={config}
    />
  );
},
{
  propTypes: {
    mode: PropTypes.oneOf([MODES.EDIT, MODES.LOAD]).isRequired,
    editedData: PropTypes.shape(UsedDataType).isRequired,
    loadedData: PropTypes.arrayOf(PropTypes.shape(DataType)).isRequired,
    onClose: PropTypes.func.isRequired,
    config: PropTypes.shape(ConfigType),
    updateData: PropTypes.func.isRequired,
  },
});
