import React, { useState, useContext } from 'react';
import { Button } from 'antd';

import TextField from '../../../../components/CRUDs/TextField';
import NumberField from '../../../../components/CRUDs/NumberField';
import SelectField from '../../../../components/CRUDs/SelectField';
import { AppContext } from '../../../../AppDataProvider';
import { DbContext } from '../../DbDataProvider';

const HookUpForm = ({
  editable,
  initialState,
  actionLabel,
  onCancel,
  onAction,
  removeMode = false,
}) => {
  const data = initialState || {
    name: '',
    number: '',
    tariff: '',
    orderedPower: 0,
    type: 1,
    buildingId: 0,
  };

  const [formData, setFormData] = useState(data);

  const { enums } = useContext(AppContext);
  const { buildings } = useContext(DbContext);

  const onChange = (fieldName, e) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
    });
  };

  const onChangeValue = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleAction = () => {
    onAction(formData);
    onCancel();
  };

  const textProps = { editable, onChange, formData };
  return (
    <div>
      {removeMode ? (
        <div>Czy na pewno chcesz usunąć przyłącze ? </div>
      ) : (
        <>
          <TextField {...textProps} name="name" label="Nazwa" />
          <TextField {...textProps} name="number" label="Numer" />
          <TextField {...textProps} name="tariff" label="Taryfa" />
          <NumberField
            editable={editable}
            onChange={onChangeValue}
            formData={formData}
            name="orderedPower"
            label="Moc zamówiona"
          />
          <SelectField
            label="Typ przyłącza"
            placeholder="Wybierz typ przyłącza"
            onChange={v => onChangeValue('type', v)}
            disabled={!editable}
            value={formData.type}
            items={enums.hookUpTypes}
          />
          <SelectField
            label="Budynek przyłącza"
            placeholder="Wybierz budynek"
            onChange={v => onChangeValue('buildingId', v)}
            disabled={!editable}
            value={formData.buildingId}
            items={buildings}
            itemKey="fullName"
          />
        </>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
          padding: '0 30px',
        }}
      >
        <Button onClick={onCancel}>Anuluj</Button>
        <Button type="primary" onClick={handleAction}>
          {actionLabel}
        </Button>
      </div>
    </div>
  );
};

export default HookUpForm;
