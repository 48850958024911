import React from 'react';
import styled from 'styled-components';
import { Button, Icon } from 'antd';


export default ({isSticky, isData, ...props}) => (
    <StyledStickySection isSticky={isSticky} {...props}>
        <ButtonContainer>
        {isData && (<Button.Group>
                {isSticky && (<Button href="#LoadReportData">
                    <Icon type="up" />
                    Do góry
                </Button>)}
                <Button href="#ReportDownload">
                    Do dołu
                    <Icon type="down" />
                </Button>
            </Button.Group>)}
        </ButtonContainer>
    </StyledStickySection>
);

const StyledStickySection = styled.div`
    position: relative;
    padding: 8px 20px 0;
    margin: 0 -20px;
    min-height: 48px;
    ${({isSticky}) => isSticky ? `
        border-bottom: solid 1px #EEF1F1;
        background: white;
    ` : ''}
`;

const ButtonContainer = styled.div`
    position: absolute;
    right: 20px;
    top: 8px;
`;
