import PropTypes from "prop-types";

export const settingsType = {
    title: PropTypes.string.isRequired,
    showTitle: PropTypes.bool.isRequired,
    showLegend: PropTypes.bool.isRequired,
    stopInteractive: PropTypes.bool.isRequired,
    stopAnimation: PropTypes.bool.isRequired,
    horizontalGrid: PropTypes.bool.isRequired,
    verticalGrid: PropTypes.bool.isRequired,
    xFormatter: PropTypes.func,
    yFormatter: PropTypes.func,
};
export const limitType = {
    description: PropTypes.string,
    axis: PropTypes.string.isRequired,
    dashSpacing: PropTypes.number.isRequired,
    dashLength: PropTypes.number.isRequired,
    lineColor: PropTypes.string.isRequired,
    lineWidth: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
export const withDescriptionType = {
    description: PropTypes.string.isRequired,
    showDescription: PropTypes.bool.isRequired,
};
const withIdType = {
    id: PropTypes.string.isRequired,
};
const withXKeyType = {
    xKey: PropTypes.string.isRequired,
};
const defaultAxisType = {
    ...withDescriptionType,
    color: PropTypes.string.isRequired,
    useDefault: PropTypes.oneOf([true]),
}

export const xAxisType = {
    ...withDescriptionType,
    withTime: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
    datesNumber: PropTypes.number.isRequired,
};
export const yAxisType = {
    ...withDescriptionType,
    ...withIdType,
    color: PropTypes.string.isRequired,
    showAxis: PropTypes.bool.isRequired,
    side: PropTypes.oneOf(["left", "right"]),
    unit: PropTypes.string.isRequired,
    rangeFrom: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    rangeTo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    rangeSpan: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
};
export const usedDataType = {
    description: PropTypes.string.isRequired,
    ...withIdType,
    axis: PropTypes.string.isRequired,
    dashed: PropTypes.bool.isRequired,
    dashSpacing: PropTypes.number,
    dashLength: PropTypes.number,
    dataSource: PropTypes.string.isRequired,
    lineColor: PropTypes.string.isRequired,
    lineWidth: PropTypes.number.isRequired,
};
export const chartConfigType = {
    settings: PropTypes.shape(settingsType).isRequired,
    limits: PropTypes.arrayOf(PropTypes.shape(limitType)),
    xAxis: PropTypes.oneOfType([
        PropTypes.shape({
            ...xAxisType,
            ...withXKeyType,
        }),
        PropTypes.shape({
            ...yAxisType,
            ...withXKeyType,
        }),
        PropTypes.shape({
            ...defaultAxisType,
            ...withXKeyType,
        }),
    ]).isRequired,
    yAxises: PropTypes.arrayOf(PropTypes.shape(yAxisType).isRequired).isRequired,
};
