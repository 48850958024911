import { formatNumber } from '../../../../utils/formaters';
import { CHART_TYPES } from '../../../../components/CustomChart';

const getConfig = ({ barColor, unit, dataFieldName, avgValue, title }) => ({
  chartConfig: {
    settings: {
      stopAnimation: false,
      stopInteractive: false,
      title: '',
      showTitle: false,
      showLegend: false,
      horizontalGrid: false,
      verticalGrid: false,
      xFormatter: e => e,
      yFormatter: e => formatNumber(e, { position: -1 }),
    },
    limits: [
      {
        description: '',
        axis: '0',
        lineWidth: 2,
        lineColor: 'black',
        dashLength: 10,
        dashSpacing: 15,
        value: avgValue,
      },
    ],
    xAxis: {
      xKey: 'name',
      description: '',
      showDescription: false,
      color: '#333',
      datesNumber: 3,
      withTime: false,
      useDefault: true,
      minTickGap: 15,
      interval: 'preserveStart',
    },
    yAxises: [
      {
        id: '0',
        color: '#333',
        showAxis: true,
        side: 'left',
        description: '',
        showDescription: false,
        unit: '',
        rangeFrom: '0',
        rangeTo: 'auto',
        rangeSpan: 'auto',
      },
    ],
  },
  chartType: CHART_TYPES.BAR_CHART,
  usedData: [
    {
      id: '0', // Po co to ?
      description: 'Zużycie',
      dataSource: 'usage',
      axis: '0',
      showRegresion: false,
      dotted: false,
      lineWidth: 2,
      lineColor: barColor,
      dashed: false,
      dashLength: 5,
      dashSpacing: 5,
      unit,
    },
  ],
  title,
  dataFieldName,
});

export const preventNaN = v =>
  Number.isNaN(v) || v === 'NaN' || v === null || v === undefined ? 0 : v;

export const getMyBuildingsChartsConfig = ({
  avgElectricityUsage,
  avgHeatUsage,
  avgEnergyUsage,
  avgWaterUsage,
}) =>
  [
    {
      barColor: '#10C8CA',
      dataFieldName: 'electricityUsagesPerSqMeter',
      unit: 'kWh/m^2*rok',
      title: 'Zużycie energii elektrycznej [kWh/m^2]',
      avgValue: preventNaN(avgElectricityUsage),
    },
    {
      barColor: '#4462ba',
      dataFieldName: 'heatUsagesPerSqMeter',
      unit: 'kWh/m^2*rok',
      title: 'Zużycie ciepła [kWh/m^2]',
      avgValue: preventNaN(avgHeatUsage),
    },
    {
      barColor: '#7FD2BE',
      dataFieldName: 'energyUsagesPerSqMeter',
      unit: 'kWh/m^2*rok',
      title: 'Zużycie energii [kWh/m^2]',
      avgValue: preventNaN(avgEnergyUsage),
    },
    {
      barColor: '#2490be',
      dataFieldName: 'waterUsagesPerSqMeter',
      unit: 'm^3/m^2*rok',
      title: 'Zużycie wody [m^3/m^2]',
      avgValue: preventNaN(avgWaterUsage),
    },
  ].map(getConfig);
