import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { isNil } from 'lodash';
import { Checkbox, Input, Button } from "antd";

import { settingsType } from "../utils/types";

import FormItemWrapper, { validate } from "../../../../../../components/FormItemWrapper";
import { FormFooter, FormInner, StyledFrom } from "../../../../components/StyledForm";
import { withDelayedUpdate } from "../../../../../../utils/delayed";

const DelayedInput = withDelayedUpdate(Input);

const EditSettingsForm = Object.assign(
({ editedData, onClose, updateData }) => {
  const [triggered, setTriggered] = useState(false);
  const [_value, setValue] = useState(editedData);

  const formSettings = {
    isEdit: true,
    triggered,
  };

  useEffect(() => {
    setValue(editedData);
  }, [editedData]);

  const updateWithValid = ((data, isValid) =>
    isValid ? updateData({...editedData, ...data}): setValue({..._value, ...data}));

  const update = (key) => ((data, isValid) => updateWithValid({[key]: data}, isValid));

  const changeTitle = ((data, isValid) => updateWithValid({
    title: data,
    ...((isValid && !isNil(data) && data !== '') && {
      showTitle: true,
    }),
  }, isValid));

  const onReset = () => {
    setTriggered(false);
    setValue(editedData);
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const toValidate = [
      'title',
    ];
    const isValid = toValidate.map((key) => validate({
      ...setupSettings[key],
      value: _value[key],
      rules: setupSettings[key] ? (setupSettings[key].rules || []) : [],
    })).reduce((prev, {validateStatus}) => prev && validateStatus !== 'error', true);
    if(!isValid) {
      setTriggered(true);
    }
    else {
      onClose();
      onReset();
    }
  }

  return (
    <StyledFrom
      layout="vertical"
      hideRequiredMark
      onSubmit={onSubmit}
    >
      <FormInner>
        <FormItemWrapper
          value={_value.title}
          onChange={changeTitle}
          {...formSettings}
          {...setupSettings.title}
        >
          <DelayedInput placeholder="Tytuł wykresu" />
        </FormItemWrapper>
        <FormItemWrapper
          value={_value.showTitle}
          valueKey="checked"
          onChange={update('showTitle')}
          {...formSettings}
          {...setupSettings.showTitle}
        >
          <Checkbox>Pokaż tytuł na wykresie</Checkbox>
        </FormItemWrapper>
        <FormItemWrapper
          value={_value.showLegend}
          valueKey="checked"
          onChange={update('showLegend')}
          {...formSettings}
          {...setupSettings.showLegend}
        >
          <Checkbox>Pokaż legendę na wykresie</Checkbox>
        </FormItemWrapper>
        <FormItemWrapper
          value={_value.horizontalGrid}
          valueKey="checked"
          onChange={update('horizontalGrid')}
          {...formSettings}
          {...setupSettings.horizontalGrid}
        >
          <Checkbox>Pokaż poziome linie siatki</Checkbox>
        </FormItemWrapper>
        <FormItemWrapper
          value={_value.verticalGrid}
          valueKey="checked"
          onChange={update('verticalGrid')}
          {...formSettings}
          {...setupSettings.verticalGrid}
        >
          <Checkbox>Pokaż pionowe linie siatki</Checkbox>
        </FormItemWrapper>
      </FormInner>
      <FormFooter>
        <Button
          onClick={onReset}
          style={{ marginRight: 16 }}
        >
          Zresetuj
        </Button>
        <Button
          type="primary"
          icon="redo"
          htmlType="submit"
        >
          Zakończ
        </Button>
      </FormFooter>
    </StyledFrom>
  );
},
{
  propTypes: {
    editedData: PropTypes.shape(settingsType).isRequired,
    onClose: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
  },
});

const setupSettings = {
  title: {
    label: "Tytuł wykresu",
    rules: [
      {
        validate: (v) => v.length <= 60,
        errorMessage: 'Maksymalnie 60 znaków',
      },
    ],
  },
  showTitle: {},
  showLegend: {},
  horizontalGrid: {},
  verticalGrid: {},
};

export default Object.assign(
({ settings, onClose, updateData }) => {
  const onUpdateFields = changedFields => {
    if (changedFields !== settings) {
      updateData({
        ...settings,
        ...changedFields,
      });
    }
  };

  return (
    <EditSettingsForm
      onClose={onClose}
      editedData={settings}
      updateData={onUpdateFields}
    />
  );
},
{
  propTypes: {
    settings: PropTypes.shape(settingsType).isRequired,
    onClose: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
  },
});
