import React, { useContext } from 'react';

import { AppContext } from '../../AppDataProvider';

import AdminAlarms from './scenes/AdminAlarms';
import UserAlarms from './scenes/UserAlarms';

const Alarms = () => {
  const { isAdmin } = useContext(AppContext);

  return isAdmin ? <AdminAlarms /> : <UserAlarms />;
};

export default Alarms;
