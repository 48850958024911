const energyUsageAreas = Object.freeze({
  mainCooling: {
    description: 'Główne chłodzenie',
    shortcut: 'Chłodz.',
    color: '#03cca7',
  },
  mainVentilation: {
    description: 'Główna wentylacja',
    shortcut: 'Went.',
    color: '#2490be',
  },
  mainLight: {
    description: 'Główne oświetlenie',
    shortcut: 'Oświetl.',
    color: '#10C8CA',
  },
  cwu: {
    description: 'Ciepła woda użytkowa',
    shortcut: 'CWU',
    color: '#249BBE',
  },
  co: {
    description: 'Centralne ogrzewanie',
    shortcut: 'CO',
    color: '#0E4194',
  },
});

export default energyUsageAreas;

export const mapUsageAreaDescription = key =>
  (value => (value ? value.description : key))(energyUsageAreas[key]);

export const mapUsageAreaShortcut = key =>
  (value => (value ? value.shortcut : key))(energyUsageAreas[key]);

export const mapUsageAreaColor = ({ key }) =>
  (value => (value ? value.color : key))(energyUsageAreas[key]);
