import axios from 'axios';
import moment from 'moment';
import { isNil, sortBy } from 'lodash';


export const getBuildingData = async (buildingId) => {
  const resp = await axios.get(`/Dashboard/fixed/${buildingId}`);
  return resp;
};

const isNull = data => Object.keys(data).every(key => key === 'time' || isNil(data[key]) || parseFloat(data[key]) === 0);

const mapTime = (data = []) => data.map(({time, ...rest}) => ({
  time: moment(time).valueOf(),
  ...rest,
}));

export const mapMulti = ({data = [], ...rest}, withTime = true) => ({
  data: withTime ? mapTime(data) : data,
  settings: {
    ...rest,
    hide: (data.length === 0) || data.every(e => isNull(e)),
  },
});

const mapTimeValues = (data = [], key = 'value') => data.map(({value, time}) => ({
  [key]: value,
  time: moment(time).valueOf(),
}));

export const mapListData = ({data = [], ...rest}, key) => ({
  data: mapTimeValues(data, key),
  settings: {
    ...rest,
    hide: (data.length === 0) || data.every(e => isNull(e)),
  },
});

const mergeData = (loadedData = [], dataKey = 'data', initialSettings = {}) => {
  const merged = loadedData.reduce(
    ({data, settings}, loaded, idx) => ({
      data: [...data, ...mapTimeValues(loaded.data, `${dataKey}_${idx}`)],
      settings: {
        ...settings,
        measurements: [...settings.measurements, {
          dataSource: `${dataKey}_${idx}`,
          description: loaded.label,
        }],
      },
    }),
    {
      data: [],
      settings: {
        ...initialSettings,
        measurements: [],
        hide: (loadedData.length === 0) || loadedData.every(e => isNull(e)),
      },
    },
  );
  const sorted = sortBy(merged.data, 'time');
  const final = [];
  let value = null;
  for (let i = 0; i < sorted.length; i += 1) {
    if (sorted[i].time === value) {
      const last = final.pop();
      final.push({ ...last, ...sorted[i] });
    } else {
      final.push(sorted[i]);
    }
    value = sorted[i].time;
  }
  return {
    ...merged,
    data: final,
  };
};

const mapComparedConsumption = ({previous, current}) => {
  const result = [];
  if(previous && previous.value && !Number.isNaN(previous.value) && previous.value !== 'NaN') {
    result.push({
      consumption: previous.value,
      time: 'Ubiegły',
    });
  }
  result.push({
    consumption: current.value,
    time: 'Bieżący',
  });
  return {
    data: result,
    settings: {},
  };
}

export const getBuildingTime = async (buildingId, time) => {
  const dataProvider = {
    day: getDay,
    week: getWeek,
    month: getMonth,
    year: getYear,
  };
  const resp = await dataProvider[time](buildingId);
  return resp;
};

const defaultCarbDioxLimit = 1000;

const getDay = async (buildingId) => {
  const resp = await axios.get(`/Dashboard/day/${buildingId}`);
  const data = resp.data || {};
  return {
    ...resp,
    data: {
      heatPowerConsumption: mapListData(data.heatPowerConsumption, 'heatPower'),
      electricityPowerConsumption: mapListData(data.eletricPowerConsumption, 'electricityPower'),
      OZEPowerProduction: mapListData(data.renewableEnergySourcesProduction, 'OZEPower'),
      temperatures: mergeData(data.temperatures, 'temp'),
      co2: mergeData(data.carbDiox, 'co2', { limit: data.carbDioxLimit || defaultCarbDioxLimit }),
      humidity: mergeData(data.humidity, 'humidity'),
    },
  }
}

const getWeek = async (buildingId) => {
  const resp = await axios.get(`/Dashboard/week/${buildingId}`);
  const data = resp.data || {};
  return {
    ...resp,
    data: {
      heatPowerConsumption: mapListData(data.heatPowerConsumption, 'heatPower'),
      electricityPowerConsumption: mapListData(data.eletricPowerConsumption, 'electricityPower'),
      OZEProduction: mapListData({ data: buildingId === 9 ? [] : data.renewableEnergySourcesProduction }, 'dailyOZEProduction'),
      temperatures: mergeData(data.temperatures, 'temp'),
      co2: mergeData(data.carbDiox, 'co2', { limit: data.carbDioxLimit || defaultCarbDioxLimit }),
      totalConsumption: mapMulti( {data: data.totalEnergyConsumption}),
    },
  }
}

const getMonth = async (buildingId) => {
  const resp = await axios.get(`/Dashboard/month/${buildingId}`);
  const data = resp.data || {};
  return {
    ...resp,
    data: {
      dailyHeatConsumption: mapListData({ data: data.heatPowerConsumption }, 'dailyHeatConsumption'),
      dailyElectricityConsumption: mapListData({ data: data.eletricPowerConsumption }, 'dailyElectricityConsumption'),
      dailyOZEProduction: mapListData({ data: buildingId === 9 ? [] : data.renewableEnergySourcesProduction }, 'dailyOZEProduction'),
      heatingCurve: mapMulti({data: data.heatingCurve}),
      energyUsageAreas: mapMulti({data: data.energyUsageAreas}, false),
      comparedConsumption: mapComparedConsumption(data.comparedConsumption),
    },
  }
}

const getYear = async (buildingId) => {
  const resp = await axios.get(`/Dashboard/year/${buildingId}`);
  const data = resp.data || {};
  return {
    ...resp,
    data: {
      monthlyHeatConsumption: mapListData({ data: data.heatPowerConsumption }, 'monthlyHeatConsumption'),
      monthlyElectricityConsumption: mapListData({ data: data.eletricPowerConsumption }, 'monthlyElectricityConsumption'),
      monthlyOZEProduction: mapListData({ data: buildingId === 9 ? [] : data.renewableEnergySourcesProduction }, 'monthlyOZEProduction'),
      heatingCurve: mapMulti({data: data.heatingCurve}),
      energyUsageAreas: mapMulti({data: data.energyUsageAreas}, false),
      comparedConsumption: mapComparedConsumption(data.comparedConsumption),
    },
  }
}
