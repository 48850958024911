import axios from 'axios';
import moment from 'moment';
import { sortBy } from 'lodash';


const GenerateMultiQuery = (multi) =>
  multi.map(e => (([type, id]) => ({type, id: parseInt(id, 10)}))(e.split('_')))
  .reduce((result, {type, id}) => {
    const index = result.findIndex(r => r.type === type);
    if(index !== -1) {
      result[index].measurements.push({id});
    }
    else {
      result.push({
        type,
        measurements: [{id}],
      });
    }
    return result;
  }, []);

const parseBaseMeasurements = (baseData) =>
  baseData.map(({Date: time, ...rest}) => ({
    time: moment(time).valueOf(),
    ...rest,
  }));

const parseMultiMeasurements = (mutliData) =>
  mutliData.reduce((result, {measurements, type}) => [
    ...result,
    ...measurements.map(({meterId, time, value}) =>({
      time: moment(time).valueOf(),
      [`${type}_${meterId}`]: value,
    })),
  ], []);

  const mergeData = async (loadedData = []) => {
    return new Promise((resolve) => {
      const sorted = sortBy(loadedData, 'time');
      const final = [];
      let value = null;
      for (let i = 0; i < sorted.length; i += 1) {
        if (sorted[i].time === value) {
          const last = final.pop();
          final.push({ ...last, ...sorted[i] });
        } else {
          final.push(sorted[i]);
        }
        value = sorted[i].time;
      }
      resolve(final);
    });
  };


export const getLineChartData = async ({building, from, to, measurements}) => {
  const parsedRequest = {
    filterReadings: {
      buildingId: building,
      lowerBound: from,
      upperBound: to,
    },
    measurements: {
      baseMeasurements: measurements.filter(e => !e.includes('_')),
      multipleMeasurements: GenerateMultiQuery(measurements.filter(e => e.includes('_'))),
    },
  };
  const resp = await axios.post(`/Analysis/data`, parsedRequest);
  const merged = await mergeData([
    ...parseBaseMeasurements(resp.data.baseMeasurements),
    ...parseMultiMeasurements(resp.data.multiMeasurements),
  ]);
  return {
    ...resp,
    data: merged,
  }
}

export const getBuildingMeasurements = async (buildingId) => {
  const resp = await axios.get(`/Analysis/measurements/${buildingId}`);
  return resp;
}
