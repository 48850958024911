import React from 'react';
import styled from 'styled-components';

export const PdfContainer = ({padding: {top, bottom} = {}, children, props}) => (
    <StyledTable {...props}>
        {top ? <TopPlaceHolder size={top} /> : null}
        {bottom ? <BottomPlaceHolder size={top} /> : null}
        <tbody>
            <tr>
                <td>
                    {children}
                </td>
            </tr>
        </tbody>
    </StyledTable>);

const TopPlaceHolder = ({size, ...props}) => (
    <thead {...props} style={{height: `${size}px`}}>
        <tr>
            <td>
                <div style={{height: `${size}px`}} />
            </td>
        </tr>
    </thead>);

const BottomPlaceHolder = ({size, ...props}) => (
    <tfoot {...props} style={{height: `${size}px`}}>
        <tr>
            <td>
                <div style={{height: `${size}px`}} />
            </td>
        </tr>
    </tfoot>);

const StyledTable = styled.table`
    table-layout: fixed;
    width: 960px;

    thead {display: none;}

    tfoot {display: none;}

    @media print {
        thead {display: table-header-group;} 
        tfoot {display: table-footer-group;}
    }
`;

export const PdfHeader = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    display: none;

    @media print {
        display: block;
    }
`;

export const PdfFooter = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    display: none;

    @media print {
        display: block;
    }
`;
