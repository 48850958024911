import React, {useState} from 'react'
import styled from 'styled-components';
import { difference, filter, uniq } from 'lodash';

import { Checkbox, Input } from 'antd';

const defaultRender = (e) => e;

const reducer = (prev, isIn) => ({
    checkedAll: prev.checkedAll && isIn,
    checkedAny: prev.checkedAny || isIn,
});

export default ({
    options = [],
    value = [],
    onChange,
    allowAll,
    allowSearch,
    filterOption,
    renderItem = defaultRender,
}) => {
const [filterValue, changeFilter] = useState();

const onCheck = (event, key) => {
    if(event.target.checked) {
        onChange([...value, key]);
    }
    else {
        onChange(filter(value, e => e !== key));
    }
}

const filteredOptions = filter(options,
    e => !filterOption || !filterValue || filterValue === '' || filterOption(filterValue, e));

const filteredEnabled = filter(filteredOptions, e => !e.disabled);

const { checkedAll, checkedAny } = filteredEnabled.reduce(
    (prev, opt) => reducer(prev, value.includes(opt.key)),
    {
        checkedAll: true,
        checkedAny: false,
    },
);

const onCheckAll = () => {
    const all = filteredEnabled.map(opt => opt.key)
    if(checkedAll) {
        onChange(difference(value, all));
    }
    else {
        onChange(uniq([
            ...all,
            ...value,
        ]));
    }
}
return (
    <MultiCheckBox>
        {allowAll && (
            <Header>
                <Checkbox
                    indeterminate={checkedAny && !checkedAll}
                    onChange={onCheckAll}
                    checked={checkedAll}
                >
                    Zaznacz wszystkie
                </Checkbox>
            </Header>)}
        {allowSearch && (
            <Header>
                <Input
                    placeholder="Wyszukaj"
                    value={filterValue}
                    onChange={e => changeFilter(e.target.value)}
                    allowClear

                />
            </Header>)}
        <CheckboxGroup>
            {filteredOptions.map(({key, ...props}) =>
            <Item key={key}>
                <Checkbox
                    onChange={(event) => onCheck(event, key)}
                    checked={value.includes(key)}
                    {...props}
                >
                    {renderItem({key, ...props})}
                </Checkbox>
            </Item>)}
        </CheckboxGroup>
    </MultiCheckBox>);
}

const MultiCheckBox = styled.div`
    padding: 7px 15px;
    border: 1px solid #E9E9E9;
    border-radius: 4px;
    .has-error & {
        border-color: #f5222d;
    }
`;

const Header = styled.div`
    border-bottom: 1px solid #E9E9E9;
    padding: 8px 0 7px 0;
`;

const CheckboxGroup = styled.div`
    height: 160px;
    overflow: auto;
    ${Header} + & {
        margin-top: 8px;
    }
`;

const Item = styled.div`
    & + & {
        margin-top: 8px;
    }
`;
