import React from 'react';
import styled from 'styled-components';
import { Radio } from 'antd';

export default ({onChange, currentTime}) => {
  return (
    <TimeSelect>
        <Radio.Group
          buttonStyle="solid"
          onChange={onChange}
          value={currentTime}
        >
        {timeTabs.map(({key, description}) =>
            <StyledRadioButton key={key} value={key}>{description}</StyledRadioButton>,
        )}
        </Radio.Group>
    </TimeSelect>
  );
}

const timeTabs = [
  {
    key: "day",
    description: "Dzień",
  },
  {
    key: "week",
    description: "Tydzień",
  },
  {
    key: "month",
    description: "Miesiąc",
  },
  {
    key: "year",
    description: "Rok",
  },
];

const StyledRadioButton = styled(Radio.Button)`
  min-width: 80px;
  text-align: center;
`;

const TimeSelect = styled.div`
  position: relative;
  text-align: center;
  .ant-radio-button-wrapper {
    background: #F8F8F8 !important;
  }
  .ant-radio-button-wrapper-checked {
    background: #0E4194 !important;
    border-color: #0E4194 !important;
    box-shadow: -1px 0 0 0 #0E4194;
  }
  .ant-radio-button-wrapper-checked:hover {
    box-shadow: -1px 0 0 0 #0E4194;
  }
  .ant-radio-button-wrapper-checked::before {
    background: #0E4194 !important;
  }
`;
