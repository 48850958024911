import React from 'react';
import styled from 'styled-components';
import { isNil } from 'lodash';

import MathParser from '../../../components/MathParser';
import { formatNumber, isNumberConverted, formatDownfall } from '../../../utils/formaters';
import InlineValue from './InlineValue';

export default ({ buildingData, weatherStationName, loading }) => {
  const getValue = ({ key, value }) => value || buildingData[key];
  const getData = ({ key, value, valueFormatter }) =>
    (v => (valueFormatter ? valueFormatter(v) : v))(getValue({ key, value }));

  const { goodPm10, badPm10, goodPm25, badPm25 } = buildingData || {};

  return (
    <WrappedCurrentData>
      <WeatherStation>
        <InlineValue
          loading={loading}
          label="Dane ze stacji pogodowej:"
          value={loading ? undefined : weatherStationName}
        />
      </WeatherStation>
      <CurrentData>
        {elements.map(element => (
          <MyRow key={element.key}>
            {element.formatter(
              loading
                ? {
                    loading,
                    label: element.label,
                    unit: element.unit(''),
                    value: '',
                  }
                : {
                    value: getData(element),
                    label: element.label,
                    unit: element.unit(getData(element)),
                    pm10: {
                      good: goodPm10 || PM10.good,
                      bad: badPm10 || PM10.bad,
                    },
                    pm25: {
                      good: goodPm25 || PM25.good,
                      bad: badPm25 || PM25.bad,
                    },
                  },
            )}
          </MyRow>
        ))}
      </CurrentData>
    </WrappedCurrentData>
  );
};

const PM10 = {
  good: 61,
  bad: 141,
};

const PM25 = {
  good: 37,
  bad: 85,
};

const renderPMElement = ({ pm, ...props }) => (
  <StyledInlineValue {...pm} {...props} />
);

const renderPM10Element = ({ pm10, ...props }) =>
  renderPMElement({ pm: pm10, ...props });
const renderPM25Element = ({ pm25, ...props }) =>
  renderPMElement({ pm: pm25, ...props });

const renderElement = ({ value, label, unit, ...props }) => (
  <InlineValue label={label} value={value} unit={unit} {...props} />
);

const elements = [
  {
    key: 'tempOutside',
    label: 'Temp:',
    unit: () => (<MathParser>^oC</MathParser>),
    valueFormatter: value => !isNil(value) ? `${formatNumber(value, { position: -1 })}` : '',
    formatter: renderElement,
  },
  {
    key: 'downfall',
    label: 'Opady:',
    unit: (value) => (isNumberConverted(value) ? 'mm' :''),
    valueFormatter: value => !isNil(value) ? formatDownfall(value) : 'Brak',
    formatter: renderElement,
  },
  {
    key: 'humidity',
    label: 'Wilgotność:',
    unit: () => '%',
    valueFormatter: value => !isNil(value) ? `${formatNumber(value)}` : '',
    formatter: renderElement,
  },
  {
    key: 'pm25',
    label: 'PM2,5:',
    unit: () => (<MathParser>μg/m^3</MathParser>),
    valueFormatter: value => !isNil(value) ? `${formatNumber(value)}` : value,
    formatter: renderPM25Element,
  },
  {
    key: 'pm10',
    label: 'PM10:',
    unit: () => (<MathParser>μg/m^3</MathParser>),
    valueFormatter: value => !isNil(value) ? `${formatNumber(value)}` : value,
    formatter: renderPM10Element,
  },
];

const colorPMConfig = {
  good: {
    color: 'white',
    background: '#74B885',
  },
  medium: {
    color: 'white',
    background: '#FFDF82',
  },
  bad: {
    color: 'white',
    background: '#C9776B',
  },
};

const StyledInlineValue = ({ ...props }) => {
  const { value, good, bad } = props;
  const isValid = value !== undefined && value !== null;
  let type;
  if (isValid) {
    const isGood = value < good;
    const isBad = value > bad;
    // eslint-disable-next-line no-nested-ternary
    type = !(isGood || isBad) ? 'medium' : isGood ? 'good' : 'bad';
  }
  return isValid ? (
    <InlineValue
      style={{
          ...colorPMConfig[type],
          borderRadius: '4px',
        }}
      {...props}
    />
  ): null
};

const WeatherStation = styled.div`
  margin-bottom: 4px;
  display: inline-block;
`;

const WrappedCurrentData = styled.div`
  text-align: right;
`;

const CurrentData = styled.div`
  display: flex;
`;

const MyRow = styled.div`
  & + & {
    & > div {
      margin-left: 16px;
      @media (max-width: 1440px) {
        margin-left: 8px;
      }
      @media (max-width: 1366px) {
        margin-left: 4px;
      }
    }
  }
`;
