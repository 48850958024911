import moment from "moment";

export const formatMonth = timestamp =>
  moment.unix(timestamp / 1000).format("MMMM");
export const formatDate = timestamp =>
  moment.unix(timestamp / 1000).format("YYYY-MM-DD");
export const formatHour = timestamp => moment.unix(timestamp / 1000).format("HH:mm");
export const formatDateTime = timestamp =>
  moment.unix(timestamp / 1000).format("YYYY-MM-DD HH:mm");

export const isFilledString = str => str && str.length;

export const formatNumber = (num, {position = 0, addSpace = true} = {}) => (
  (str) => addSpace ? numberWithSpaces(str) : str
  )(position < 0 ? parseFloat(num.toFixed(-position)) : Math.round(num / 10**position) * 10**position);

export const formatMoney = (num, {addSpace = true} = {}) =>
  ((str) => addSpace ? numberWithSpaces(str) : str)(num.toFixed(2));

const numberWithSpaces = (x) => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export const removePolish = str =>
str.replace(/ę/ig,'e').replace(/[żź]/ig,'z').replace(/ó/ig,'o').replace(/ł/ig,'l')
  .replace(/ć/ig,'c').replace(/ś/ig,'s').replace(/ń/ig,'n').replace(/ą/ig,'a')
  .replace(/Ę/ig,'E').replace(/[ŻŹ]/ig,'Z').replace(/Ó/ig,'O').replace(/Ł/ig,'L')
  .replace(/Ć/ig,'C').replace(/Ś/ig,'S').replace(/Ń/ig,'N').replace(/Ą/ig,'A');

export const formatPeriod = (startDate, endDate) => {
  let period = `${moment(startDate).format('MMMM YYYY')} - ${moment(endDate).format('MMMM YYYY')}`;
  if(moment(startDate).isSame(moment(endDate), 'year')) {
      period = `${moment(startDate).format('MMMM')} - ${moment(endDate).format('MMMM YYYY')}`;
      if(moment(startDate).isSame(moment(endDate), 'month')) {
          period = moment(startDate).format('MMMM');
      }
  }
  return period;
}

export const formatDownfall = str =>
  isNumberConverted(str) ? Number.parseFloat(str) : str;

export const isNumberConverted = str =>
  !Number.isNaN(Number.parseFloat(str));
