import { CHART_SIZES } from '../../components/ChartHolder';
import { formatNumber } from '../../../../utils/formaters';
import { colors } from '../../../../components/ColorSelect';
import { CHART_TYPES } from '../../../../components/CustomChart';

const colorMapper = ({idx}) => colors[idx % colors.length].color;
const buildingColorMapper = (building, id) => colorMapper({idx: id});

const pieChartConfig = {
  chartType: CHART_TYPES.PIE_CHART,
  chartSize: CHART_SIZES.SMALL,

  isAnimationActive: false,
  showEmpty: false,
  showLabel: false,
  showTitle: false,
  showTooltip: false,

  innerRadius: "0%",
  outerRadius: "85%",

  descriptionKey: "shortName",
  unit: "kWh",

  colorMapper,
  dataFormatter: (e) => formatNumber(e, {position: 0}),
};

export default Object.assign({
  heatToArea: {
      chartConfig: {
        settings: {
          stopAnimation: true,
          stopInteractive: true,
          title: "Zależność jednostkowego zużycia ciepła od powierzchni budynku",
          showTitle: true,
          showLegend: false,
          horizontalGrid: true,
          verticalGrid: false,
          xFormatter: (e) => formatNumber(e, {position: 0}),
          yFormatter: (e) => formatNumber(e, {position: 1}),
        },
        xAxis: {
          xKey: "unitHeatTotal",
          description: "Jednostkowe zużycie ciepła",
          showDescription: true,
          color: "#333",
          unit: "kWh/m^2",
          rangeFrom: "auto",
          rangeTo: "auto",
          rangeSpan: "auto",
        },
        yAxises: [
          {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Powierzchnia budynku",
              showDescription: true,
              unit: "m^2",
              rangeFrom: "auto",
              rangeTo: "auto",
              rangeSpan: "auto",
          },
        ],
      },
      chartType: CHART_TYPES.SCATTERED_CHART,
      chartSize: CHART_SIZES.BIG,
      usedData: [
        {
          id: "0",
          axis: "0",
          descriptionId: "shortName",
          showDescription: true,
          dataSource: "area",
          radius: 10,
          lineWidth: 2,
          lineColor: "#063951",
          unit: "m^2",
        },
      ],
  },
  electricityToArea: {
      chartConfig: {
        settings: {
          stopAnimation: true,
          stopInteractive: true,
          title: "Zależność jednostkowego zużycia energii elektrycznej od powierzchni budynku",
          showTitle: true,
          showLegend: false,
          horizontalGrid: true,
          verticalGrid: false,
          xFormatter: (e) => formatNumber(e, {position: 0}),
          yFormatter: (e) => formatNumber(e, {position: 1}),
        },
        xAxis: {
          xKey: "unitElectricityTotal",
          description: "Jednostkowe zużycie energii elektrycznej",
          showDescription: true,
          color: "#333",
          unit: "kWh/m^2",
          rangeFrom: "auto",
          rangeTo: "auto",
          rangeSpan: "auto",
        },
        yAxises: [
          {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Powierzchnia budynku",
              showDescription: true,
              unit: "m^2",
              rangeFrom: "auto",
              rangeTo: "auto",
              rangeSpan: "auto",
          },
        ],
      },
      chartType: CHART_TYPES.SCATTERED_CHART,
      chartSize: CHART_SIZES.BIG,
      usedData: [
        {
          id: "0",
          axis: "0",
          descriptionId: "shortName",
          showDescription: true,
          dataSource: "area",
          radius: 10,
          lineWidth: 2,
          lineColor: "#063951",
          unit: "m^2",
        },
      ],
  },
  electricityUsageByBuildings: {
    ...pieChartConfig,
    dataKey: "electricityTotal",
  },
  heatUsageByBuildings: {
    ...pieChartConfig,
    dataKey: "heatTotal",
  },
  waterUsageByBuildings: {
    ...pieChartConfig,
    dataKey: "waterTotal",
  },
  buildingColorMapper,
  pm: {
    chartConfig: {
      settings: {
        stopAnimation: true,
        stopInteractive: true,
        title: "Wyniki pomiarów stężenia pyłów",
        showTitle: true,
        showLegend: true,
        horizontalGrid: true,
        verticalGrid: true,
        yFormatter: (e) => formatNumber(e, {position: -1}),
      },
      xAxis: {
        xKey: "time",
        description: "Czas",
        showDescription: false,
        color: "#333",
        datesNumber: 4,
        withTime: false,
      },
      yAxises: [
        {
          id: "0",
          color: "#333",
          showAxis: true,
          side: "left",
          description: "Stężenie pyłów",
          showDescription: true,
          unit: "μg/m^3",
          rangeFrom: "0",
          rangeTo: "auto",
          rangeSpan: "auto",
        },
      ],
    },
    chartType: CHART_TYPES.LINE_CHART,
    chartSize: CHART_SIZES.BIG,
    usedData: [
      {
        id: "0",
        description: "PM2,5",
        dataSource: "pm25",
        axis: "0",
        showRegresion: false,
        dotted: false,
        lineWidth: 2,
        lineColor: colors[0].color,
        dashed: false,
        dashLength: 5,
        dashSpacing: 5,
        unit: "μg/m^3",
      },
      {
        id: "1",
        description: "PM10",
        dataSource: "pm10",
        axis: "0",
        showRegresion: false,
        dotted: false,
        lineWidth: 2,
        lineColor: colors[1].color,
        dashed: false,
        dashLength: 5,
        dashSpacing: 5,
        unit: "μg/m^3",
      },
    ],
  },
});
