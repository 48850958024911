import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Page from './Page';
import Logo, {LOGO_SIZES} from '../../../components/Logo';
import { formatPeriod } from '../../../utils/formaters';

export default ({config, user, creationDate, ...props}) => (
    <StyledFrontPage {...props}>
        <LogoHolder>
            <Logo size={LOGO_SIZES.REPORT_FRONT_PAGE} />
        </LogoHolder>
        <MainTitle>Raport z monitoringu zużycia energii</MainTitle>
        <Title>{user.name}</Title>
        <ReportDate>{`Raport za okres: ${formatPeriod(config.startDate, config.endDate)}`}</ReportDate>
        <TableWrapper>
            <Table cellSpacing="0" cellPadding="0">
                <tbody>
                    <Row>
                        <Cell>Wykonawca</Cell>
                        <Cell>Krajowa Agencja Poszanowania Energii SA</Cell>
                    </Row>
                    <Row>
                        <Cell />
                        <Cell>Al. Jerozolimskie 65/79</Cell>
                    </Row>
                    <Row>
                        <Cell />
                        <Cell>00-697 Warszawa</Cell>
                    </Row>
                </tbody>
            </Table>
        </TableWrapper>
        <Month>{moment(creationDate).format('MMMM YYYY')}</Month>
    </StyledFrontPage>);

const StyledFrontPage = styled(Page)`
    display: none;
    @media print {
        min-width: 960px;
        min-height: 100%;
        display: block;
        page-break-before: always;
        page-break-after: always;
        color: black !important;
        z-index: 1;
        background: white;
    }
`;

const Title = styled.div`
    font-size: 42px;
    text-align: center;
    padding-top: 152px;
    height: 280px;
`;

const LogoHolder = styled.div`
    padding-top: 80px;
    text-align: center;
`;

const MainTitle = styled.div`
    padding-top: 100px;
    font-size: 48px;
    text-align: center;
    font-weight: bold;
`;

const ReportDate = styled.div`
    padding-top: 108px;
    font-size: 28px;
    text-align: center;
    font-weight: bold;
`;

const TableWrapper = styled.div`
    padding-top: 300px;
`;

const Table = styled.table`
    border: none;
`;

const Cell = styled.td`
    font-size: 24px;
    line-height: 32px;
    color: black !important;
    font-weight: bold;
`;

const Row = styled.tr`
    ${Cell}:first-child {
        padding-right: 48px;
    }
`;

const Month = styled.div`
    padding-top: 132px;
    font-size: 24px;
    text-align: center;
    font-weight: bold;
`;
