import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { Icon } from 'antd';


export default ({data = {}, loading}) =>
    <TimeSection>
        Wskazania od<br />
        {loading ?
            <Icon type="loading" spin />
            :
            moment(data.firstReadingDate).format('YYYY-MM-DD')}
    </TimeSection>

const TimeSection = styled.div`
    margin-top: 24px;
    margin-bottom: 8px;
    @media (max-height: 800px) {
        margin-top: 12px;
        margin-bottom: 4px;
    }
    text-align: center;
    font-size: 1rem;
    @media (max-width: 1600px) {
        font-size: 0.9em;
    }
    @media (max-width: 1440px) {
        font-size: 0.8em;
    }
    font-weight: bold;
`;
