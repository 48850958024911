import React from 'react';
import styled from 'styled-components';
import { InputNumber } from 'antd';

export default ({ children, ...props }) => (
  <DisabledInput style={{ pointerEvents: 'none' }} {...props} disabled>
    {children}
  </DisabledInput>
);

const DisabledInput = styled(InputNumber)`
  &.ant-input[disabled] {
    color: rgba(0, 0, 0, 0.85);
    cursor: text;
  }
`;
