import React, { useState, useContext, createContext } from 'react';
import { message } from 'antd';

import { AppContext } from '../../AppDataProvider';
import { AdministrationContext } from '../Administration/AdministrationDataProvider';

import {
  getActiveAlarms,
  getAlarmsHistory,
  postAlarmDeactivation,
  postNewAlarmSetting,
} from './services/api';
import { extendAlarms } from './services/const';

const AlarmsContext = createContext({});

const AdministrationDataProvider = ({ children }) => {
  const { startAsyncTask, finishAsyncTask, enums, wrapRequest } = useContext(
    AppContext,
  );
  const { fetchBuildings } = useContext(AdministrationContext);

  const [activeAlarms, setActiveAlarms] = useState([]);
  const [alarmsHistory, setAlarmsHistory] = useState([]);

  async function fetchActiveAlarms() {
    startAsyncTask();
    await wrapRequest(() => getActiveAlarms(), {
      onSuccess: data => {
        setActiveAlarms(extendAlarms(data, enums.alarmTypes));
      },
    });
    finishAsyncTask();
  }

  async function fetchAlarmsHistory() {
    startAsyncTask();
    await wrapRequest(() => getAlarmsHistory(), {
      onSuccess: data => {
        setAlarmsHistory(extendAlarms(data, enums.alarmTypes));
      },
    });
    finishAsyncTask();
  }

  async function changeSetting(setting) {
    startAsyncTask();
    await wrapRequest(() => postNewAlarmSetting(setting), {
      onError: (error = {}) => {
        message.error(
          error.data ||
            'Nie udało się zaktualizować ustawień alarmów. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.',
        );
      },
      onSuccess: fetchBuildings,
    });
    finishAsyncTask();
  }

  async function deactivateAlarm(alarmId) {
    startAsyncTask();
    await wrapRequest(() => postAlarmDeactivation(alarmId), {
      onError: (error = {}) => {
        message.error(
          error.data ||
            'Nie udało się wyłączyć alarmu. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.',
        );
      },
      onSuccess: () => {
        fetchActiveAlarms();
        fetchAlarmsHistory();
      },
    });
    finishAsyncTask();
  }

  const state = {
    activeAlarms,
    alarmsHistory,

    fetchActiveAlarms,
    fetchAlarmsHistory,
    changeSetting,
    deactivateAlarm,
  };

  return (
    <AlarmsContext.Provider value={state}>{children}</AlarmsContext.Provider>
  );
};

export { AlarmsContext };
export default AdministrationDataProvider;
