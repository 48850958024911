import React from 'react';
import styled from 'styled-components';

import Page from '../components/Page';
import PageHeading from '../components/PageHeading';
import Conclusion from '../components/Conclusion';

export default ({comments = {}, ...props}) =>
    (<Page {...props}>
        <IntroductionWrapper>
            <PageHeading
                id="ReportObject"
                to="Navigation"
                prefix="1."
            >
                Przedmiot raportu
            </PageHeading>
            <Conclusion
                comment={comments.reportObject}
                refId='reportObject'
            />
            <PageHeading
                id="ReportConclusion"
                to="Navigation"
                prefix="2."
            >
                Wnioski
            </PageHeading>
            <Conclusion
                comment={comments.reportConclusion}
                refId='reportConclusion'
            />
        </IntroductionWrapper>
    </Page>);

// WONTFIX jest jakis problem z ustawianiem pierwszego pola i sie rozciaga
const IntroductionWrapper = styled.div`
    margin: 24px 0;
    padding: 24px 0;
`;
