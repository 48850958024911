import { CHART_SIZES } from '../../components/ChartHolder';
import { formatNumber } from '../../../../utils/formaters';
import { CHART_TYPES } from '../../../../components/CustomChart';

export default Object.assign({
    climateIndex: () => ({
        chartConfig: {
          settings: {
            stopAnimation: true,
            stopInteractive: true,
            title: "Indeks klimatyczny",
            showTitle: true,
            showLegend: true,
            horizontalGrid: true,
            verticalGrid: true,
            stackData: true,
            yFormatter: (e) => formatNumber(e, {position: -1}),
          },
          xAxis: {
            xKey: "time",
            description: "Czas",
            showDescription: false,
            color: "#333",
            datesNumber: 4,
            withTime: false,
          },
          yAxises: [
            {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Zużycie ciepła",
              showDescription: false,
              unit: "%",
              rangeFrom: "0",
              rangeTo: "100",
              rangeSpan: "25",
            },
          ],
        },
        chartType: CHART_TYPES.AREA_CHART,
        chartSize: CHART_SIZES.SPREAD,
        usedData: [
          {
            id: "0",
            description: "Procent temp. w normie",
            dataSource: "good",
            axis: "0",
            lineWidth: 2,
            lineColor: "#556B3E",
            unit: "%",
          },
          {
            id: "1",
            description: "Procent zbyt niskiej temp.",
            dataSource: "cold",
            axis: "0",
            dotted: false,
            lineWidth: 2,
            lineColor: "#063951",
            unit: "%",
          },
          {
            id: "2",
            description: "Procent zbyt wysokiej temp.",
            dataSource: "hot",
            axis: "0",
            lineWidth: 2,
            lineColor: "#C13015",
            unit: "%",
          },
        ],
    }),
    temperatures: ({settings}) => ({
        chartConfig: {
          settings: {
            stopAnimation: true,
            stopInteractive: true,

            showLegend: false,
            horizontalGrid: true,
            verticalGrid: true,

            title: "Zależność temperatury wewnętrznej od zewnętrznej",
            showTitle: true,

            xFormatter: (e) => formatNumber(e, {position: -1}),
            yFormatter: (e) => formatNumber(e, {position: -1}),
          },
          ...(settings.limitMin && settings.limitMax && {
            areas: [
              {
                axis: "0",
                color: "green",
                opacity: 0.3,
                from: settings.limitMin,
                to: settings.limitMax,
              },
            ],
          }),
          xAxis: {
            xKey: "outsideTemperature",
            color: "#333",
            showAxis: true,
            description: "Śr. dzienna temperatura zewnętrzna",
            showDescription: true,
            unit: "^oC",
            rangeFrom: "auto",
            rangeTo: "auto",
            rangeSpan: "auto",
          },
          yAxises: [
            {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Śr. dzienna temperatura wewnętrzna",
              showDescription: true,
              unit: "^oC",
              rangeFrom: "auto",
              rangeTo: "auto",
              rangeSpan: "auto",
            },
          ],
        },
        chartType: CHART_TYPES.LINE_CHART,
        chartSize: CHART_SIZES.BIG,
        usedData: [
          {
            id: "0",
            description: "Śr. dzienna temperatura wewnętrzna",
            dataSource: "insideTemperature",
            axis: "0",
            dotted: true,
            lineWidth: 2,
            lineColor: "#063951",
            dashed: false,
            dashLength: 5,
            dashSpacing: 5,
            unit: "^oC",
            showRegresion: false,
            addRegresionBoundary: false,
            spreadRegresion: false,
          },
        ],
    }),
    heatingCurve: () => ({
        chartConfig: {
          settings: {
            stopAnimation: true,
            stopInteractive: true,

            showLegend: false,
            horizontalGrid: true,
            verticalGrid: true,
            showRegression: true,
            regresionPosition: 'left',

            title: "Charakterystyka energetyczna budynku",
            showTitle: true,

            xFormatter: (e) => formatNumber(e, {position: -1}),
            yFormatter: (e) => formatNumber(e, {position: -1}),
          },
          xAxis: {
            xKey: "outsideTemperature",
            color: "#333",
            showAxis: true,
            description: "Temperatura zewnętrzna",
            showDescription: true,
            unit: "^oC",
            rangeFrom: "-20",
            rangeTo: "12",
            rangeSpan: "8",
          },
          yAxises: [
            {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Dzienne zuż. ciepła",
              showDescription: true,
              unit: "kWh",
              rangeFrom: "auto",
              rangeTo: "auto",
              rangeSpan: "auto",
            },
          ],
        },
        chartType: CHART_TYPES.LINE_CHART,
        chartSize: CHART_SIZES.SPREAD,
        usedData: [
          {
            id: "0",
            description: "Dzienne zużycie ciepła",
            dataSource: "heatConsumption",
            axis: "0",
            dotted: true,
            lineWidth: 2,
            lineColor: "#063951",
            dashed: false,
            dashLength: 5,
            dashSpacing: 5,
            unit: "kWh",
            showRegresion: true,
            addRegresionBoundary: true,
            spreadRegresion: true,
          },
        ],
    }),
    hourlyHeat: ({settings}) => ({
        chartConfig: {
          settings: {
            stopAnimation: true,
            stopInteractive: true,
            title: "Przebieg poboru ciepła",
            showTitle: true,
            showLegend: false,
            horizontalGrid: true,
            verticalGrid: true,
            yFormatter: (e) => formatNumber(e, {position: -1}),
          },
          ...(settings.limit && {
            limits: [
              {
                description: null,
                axis: "0",
                lineWidth: 2,
                lineColor: "#C9776B",
                dashLength: 10,
                dashSpacing: 15,
                value: settings.limit,
              },
            ],
          }),
          xAxis: {
            xKey: "time",
            description: "Czas",
            showDescription: false,
            color: "#333",
            datesNumber: 4,
            withTime: false,
          },
          yAxises: [
            {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Pobór mocy cieplnej",
              showDescription: true,
              unit: "kW",
              rangeFrom: "0",
              rangeTo: "auto",
              rangeSpan: "auto",
            },
          ],
        },
        chartType: CHART_TYPES.LINE_CHART,
        chartSize: CHART_SIZES.SPREAD,
        usedData: [
          {
            id: "0",
            description: "Pobór mocy cieplnej",
            dataSource: "heatPower",
            axis: "0",
            showRegresion: false,
            dotted: false,
            lineWidth: 2,
            lineColor: "#0E4194",
            dashed: false,
            dashLength: 5,
            dashSpacing: 5,
            unit: "kW",
          },
        ],
    }),
    hourlyElectricity: ({settings}) => ({
        chartConfig: {
          settings: {
            stopAnimation: true,
            stopInteractive: true,
            title: "Przebieg poboru energii elektrycznej",
            showTitle: true,
            showLegend: false,
            horizontalGrid: true,
            verticalGrid: true,
            yFormatter: (e) => formatNumber(e, {position: -1}),
          },
          ...(settings.limit && {
            limits: [
              {
                description: null,
                axis: "0",
                lineWidth: 2,
                lineColor: "#C9776B",
                dashLength: 10,
                dashSpacing: 15,
                value: settings.limit,
              },
            ],
          }),
          xAxis: {
            xKey: "time",
            description: "Czas",
            showDescription: false,
            color: "#333",
            datesNumber: 4,
            withTime: false,
          },
          yAxises: [
            {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Pobór mocy elektrycznej",
              showDescription: true,
              unit: "kW",
              rangeFrom: "0",
              rangeTo: "auto",
              rangeSpan: "auto",
            },
          ],
        },
        chartType: CHART_TYPES.LINE_CHART,
        chartSize: CHART_SIZES.SPREAD,
        usedData: [
          {
            id: "0",
            description: "Pobór mocy elektrycznej",
            dataSource: "electricityPower",
            axis: "0",
            showRegresion: false,
            dotted: false,
            lineWidth: 2,
            lineColor: "#0E4194",
            dashed: false,
            dashLength: 5,
            dashSpacing: 5,
            unit: "kW",
          },
        ],
    }),
})
