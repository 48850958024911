import React, { useContext, Fragment } from 'react';
import { Redirect } from 'react-router';

import { AppContext } from '../AppDataProvider';
import { SignInRoute } from '../Routes';

const Authentication = ({ children }) => {
  const { isSignedIn } = useContext(AppContext);
  return isSignedIn ? (
    <Fragment>{children}</Fragment>
  ) : (
    <Redirect to={SignInRoute} />
  );
};

export default Authentication;
