import React from "react";
import {
  BarChart,
  Bar,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { find } from "lodash";
import styled from "styled-components";
import PropTypes from "prop-types";

import {
  chartRangesFromConfig,
  dateTicks,
} from "./utils/calculate";
import {
  dataFromXAxis,
  dataFromAxis,
  dataFromLimits,
  hasLeftAxis,
  hasRightAxis,
  isTimeChart,
} from "./utils/filters";
import {
  chartConfigType,
  usedDataType,
} from "./utils/chartTypes";
import CustomXAxis, { CustomTimeAxis, DefaultAxis } from "./CustomXAxis";
import CustomYAxis from "./CustomYAxis";
import CustomTooltip from "./CustomTooltip";
import CustomLegend from "./CustomLegend";
import CustomLimit from "./CustomLimit";
import { StyledChart, Container, ChartTitle} from './Common';
import MathParser from "../MathParser";


export default Object.assign(
  ({ usedData, data, chartConfig, extra }) => {
    const { xAxis, settings, yAxises, limits } = chartConfig;
    const {
      showTitle,
      title,
      stopAnimation,
      stopInteractive,
      showLegend,
      horizontalGrid,
      verticalGrid,
      xFormatter,
      yFormatter,
    } = settings;
    const xAxisRanges = xAxis.useDefault
    ? {
      domainMin: 'dataMin',
      domainMax: 'dataMax',
    }
    : (axisFun =>
      axisFun(dataFromXAxis(data, xAxis.xKey), xAxis))(
        isTimeChart(xAxis.xKey) ? dateTicks : chartRangesFromConfig,
      );
    const xAxisChoice = () => {
      if(xAxis.useDefault) return DefaultAxis;
      return isTimeChart(xAxis.xKey) ? CustomTimeAxis : CustomXAxis;
    }
    return (
      <StyledChart>
        {showTitle && (
          <ChartTitle><MathParser>{title}</MathParser></ChartTitle>
        )}
        <Container showTitle={showTitle}>
          <ResponsiveContainer height="100%" width="100%" >
            <BarChart
              data={data}
            >
              {!stopInteractive && (
                <Tooltip
                  content={
                    <CustomTooltip
                      withTime={xAxis.withTime}
                      xAxis={xAxis}
                      xFormatter={xFormatter}
                      yFormatter={yFormatter}
                    />
                  }
                />
              )}
              {showLegend && (
                CustomLegend()
              )}
              <CartesianGrid
                strokeDasharray="5 5"
                horizontal={horizontalGrid}
                vertical={!xAxisRanges.ticks ? verticalGrid : false}
              />
              {verticalGrid &&
                xAxisRanges.ticks &&
                xAxisRanges.ticks.map((x) => (
                  <ReferenceLine
                    key={`cartesian-x-${x}`}
                    x={x}
                    yAxisId={yAxises[0].id}
                    strokeDasharray="5 5"
                  />
                ))}
              {usedData.map(({ id, ...used }) =>
                CustomBar({
                  yAxises,
                  ...used,
                  lineId: id,
                  key: `usedData-${id}`,
                  ...(stopAnimation && {isAnimationActive: false}),
                }),
              )}
              {limits && limits.map(
                  (limit, id) => CustomLimit({ isFront: true, ...limit, key: id}),
                )}
              {(axisComp =>
                axisComp({
                  ...xAxis,
                  dataKey: xAxis.xKey,
                  hasLeft: hasLeftAxis(yAxises),
                  hasRight: hasRightAxis(yAxises),
                  ranges: xAxisRanges,
                  ...(xFormatter && {tickFormatter: xFormatter}),
                  ...(stopAnimation && {isAnimationActive: false}),
                  // dodanie paddingu dla extra
                  padding: {right: 10, left: 10},
                  ...(extra && {padding: {right: 100}}),
                }))(xAxisChoice())
              }
              {yAxises.map(yAxis =>
                CustomYAxis({
                  ...yAxis,
                  key: `yAxis-${yAxis.id}`,
                  dataSpread: [
                    ...dataFromAxis(data, usedData, yAxis.id, yAxis.maxMin, yAxis.minMax),
                    ...dataFromLimits(limits, yAxis.id),
                  ],
                  ...(yFormatter && {tickFormatter: yFormatter}),
                }),
              )}
            </BarChart>
          </ResponsiveContainer>
          {extra && <Extra>
            {extra}
          </Extra>}
        </Container>
      </StyledChart>
    )
  },
  {
    propTypes: {
      chartConfig: PropTypes.shape(chartConfigType).isRequired,
      usedData: PropTypes.arrayOf(PropTypes.shape(usedDataType)).isRequired,
      data: PropTypes.arrayOf(PropTypes.any),
    },
  },
)

const CustomBar = ({
  yAxises,
  description,
  showDescription,
  lineId,
  axis,
  dotted,
  dashed,
  dashSpacing,
  dashLength,
  dataSource,
  lineColor,
  lineWidth,
  unit,
  ...props
}) => (
    <Bar
      unit={(e => (e && e.unit) || unit)(find(yAxises, e => e.id === axis))}
      name={description}
      lineId={lineId}
      fill={lineColor}
      dataKey={dataSource}
      yAxisId={axis}
      maxBarSize={50}
      {...props}
    />
  );

const Extra = styled.div`
  position: absolute;
  right: 0;
  bottom: 50%;
`;
