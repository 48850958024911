import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import 'antd/dist/antd.css';

import App from './App';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE;

// console.log(process.env.REACT_APP_API_BASE, 'base');
const token = localStorage.getItem('user-token');
if (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

const GlobalStyle = createGlobalStyle`
  ${reset}
`;

const mainApp = (
  <React.Fragment>
    <GlobalStyle />
    <Router>
      <App />
    </Router>
  </React.Fragment>
);

ReactDOM.render(mainApp, document.getElementById('root'));
