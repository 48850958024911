import React from "react";
import styled from "styled-components";
import { Icon } from "antd";

export default ({label, value, unit, loading, ...props}) => (
<InlineValue {...props}>
    <Label>{label}</Label>
    <Value>
        {loading
        ?
        <Icon type="loading" spin />
        :
        value
        }
    </Value>
    {unit && <Unit>{unit}</Unit>}
</InlineValue>);

const InlineValue = styled.div`
    display: flex;
    padding: 0 8px;
    align-items: baseline
`;

const Label = styled.span`
    font-size: 0.9em;
    @media (max-width: 1600px) {
        font-size: 0.85em;
    }
    @media (max-width: 1440px) {
        font-size: 0.75em;
    }
`;

const Value = styled.span`
    margin-left: 6px;
    font-size: 1.2em;
    @media (max-width: 1600px) {
        font-size: 1.1em;
    }
    @media (max-width: 1440px) {
        font-size: 0.95em;
    }
    font-weight: bold;
`;

const Unit = styled.span`
    margin-left: 2px;
    font-size: 0.9em;
    @media (max-width: 1600px) {
        font-size: 0.8em;
    }
    @media (max-width: 1440px) {
        font-size: 0.7em;
    }
`;
