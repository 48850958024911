import React, { Component } from "react";
import PropTypes from "prop-types";
import { filter, zipWith } from "lodash";
import { List } from "antd";

import { MODES } from "../utils/constants";
import { DataType } from "../utils/types";

import DataInputForm from '../../../../components/DataInputForm';
import StyledDrawer from '../../../../components/StyledDrawer';
import { ButtonForList, SetupAdd, SetupList} from '../../../../components/SetupList';

import LoadDataDialog from "../Dialogs/LoadDataDialog";

// TODO przerobic na funkcjonalny
class LoadData extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape(DataType)).isRequired,
    update: PropTypes.func.isRequired,
  };

  state = {
    openedDialog: false,
    editedData: null,
  };

  getIndex = (key) => this.props.data.findIndex(e => e.id === key);

  onCloseDialog = () => {
    this.setState({
      openedDialog: false,
      editedData: null,
    });
  };

  openDialogLoad = () => {
    this.setState({
      openedDialog: true,
    });
  };

  openDialogEdit = key => {
    this.setState({
      openedDialog: true,
      editedData: key,
    });
  };

  newIndex = () =>
    this.props.data.reduce(
      (prev, {id}) => Math.max(prev, id),
      -1,
    ) + 1;

  loadData = newData => {
    const { data, update } = this.props;
    update([...data, {
      id: this.newIndex().toString(),
      ...newData,
     }]);
  };

  updateLoadedData = (key, newData) => {
    const { data, update } = this.props;
    const id = this.getIndex(key);
    if(newData.data.length !== data[id].data.length) {
      throw Error('Fetched data of different length.');
    }
    const zipped = zipWith(newData.data, data[id].data, (a, b) => ({...b, ...a}));
    const result = [...data];
    result[id] = {
      id: key,
      fields: newData.fields,
      data: zipped,
    };
    update(result);
  }

  removeData = key => {
    const { data, update } = this.props;
    update(filter(data, e => e.id !== key));
  };

  updateData = (key, value) => {
    const { data, update } = this.props;
    const result = [...data];
    result[this.getIndex(key)] = value;
    update(result);
  };

  render() {
    const isEdit = this.state.editedData !== null;
    const { data, now } = this.props;
    return (
      <DataInputForm>
        <SetupList>
          {data.map(({id, fields}) => (
            <List.Item
              key={id}
              actions={[
                <ButtonForList onClick={() => this.openDialogEdit(id)}>edytuj</ButtonForList>,
                <ButtonForList onClick={() => this.removeData(id)}>usuń</ButtonForList>,
              ]}
            >
              <List.Item.Meta title={fields.description} />
            </List.Item>
          ))}
        </SetupList>
        <SetupAdd
          description="Załaduj dane"
          icon="download"
          onClick={this.openDialogLoad}
        />
        <StyledDrawer
          title={isEdit ? "Edycja zestawu danych" : "Dodaj nowy zestaw danych"}
          placement="left"
          onClose={this.onCloseDialog}
          visible={this.state.openedDialog}
          width={480}
        >
          <LoadDataDialog
            mode={isEdit ? MODES.EDIT : MODES.LOAD}
            now={now}
            onClose={this.onCloseDialog}
            editedData={isEdit ? data[this.getIndex(this.state.editedData)] : {}}
            loadData={isEdit ?
              (value) => this.updateLoadedData(this.state.editedData, value)
              : this.loadData}
            updateData={value =>
              this.updateData(this.state.editedData, value)
            }
          />
        </StyledDrawer>
      </DataInputForm>
    );
  }
}

export default LoadData;
