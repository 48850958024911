import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { sortBy } from "lodash";
import {
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import { Container, ChartTitle } from "./Common";
import { PieTooltip } from "./CustomTooltip";
import MathParser from '../MathParser';

const RADIAN = Math.PI / 180;
                   
const customLabel = ({ cx, cy, midAngle, outerRadius, fill, payload, percent, value }) => {
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 6) * cos;
  const sy = cy + (outerRadius + 6) * sin;
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 14;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{payload.shortcut}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(1)}%)`}
      </text>
    </g>
  );
};

const CustomInner = ({viewBox, innerContent, innerRadius}) => {
  const percentage = parseInt(innerRadius, 10);
  const R = Math.min(viewBox.cx, viewBox.cy) * percentage / 100;
  return (
    <g transform={`translate(${viewBox.cx - R}, ${viewBox.cy - R})`}>
      <foreignObject width={2*R} height={2*R}>
        <FullDiv>
          <InnerDiv>
            {innerContent}
          </InnerDiv>
        </FullDiv>
      </foreignObject>
    </g>
  );
}
  

const FullDiv = styled.div`
position: relative;
height: 100%;
width: 100%;
border-radius: 50%;
`;

const InnerDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
`;

const getColor = ({color, key, value}, idx, colorMapper) =>
  colorMapper
  ? colorMapper({key, value, color, idx})
  : color;

const prepareData = ({data, descriptionMapper, shortcutMapper, showEmpty, descriptionKey, ...rest}) => {
  const sum = data.reduce((s, {value}) => s + value, 0);
  return sortBy(
    data.filter(({value}) => !showEmpty || value).map(
      e => ({
        ...e,
        description: descriptionMapper ? descriptionMapper(e[descriptionKey]) : e[descriptionKey],
        shortcut: shortcutMapper ? shortcutMapper(e[descriptionKey]) : e[descriptionKey],
        percentage: sum > 0 ? e.value / sum : 0,
        ...rest,
      })),
    [e => -e.value],
  );
}

export default Object.assign(
({
  data,
  unit,
  showEmpty,
  showLabel = true,
  showLegend,
  showTitle,
  title,
  descriptionMapper,
  shortcutMapper,
  showTooltip,
  colorMapper,
  innerContent,
  innerRadius = "32%",
  outerRadius = "48%",
  descriptionKey = "key",
  dataFormatter,
  ...props
}) => {
  const nData = prepareData({
    data,
    descriptionMapper,
    shortcutMapper,
    showEmpty,
    unit,
    descriptionKey,
  });
  return (
  <StyledPieChart>
    {showTitle && (
      <ChartTitle>
        <MathParser>{title}</MathParser>
      </ChartTitle>
    )}
  <Container showTitle={showTitle} legendItems={showLegend ? (nData || []).length : 0}>
    <ResponsiveContainer height="100%" width="100%">
      <PieChart>
        {showTooltip &&
          <Tooltip
            content={
              <PieTooltip dataFormatter={dataFormatter}/>
            }
        />}
        <Pie
            startAngle={-270}
            endAngle={90}
            data={nData}
            {...(innerContent && {innerRadius})}
            outerRadius={outerRadius} 
            label={showLabel ? customLabel : undefined}
            dataKey="value"
            minAngle={2}
            {...props}
        >
            {
              nData.map((e, idx) => <Cell key={`cell-${e[descriptionKey]}`} fill={getColor(e, idx, colorMapper)}/>)
            }
            {
              innerContent &&
                <Label content={<CustomInner innerContent={innerContent} innerRadius={innerRadius}/>} />
            }
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  </Container>
  {showLegend && (
    <CustomPieLegend
      data={nData}
      colorMapper={colorMapper}
    />
  )}
</StyledPieChart>);
},
{
    propTypes: {
        data: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            color: PropTypes.string,
        })),
    },
},
)

const StyledPieChart = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .recharts-layer {
    font-size: 14px;
    @media (max-width: 1600px) {
        font-size: 13px;
    }
    @media (max-width: 1440px) {
        font-size: 12px;
    }
    @media (max-width: 1366px) {
      font-size: 11px;
    }
  }
`;

const CustomPieLegend = ({data, colorMapper}) => (
  <StyledLegend>
    <LegendWrapper>
    {data.map((e, idx) => (
      <LegendItem
        key={e.key}
        color={getColor(e, idx, colorMapper)}
    >{`${e.description} - (${(e.percentage * 100).toFixed(1)}%)`}</LegendItem>))}
    </LegendWrapper>
  </StyledLegend>
)

const LegendItem = styled.div`
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background: ${({color}) => color};
    margin-right: 16px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
  }

  & + & {
    @media (max-width: 1440px) {
      margin-top: 4px;
    }
  }
`;

const StyledLegend = styled.div`
  font-size: 12px;
  line-height: 12px;
  @media (max-width: 1440px) {
    font-size: 11px;
    line-height: 11px;
  }
  display: flex;
`;

const LegendWrapper = styled.div`
  margin: auto;
`;
