import PropTypes from 'prop-types';

import { withDescriptionType } from '../../../../../../components/Charts/utils/chartTypes';

export const xAxisType = Object.freeze({
  ...withDescriptionType,
  color: PropTypes.string.isRequired,
  datesNumber: PropTypes.number,
  withTime: PropTypes.bool,
  xKey: PropTypes.string.isRequired,
  unit: PropTypes.string,
  rangeFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rangeTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rangeSpan: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const yAxisType = Object.freeze({
  color: PropTypes.string.isRequired,
  showAxis: true,
  side: PropTypes.oneOf(["left", "right"]),
  ...withDescriptionType,
  unit: PropTypes.string,
  rangeFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rangeTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rangeSpan: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
})

export const settingsType = Object.freeze({
  title: PropTypes.string.isRequired,
  showTitle: PropTypes.bool.isRequired,
  showLegend: PropTypes.bool.isRequired,
  horizontalGrid: PropTypes.bool.isRequired,
  verticalGrid: PropTypes.bool.isRequired,
})

export const ConfigType = Object.freeze({
  settings: PropTypes.shape({...settingsType}).isRequired,
  xAxis: PropTypes.shape({
    ...xAxisType,
  }).isRequired,
  yAxises: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    fields: PropTypes.shape({...yAxisType}).isRequired,
  }).isRequired).isRequired,
});

export const EditDataType = Object.freeze({
  building: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  user: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string.isRequired,
  measurements: PropTypes.arrayOf(PropTypes.string).isRequired,
  periodType: PropTypes.string.isRequired,
});

export const DataType = Object.freeze({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fields: PropTypes.shape({...EditDataType}).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    time: PropTypes.number.isRequired,
  })),
});

export const EditUsedDataType = Object.freeze({
  description: PropTypes.string.isRequired,
  axis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showRegresion: PropTypes.bool,
  dotted: PropTypes.bool,
  lineWidth: PropTypes.number,
  lineColor: PropTypes.string.isRequired,
  dashed: PropTypes.bool,
  dashLength: PropTypes.number,
  dashSpacing: PropTypes.number,
});

export const UsedDataType = Object.freeze({
  dataSource: PropTypes.string.isRequired,
  fields: PropTypes.shape({...EditUsedDataType}).isRequired,
  show: PropTypes.bool.isRequired,
});
