import { round } from 'lodash';

export const extendAlarms = (alarms, alarmTypes) =>
  alarms.map(alarm => ({
    ...alarm,
    key: alarm.id,
    reading: withUnit(round(alarm.reading, 2), alarm.alarmTypeId),
    threshold: withUnit(alarm.threshold, alarm.alarmTypeId),
    alarmType: alarmTypes.find(alarmType => alarmType.id === alarm.alarmTypeId)
      .name,
  }));

const withUnit = (value, alarmTypeId) => `${value} ${getUnit(alarmTypeId)}`;

export const getUnit = alarmTypeId => {
  switch (alarmTypeId) {
    case 1:
    case 2:
      return '°C';
    case 3:
    case 4:
      return 'kW';
    case 5:
      return '%';
    default:
      return '';
  }
};
