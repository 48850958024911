import styled from 'styled-components';
import { Form } from 'antd';

export const StyledFrom = styled(Form)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const FormInner = styled.div`
    flex: 1
`;

export const FormFooter = styled.div`
    margin-top: auto;
    border-top: 1px solid #e9e9e9;
    padding: 16px 24px 0 24px;
    text-align: right;
    margin-left: -24px;
    margin-right: -24px;
`;
