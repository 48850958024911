import React from 'react';
import styled from 'styled-components';

import Page from './Page';
import PageHeading from './PageHeading';
import List from './List';

export default ({buildings}) => (
    <Page>
        <PageHeading id="Navigation" to="Navigation">
            Spis treści
        </PageHeading>
        <List>
            <List.Item>
                <CustomLink href="#ReportObject">Przedmiot raportu</CustomLink>
            </List.Item>
            <List.Item>
                <CustomLink href="#ReportConclusion">Wnioski</CustomLink>
            </List.Item>
            <List.Item>
                <CustomLink href="#AllBuildings">Analiza dla wszystkich budynków</CustomLink>
            </List.Item>
            <List
                title={<CustomLink href="#AllMeasuredBuildings">Analiza dla budynków opomiarowanych</CustomLink>}
            >
                {buildings.map(({buildingId, fullName}) => (
                    <List.Item key={buildingId}>
                        <CustomLink href={`#${genReportLink(buildingId)}`}>{fullName}</CustomLink>
                    </List.Item>
                ))}
            </List>
        </List>
    </Page>);

export const genReportLink = (id) => `Building_${id}`;

const CustomLink = styled.a`
    text-decoration: none;
    color: darkblue;
`;
