import React, { useContext, useState } from 'react';
import { Select, Input, Button, Spin, Row, Col, message } from 'antd';

import { AppContext } from '../../../../AppDataProvider';
import { AdministrationContext } from '../../AdministrationDataProvider';
import {
  postNewLabelName,
  postLabelNameRemoval,
  postLabelNameUpdate,
} from '../../services/api';

import { labelTypes, labelTypesIds } from './labelTypes';

const Header = () => (
  <Row gutter={16}>
    <Col span={2} style={{ textAlign: 'right' }}>
      Id
    </Col>
    <Col span={8}>Typ</Col>
    <Col span={9}>Nazwa</Col>
    <Col span={5} />
  </Row>
);

const Footer = ({ pushEmptyLabel, closeModal }) => (
  <>
    <Row gutter={16} style={{ marginTop: '8px', marginBottom: '8px' }}>
      <Col span={19} />
      <Col span={5}>
        <Button
          type="primary"
          style={{ width: '100%' }}
          onClick={pushEmptyLabel}
        >
          Dodaj nowy
        </Button>
      </Col>
    </Row>
    <Row gutter={16} style={{ marginTop: '36px' }}>
      <Col span={19} />
      <Col span={5}>
        <Button type="primary" style={{ width: '100%' }} onClick={closeModal}>
          Ok
        </Button>
      </Col>
    </Row>
  </>
);

const MeterLabels = ({ buildingId, closeModal }) => {
  const { mode, buildings } = useContext(AdministrationContext);
  const currentBuilding = buildings.find(
    building => building.id === buildingId,
  );
  const buildingMetersLabels = currentBuilding
    ? labelTypesIds.flatMap(labelTypeId =>
        currentBuilding[labelTypeId].map(label => ({
          ...label,
          typeId: labelTypeId,
        })),
      )
    : [];
  const [localLabels, setLocalLabels] = useState(buildingMetersLabels);
  const pushEmptyLabel = () => {
    const emptyLabel = {
      typeId: undefined,
      name: '',
    };
    setLocalLabels([...localLabels, emptyLabel]);
  };
  return (
    <div>
      <Header />

      {localLabels.map((label, idx) => (
        <SingleLabel
          label={label}
          buildingId={buildingId}
          mode={mode}
          disabled={label.typeId !== undefined}
          key={`${label.typeId || idx}_${label.id || idx}`}
        />
      ))}
      {mode !== 'read' && (
        <Footer pushEmptyLabel={pushEmptyLabel} closeModal={closeModal} />
      )}
    </div>
  );
};

const SingleLabel = ({ label, buildingId, disabled, mode }) => {
  const { wrapRequest } = useContext(AppContext);

  const [isDeleted, setIsDeleted] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [typeId, setTypeId] = useState(label.typeId);
  const [name, setName] = useState(label.name);
  const [id, setId] = useState(label.id);
  const [isUpdating, setIsUpdating] = useState(false);

  if (isDeleted) {
    return null;
  }

  const handleSelect = id => setTypeId(id);
  const handleType = e => setName(e.target.value);

  const handleDelete = async () => {
    setIsLoading(true);
    await wrapRequest(() => postLabelNameRemoval(id, typeId), {
      onError: () => {
        message.error(
          'Nie udało się usunąć etykiety. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.',
        );
      },
      onSuccess: () => {
        setIsDeleted(true);
      },
    });
    setIsLoading(false);
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    await wrapRequest(() => postLabelNameUpdate(id, name, buildingId, typeId), {
      onError: () => {
        message.error(
          'Nie udało się edytować etykiety. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.',
        );
      },
      onSuccess: () => {},
    });
    setIsLoading(false);
  };
  const handleUpdateStart = () => setIsUpdating(true);
  const handleUpdateSave = () => {
    setIsUpdating(false);
    handleUpdate();
  };

  const handleAdd = async () => {
    setIsLoading(true);
    await wrapRequest(() => postNewLabelName(name, buildingId, typeId), {
      onError: () => {
        message.error(
          'Nie udało się dodać etykiety. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.',
        );
      },
      onSuccess: data => {
        setId(data.id);
      },
    });
    setIsLoading(false);
  };

  const labelTypeOptions = labelTypes.map(labelType => (
    <Select.Option key={labelType.id} value={labelType.id}>
      {labelType.name}
    </Select.Option>
  ));

  const typeDisabled = !!id;
  const canEditName = isUpdating || !id;

  return (
    <Row gutter={16} style={{ marginTop: '8px', marginBottom: '8px' }}>
      <Col
        span={2}
        style={{
          textAlign: 'right',
          lineHeight: '32px',
        }}
      >
        {id}
      </Col>
      <Col span={6}>
        <Select
          style={{ width: '100%' }}
          onChange={handleSelect}
          value={typeId}
          disabled={typeDisabled}
        >
          {labelTypeOptions}
        </Select>
      </Col>
      <Col span={8}>
        <Input
          style={{ width: '100%' }}
          onChange={handleType}
          value={name}
          disabled={!canEditName}
          placeholder="Podaj nazwę"
        />
      </Col>
      <Col span={8}>
        {isLoading && <Spin />}
        {id && !isLoading && mode !== 'read' && (
          <Button
            style={{ width: '40%', marginRight: 5 }}
            onClick={handleDelete}
            type="danger"
          >
            Usuń
          </Button>
        )}
        {id && !isLoading && mode === 'update' && (
          <Button
            style={{ width: '40%' }}
            onClick={isUpdating ? handleUpdateSave : handleUpdateStart}
          >
            {isUpdating ? 'Zapisz' : 'Edytuj'}
          </Button>
        )}
        {!id && !isLoading && mode !== 'read' && (
          <Button
            style={{ width: '100%' }}
            onClick={handleAdd}
            disabled={disabled}
          >
            Dodaj
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default MeterLabels;
