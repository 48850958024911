import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Checkbox, Form, Row, Col, Input, Button } from "antd";

import { yAxisType } from "../utils/types";
import { MODES } from "../utils/constants";

import FormItemWrapper, { validate } from "../../../../../../components/FormItemWrapper";
import { FormFooter, FormInner, StyledFrom } from "../../../../components/StyledForm";
import AxisSideSelect from "../../../../components/AxisSideSelect";

import { withDelayedUpdate } from "../../../../../../utils/delayed";

const DelayedInput = withDelayedUpdate(Input);

const AxisForm = Object.assign(
({mode, editedData, onClose, loadData, updateData}) => {
  const isEdit = mode === MODES.EDIT;

  const [triggered, setTriggered] = useState(false);
  const [_value, setValue] = useState(editedData);

  const formSettings = {
    isEdit,
    triggered,
  };

  useEffect(() => {
    setValue(editedData);
  }, [editedData]);

  const updateWithValid = ((data, isValid) =>
    isValid ? updateData({...editedData, ...data}): setValue({..._value, ...data}));

  const update = (key) => ((data, isValid) => updateWithValid({[key]: data}, isValid));

  const onReset = () => {
    setTriggered(false);
    if(isEdit) {
      setValue(editedData);
    }
    else {
      updateData(genDefaultData());
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const toValidate = [
      'description',
      'side',
      'unit',
      'rangeFrom',
      'rangeTo',
      'rangeSpan',
    ];
    const isValid = toValidate.map((key) => validate({
      ...setupSettings[key],
      value: _value[key],
      rules: setupSettings[key] ? (setupSettings[key].rules || []) : [],
    })).reduce((prev, {validateStatus}) => prev && validateStatus !== 'error', true);
    if(!isValid) {
      setTriggered(true);
    }
    else {
      if(isEdit) {
        updateData(_value);
      } else {
        loadData(_value);
      }
      onClose();
      onReset();
    }
  }

  return (
    <StyledFrom
      layout="vertical"
      onSubmit={onSubmit}
      hideRequiredMark
    >
      <FormInner>
        <FormItemWrapper
          value={_value.showAxis}
          valueKey="checked"
          onChange={update('showAxis')}
          {...formSettings}
          {...setupSettings.showAxis}
        >
          <Checkbox>Pokaż oś na wykresie</Checkbox>
        </FormItemWrapper>
        <FormItemWrapper
          value={_value.description}
          onChange={update('description')}
          {...formSettings}
          {...setupSettings.description}
        >
          <DelayedInput placeholder="Opis osi" />
        </FormItemWrapper>
        <FormItemWrapper
          value={_value.showDescription}
          valueKey="checked"
          onChange={update('showDescription')}
          {...formSettings}
          {...setupSettings.showDescription}
        >
          <Checkbox>Pokaż nazwę osi na wykresie</Checkbox>
        </FormItemWrapper>
        <FormItemWrapper
          value={_value.side}
          onChange={update('side')}
          {...formSettings}
          {...setupSettings.side}
        >
          <AxisSideSelect />
        </FormItemWrapper>
        <FormItemWrapper
          value={_value.unit}
          onChange={update('unit')}
          {...formSettings}
          {...setupSettings.unit}
        >
          <DelayedInput placeholder="Jednostka" />
        </FormItemWrapper>
        <Form.Item
          label="Zarządzanie zakresem danych"
          help="Aby ustawić automatyczne wyliczanie, wpisz auto"
        >
          <Row gutter={24}>
            <Col span={12}>
              <FormItemWrapper
                value={_value.rangeFrom}
                onChange={update('rangeFrom')}
                {...formSettings}
                {...setupSettings.rangeFrom}
              >
                <DelayedInput placeholder="Początek zakresu" />
              </FormItemWrapper>
            </Col>
            <Col span={12}>
                <FormItemWrapper
                  value={_value.rangeTo}
                  onChange={update('rangeTo')}
                  {...formSettings}
                  {...setupSettings.rangeTo}
                >
                  <DelayedInput placeholder="Koniec zakresu" />
                </FormItemWrapper>
            </Col>
            <Col span={12}>
                <FormItemWrapper
                  value={_value.rangeSpan}
                  onChange={update('rangeSpan')}
                  {...formSettings}
                  {...setupSettings.rangeSpan}
                >
                  <DelayedInput placeholder="Krok" />
                </FormItemWrapper>
            </Col>
          </Row>
        </Form.Item>
      </FormInner>
      <FormFooter>
        <Button
          onClick={onReset}
          style={{ marginRight: 16 }}
        >
          Zresetuj
        </Button>
        <Button
          type="primary"
          icon={isEdit ? "redo" : "plus"}
          htmlType="submit"
        >
          {isEdit ? "Aktualizuj" : "Dodaj oś"}
        </Button>
      </FormFooter>
    </StyledFrom>
  );
},
{
  propTypes: {
    mode: PropTypes.oneOf([MODES.EDIT, MODES.LOAD]).isRequired,
    editedData: PropTypes.shape(yAxisType).isRequired,
    onClose: PropTypes.func.isRequired,
    loadData: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
  },
});

const setupSettings = {
  showAxis: {},
  description: {
    label: "Opis osi",
    required: true,
    rules: [
      {
        validate: (v) => v.length >= 3,
        errorMessage: 'Wymagane min. 3 znaki',
      },
      {
        validate: (v) => v.length <= 20,
        errorMessage: 'Maksymalnie 20 znaków',
      },
    ],
  },
  showDescription: {},
  side: {
    label: "Miejsce wyświetlania",
    required: true,
  },
  unit: {
    label: "Jednostka",
    required: false,
    rules: [
      {
        validate: (v) => v.length <= 10,
        errorMessage: 'Maksymalnie 10 znaków',
      },
    ],
  },
  rangeFrom: {
    label: "Od",
    required: true,
  },
  rangeTo: {
    label: "Do",
    required: true,
  },
  rangeSpan: {
    label: "Krok",
    required: true,
  },
};

const genDefaultData = () => ({
  color: "#333",
  showAxis: true,
  side: "left",
  description: "",
  showDescription: false,
  unit: "",
  rangeFrom: "auto",
  rangeTo: "auto",
  rangeSpan: "auto",
});

export default Object.assign(
({mode, editedAxis, onClose, loadData, updateData}) => {
  const isEdit = mode === MODES.EDIT;
  const [newData, updateNewData] = useState(genDefaultData());

  return (
    <AxisForm
      mode={mode}
      onClose={onClose}
      editedData={isEdit ? editedAxis.fields : newData }
      updateData={isEdit ? updateData : updateNewData }
      loadData={loadData}
    />
  );
},
{
  propTypes: {
    mode: PropTypes.oneOf(["load", "edit"]).isRequired,
    editedAxis: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fields: PropTypes.shape({...yAxisType}),
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    loadData: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
  },
});
