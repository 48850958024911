export const MODES = Object.freeze({
  EDIT: "edit",
  LOAD: "load",
});

export const extractDataSource = path => {
  const tab = path.split("-");
  return tab[0];
};

// Zwraca (0)id, (1)measurement
export const extractEntry = path => {
  return path.split("-");
}

export const generateDataSource = (id, measurement) => `${id}-${measurement}`;

export const generateDataDescription = (description, measurement) =>
  `${description} - ${measurement}`;

export const generateDataSourcesSet = ({id, fields}) =>
  fields.measurements.map( (m, idx) => ({
    dataSource: generateDataSource(id, m),
    description: generateDataDescription(fields.description, fields.measurementsLabels[idx]),
  }));

export const NOT_LOADED_DATA_REASONS = Object.freeze({
  AXISKEY: 'axiskey',
  NOAXISDATA: 'noaxisdata',
});

export const formatReason = (reason) => ({
  [NOT_LOADED_DATA_REASONS.AXISKEY]: 'Dane odłożone na osi X',
  [NOT_LOADED_DATA_REASONS.NOAXISDATA]: 'Brak pobranych danych odpowiadających pomiarowi wartości z osi X',
})[reason];

export const formatReasonSymbol = (reason) => ({
  [NOT_LOADED_DATA_REASONS.AXISKEY]: 'check',
  [NOT_LOADED_DATA_REASONS.NOAXISDATA]: 'warning',
})[reason];

export const formatReasonColor = (reason) => ({
  [NOT_LOADED_DATA_REASONS.AXISKEY]: '#52c41a',
  [NOT_LOADED_DATA_REASONS.NOAXISDATA]: '#faad14',
})[reason];

export const getDisableReason = (dataSource, loadedData, xKey) => {
  const [id, usedKey] = extractEntry(dataSource);
  const { fields } = loadedData.find(load => load.id === id);
  if(xKey === usedKey) {
    return NOT_LOADED_DATA_REASONS.AXISKEY;
  }
  if(![...fields.measurements, 'time'].includes(xKey)) {
    return NOT_LOADED_DATA_REASONS.NOAXISDATA;
  }
  return null;
}
