import React from 'react';
import styled from 'styled-components';
import { isNil } from 'lodash';

import Loading from '../../../components/Loading';
import MathParser from '../../../components/MathParser';
import { formatNumber } from '../../../utils/formaters';

export default ({ data = {}, loading }) => {
  const {
    minValue = 0,
    usageInSelectedBuilding: value,
    avgUsageInUsersBuilings: avg,
  } = data;
  const factor = value / (2 * avg);
  const offset = `${Math.max(Math.min(factor, 1), 0) * 100}%`;

  return (
    <Container>
      <InnerContent>
        {(loading || isNil(value) || isNil(value)) ? (
          <Loading color="blue" size="20"/>
        ) : (
          <CompareUsageContainer>
            <IndicatorContainer>
              <Indicator offset={offset}>
                <div>{formatNumber(value, {position: -1})}</div>
                <Arrow />
              </Indicator>
            </IndicatorContainer>
            <GradientBar />
            <LabelsContainer>
              <div>{formatNumber(minValue, {position: 0})}</div>
              <div>{formatNumber(2 * avg, {position: 0})}</div>
            </LabelsContainer>
            <Title>
              <MathParser>
                Jednostkowy wskaźnik zużycia energii [kWh/m^2/rok]
              </MathParser>
            </Title>
          </CompareUsageContainer>
        )}
      </InnerContent>
    </Container>
  );
};

const CompareUsageContainer = styled.div`
  position: relative;
`;

const IndicatorContainer = styled.div`
  position: relative;
  padding: 0 0 3px;
  height: 36px;
  width: 100%;
  @media (max-width: 1600px) {
    height: 34px;
  }
  @media (max-width: 1440px) {
    height: 32px;
  }
  @media (max-width: 1366px) {
    height: 30px;
  }
`;

const Indicator = styled.div`
  position: absolute;
  left: ${p => p.offset};
  top: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  transform: translateX(-50%);
  font-size: 14px;
  @media (max-width: 1600px) {
    font-size: 13px;
  }
  @media (max-width: 1440px) {
    font-size: 12px;
  }
  @media (max-width: 1366px) {
    font-size: 10px;
  }
`;

const Arrow = styled.div`
  background: #333;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
  width: 10px;
  height: 14px;
  @media (max-width: 1600px) {
    height: 13px;
  }
  @media (max-width: 1440px) {
    height: 12px;
    width: 9px;
  }
  @media (max-width: 1366px) {
    height: 10px;
    width: 8px;
  }
`;

const GradientBar = styled.div`
  height: 15px;
  width: 100%;
  background: linear-gradient(to right, hsl(120,60%,50%) 0%, hsl(110.2,63.64%,50.46%) 10%, hsl(100.33,67.27%,50.93%) 20%, hsl(90.49,70.9%,51.39%) 30%, hsl(80.65,74.55%,51.85%) 40%, hsl(70.81,78.18%,52.32%) 50%, hsl(60.98,81.82%,52.78%) 60%, hsl(51.14,85.45%,53.24%) 70%, hsl(41.3,89.1%,53.71%) 80%, hsl(31.46,92.73%,54.17%) 90%, hsl(21.63,96.36%,54.63%) 100%);
`;

const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
`;

const Title = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  @media (max-width: 1600px) {
    font-size: 13px;
    line-height: 15px;
  }
  @media (max-width: 1440px) {
    font-size: 12px;
    line-height: 14px;
  }
  @media (max-width: 1366px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

const InnerContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 4px;
`;

const Container = styled.div`
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  position: relative;
  padding-top: calc(50% - 2px);
  background: #F8F8F8;
  margin-top: 8px;
  @media (max-width: 1440px) {
    padding-top: calc(50% + 4px);
  }
  @media (max-height: 800px) {
    margin-bottom: 4px;
  }
`;
