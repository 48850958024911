import React from 'react';
import {Label, ReferenceLine} from 'recharts';

export default ({
    description,
    axis,
    dashSpacing,
    dashLength,
    lineColor,
    lineWidth,
    value,
    ...props
  }) => (
    <ReferenceLine
      ifOverflow="extendDomain"
      yAxisId={axis}
      y={value}
      stroke={lineColor}
      strokeDasharray={`${dashLength} ${dashSpacing}`}
      strokeWidth={lineWidth}
      {...props}
    >
      <Label position="top" fill={lineColor}>
        {description}
      </Label>
    </ReferenceLine>
  );
