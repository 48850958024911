import React, { useState, useContext } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { Button, Select, DatePicker, Form } from 'antd';

import { AppContext } from '../../../../AppDataProvider';

const { Option } = Select;
const { MonthPicker } = DatePicker;

const BUILDING_FIELDS = [
  {
    field: 'FullName',
    label: 'Pełna nazwa',
  },
  {
    field: 'ShortName',
    label: 'Krótka nazwa',
  },
  {
    field: 'Street',
    label: 'Ulica',
  },
  {
    field: 'City',
    label: 'Miasto',
  },
  {
    field: 'PostOffice',
    label: 'Poczta',
  },
  {
    field: 'Area',
    label: 'Powierzchnia',
  },
  {
    field: 'OrderedElectricPower',
    label: 'Zamówiona moc elekt.',
  },
  {
    field: 'OrderedHeatPower',
    label: 'Zamówiona moc cieplna',
  },
  {
    field: 'RenewableEnergySourcesPower',
    label: 'Źródła energii odn.',
  },
  {
    field: 'HasMetersInstalled',
    label: 'Opomiarowany',
  },
  {
    field: 'ManagerEmail',
    label: 'Email kierownika',
  },
  {
    field: 'CategoryId',
    label: 'Kategoria',
  },
];

const DataExport = () => {
  const [buildingsIds, setBuildingsIds] = useState([]);
  const handleBuildingSelect = values => setBuildingsIds(values);

  const [buildingsFields, setBuildingsFields] = useState([]);
  const handleBuildingFieldSelect = values => setBuildingsFields(values);

  const [hookUpTypesIds, setHookUpTypesIds] = useState([]);
  const handleHookUpTypeSelect = values => setHookUpTypesIds(values);

  const { buildings, enums } = useContext(AppContext);

  const buildingOptions = buildings.map(building => (
    <Option key={building.id}>{building.shortName}</Option>
  ));

  const buildingFieldOptions = BUILDING_FIELDS.map(entry => (
    <Option key={entry.field}>{entry.label}</Option>
  ));

  const invoiceOptions = enums.hookUpTypes.map(hookUpType => (
    <Option key={hookUpType.id}>{hookUpType.name}</Option>
  ));

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const handleStartMonthSelect = value => setStartDate(value);
  const handleEndMonthSelect = value => setEndDate(value);
  const disabledStartDate = newStartDate => {
    if (!newStartDate || !endDate) {
      return false;
    }
    return newStartDate.valueOf() > endDate.valueOf();
  };
  const disabledEndDate = newEndDate => {
    if (!newEndDate || !startDate) {
      return false;
    }
    return newEndDate.valueOf() <= startDate.valueOf();
  };

  const isFormValid = buildingsIds.length > 0 && startDate && endDate;

  const handleExport = async () => {
    const request = {
      buildingsIds,
      buildingsFields,
      hookUpTypesIds,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    }
    const response = await axios.post('/DataExport/export', request, { responseType: 'blob' });
    saveAs(response.data, `Eksport danych.xlsx`);
  };

  return (
    <Form>
      <Form.Item label="Wybrane budynki">
        <Select
          mode="multiple"
          style={{ width: 500}}
          placeholder="Wybierz budynek (budynki)"
          onChange={handleBuildingSelect}
        >
          {buildingOptions}
        </Select>
      </Form.Item>
      <Form.Item label="Dane o budynkach">
        <Select
          style={{ width: 500}}
          mode="multiple"
          placeholder="Wybierz dane o budynakch"
          onChange={handleBuildingFieldSelect}
        >
          {buildingFieldOptions}
        </Select>
      </Form.Item>
      <Form.Item label="Rodzaje faktur">
        <Select
          style={{ width: 500}}
          mode="multiple"
          placeholder="Wybierz rodzaj/e faktur"
          onChange={handleHookUpTypeSelect}
        >
          {invoiceOptions}
        </Select>
      </Form.Item>
      <Form.Item label="Zakres dat">
        <MonthPicker
          disabledDate={disabledStartDate}
          value={startDate}
          onChange={handleStartMonthSelect}
          style={{ marginRight: 32 }}
          format="MM-YYYY"
          placeholder="Początkowy miesiąc"
        />
        <MonthPicker
          disabledDate={disabledEndDate}
          value={endDate}
          onChange={handleEndMonthSelect}
          format="MM-YYYY"
          placeholder="Końcowy miesiąc"
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={handleExport} disabled={!isFormValid}>
          Eksportuj
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DataExport;
