import { range } from "lodash";
import moment from "moment";
import { filterDataByKeys } from "./filters";

const AUTO = "auto";

// TODO - blad, gdy dane na osi są stałe
// TODO - blad, gdy withTime + za mało jest danych na wymaganą liczbę ticków

const autoRanges = [
  {
    limit: 2,
    span: 0.5,
  },
  {
    limit: 4,
    span: 1,
  },
  {
    limit: 7.5,
    span: 2,
  },
  {
    limit: 10,
    span: 2.5,
  },
];
const minPower = -1;
const maxTickNumber = 16;

const calculatePower = ({ min, max }) => {
  const scope = max - min;
  const logRange = Math.max(Math.floor(Math.log10(scope)), minPower);
  return 10**logRange;
};

const calculateSpan = ({ min, max }) => {
  const power = calculatePower({ min, max });
  const digit = (max - min) / power;
  let i = 0;
  while (digit > autoRanges[i].limit && i + 1 < autoRanges.length) {
    i += 1;
  }
  return power * autoRanges[i].span;
};

const minTick = ({ min }, span) => span * Math.floor(min / span);
const maxTick = ({ max }, span) => span * Math.ceil(max / span);
const minValue = minTick;
const maxValue = maxTick;

const formatRange = (scope, auto) =>
  scope === AUTO ? auto : Number.parseFloat(scope);

const getRangeFromData = values => ({
  min: Math.min(...values.filter(e => e !== undefined)),
  max: Math.max(...values.filter(e => e !== undefined)),
});

const getTicks = ({ min, max }, span) => range(min, max + span / 2, span);

export const dateTicks = (data, { datesNumber, withTime }) => {
  if(data.length === 0) return {};
  const { min, max } = getRangeFromData(data);
  const start = moment.unix(min / 1000);
  const end = moment.unix(max / 1000);
  const duration = moment.duration(end.diff(start));
  const periods = withTime ? duration.asHours() : duration.asDays();
  const number = Math.min(periods, datesNumber - 1);
  if(Number.isNaN(number)) return {};
  if(number <= 0) return {};
  const span = Math.floor(periods / number);
  const result = [];
  const iter = moment(start);
  while (!iter.isAfter(end)) {
    result.push(iter.format("x"));
    iter.add(span, withTime ? "hours" : "day");
  }
  return {
    domainMin: min,
    domainMax: max,
    ticks: result,
  };
};

const normalizeInput = (input) => {
  if(input !== 'auto' && Number.isNaN(parseFloat(input))) {
    return 'auto';
  }
  return input;
}

export const chartRangesFromConfig = (
  data,
  { rangeFrom, rangeTo, rangeSpan },
) => {
  let autoRangeSpan = normalizeInput(rangeSpan);
  const autoRangeFrom = normalizeInput(rangeFrom);
  const autoRangeTo = normalizeInput(rangeTo);
  if(data.length === 0) return {};
  const { min, max } = getRangeFromData(data);
  let from = formatRange(autoRangeFrom, min);
  let to = formatRange(autoRangeTo, max);
  if( to < from) {
    const mem = from;
    from = to;
    to = mem;
  }
  let scope = {
    min: from,
    max: to,
  };
  if(rangeSpan !== 'auto' && (
      (rangeSpan <= 0) ||
      (rangeSpan * (maxTickNumber - 1) <=  (to - from))
    )) {
    autoRangeSpan = 'auto';
  }
  const span = formatRange(autoRangeSpan, calculateSpan(scope));
  if(Number.isNaN(span)) return {};
  if(span <= 0) return {}
  if(to - from < span) {
    to += span;
    from -= span;
    scope = {
      min: from,
      max: to,
    };
  }
  return {
    domainMin: minValue(scope, span),
    domainMax: maxValue(scope, span),
    ticks: getTicks(
      {
        min: minTick(scope, span),
        max: maxTick(scope, span),
      },
      span,
    ),
  };
};

const regresionData = (data, xKey, yKey) => {
  const n = data.length;
  let { sx, sy } = data.reduce(
    (prev, d) => ({
      sx: prev.sx + d[xKey],
      sy: prev.sy + d[yKey],
    }),
    {
      sx: 0,
      sy: 0,
    },
  );
  sx /= n;
  sy /= n;
  return {
    sx,
    sy,
    ...data.reduce(
      ({ rx2, ry2, rxy }, d) => ({
        rx2: rx2 + (d[xKey] - sx) * (d[xKey] - sx),
        ry2: ry2 + (d[yKey] - sy) * (d[yKey] - sy),
        rxy: rxy + (d[xKey] - sx) * (d[yKey] - sy),
      }),
      {
        rx2: 0,
        ry2: 0,
        rxy: 0,
      },
    ),
  };
};

export const regresion = (data, xKey, yKey) => {
  if(data.length < 2) return {a: 0, b: 0, R2: 0};
  const { sx, sy, rx2, ry2, rxy } = regresionData(data, xKey, yKey);
  const a = rxy / rx2;
  if(Number.isNaN(a)) return {a: 0, b: 0, R2: 0};
  const b = sy - a * sx;
  const ss = data.reduce(
    (prev, d) => prev + (a * d[xKey] + b - sy) * (a * d[xKey] + b - sy),
    0,
  );
  return {
    a,
    b,
    R2: ss / ry2,
  };
};

export const generateRegresionKey = path => `REG_${path}`;

// TODO dodanie Object.assign
export const addRegresionValues = (data, usedData, xKey, {domainMin, domainMax}) => {
  let nData = [...data];
  if(Number.isNaN(domainMax) || Number.isNaN(domainMin)) return nData;
  usedData
    .filter(e => e.showRegresion)
    .forEach(used => {
      const { dataSource } = used;
      const dataSet = filterDataByKeys(data, [dataSource, xKey]);
      used.regresion = regresion(dataSet, xKey, dataSource);
      const { a, b } = used.regresion;
      const regKey = generateRegresionKey(dataSource);
      nData = [
        {
          [xKey]: domainMin,
          [regKey]: a * domainMin + b,
        },
        ...nData,
        {
          [xKey]: domainMax,
          [regKey]: a * domainMax + b,
        },
      ];
    });
  return nData;
};
