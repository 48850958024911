import React, { useContext, useEffect } from 'react';
import { Tabs } from 'antd';

import { AdministrationContext } from './AdministrationDataProvider';

import UsersList from './scenes/Users/UsersList';
import BuildingsList from './scenes/Buildings/BuildingsList';
import IndicatorsForm from './scenes/Indicators/IndicatorsForm';
import CommonModal from './scenes/CommonModal';

const Administration = () => {
  const { fetchBuildings, fetchUsers } = useContext(AdministrationContext);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchBuildings();
  }, []);

  return (
    <div style={{ padding: '20px', height: '100%', background: '#EEF1F1' }}>
      <Tabs defaultActiveKey="users">
        <Tabs.TabPane tab="Użytkownicy" key="users">
          <UsersList />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Budynki" key="buildings">
          <BuildingsList />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Wskaźniki" key="indicators">
          <IndicatorsForm />
        </Tabs.TabPane>
      </Tabs>
      <CommonModal />
    </div>
  );
};

export default Administration;
