import React, { useContext, useEffect } from 'react';
import { Tabs } from 'antd';

import ContentWrapper from '../../../components/ContentWrapper';
import { AlarmsContext } from '../AlarmsDataProvider';
import AlarmsTable from '../components/AlarmsTable';
import AlarmsSettings from './AlarmsSettings';

const AdminAlarms = () => {
  const {
    activeAlarms,
    alarmsHistory,

    fetchActiveAlarms,
    fetchAlarmsHistory,
    changeSetting,
    deactivateAlarm,
  } = useContext(AlarmsContext);

  useEffect(() => {
    fetchActiveAlarms();
    fetchAlarmsHistory();
  }, []);

  return (
    <ContentWrapper style={{padding: '20px', height: '100%'}}>
      <Tabs>
        <Tabs.TabPane tab="Aktywne alarmy użytkowników" key="usersAlarms">
          <AlarmsTable
            alarms={activeAlarms}
            deactivateAlarm={deactivateAlarm}
            fetchActiveAlarms={fetchActiveAlarms}
            fetchAlarmsHistory={fetchAlarmsHistory}
            isAdmin
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Historia alarmów użytkowników" key="usersHistory">
          <AlarmsTable alarms={alarmsHistory} isAdmin isHistory />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Ustawienia alarmów" key="settings">
          <AlarmsSettings changeSetting={changeSetting} />
        </Tabs.TabPane>
      </Tabs>
    </ContentWrapper>
  );
};

export default AdminAlarms;
