import React, { useContext, useState } from 'react';
import moment from 'moment';
import { Select } from 'antd';

import CrudList from '../../../../components/CRUDs/CrudList';

import { AppContext } from '../../../../AppDataProvider';
import { DbContext } from '../../DbDataProvider';
import { getHookUpDetails, getHookUpUnit } from './services/services';

const { Option } = Select;

const InvoicesList = () => {
  const { buildings, enums } = useContext(AppContext);
  const {
    hookUps,
    invoices,
    setCurrentId,
    setMode,
    setKind,
    setIsModalVisible,
  } = useContext(DbContext);

  const showInvoicesModal = id => {
    setIsModalVisible(true);
    setCurrentId(id);
    setKind('Invoices');
  };

  const handleCreateInvoice = () => {
    showInvoicesModal();
    setMode('create');
  };

  const handleReadInvoice = id => {
    showInvoicesModal(id);
    setMode('read');
  };

  const handleUpdateInvoice = id => {
    showInvoicesModal(id);
    setMode('update');
  };

  const handleDeleteInvoice = id => {
    showInvoicesModal(id);
    setMode('delete');
  };

  const [selectedBuildingId, setSelectedBuildingId] = useState();
  const handleBuildingChange = value => {
    setSelectedBuildingId(value);
    setSelectedHookUpId(undefined);
  };

  const buildingOptions = buildings.map(building => (
    <Option value={building.id} key={building.id}>
      {building.shortName}
    </Option>
  ));

  const [selectedHookUpId, setSelectedHookUpId] = useState();
  const handleHookUpChange = value => setSelectedHookUpId(value);
  const hookUpsgOptions = hookUps
    .filter(hookUp => hookUp.buildingId === selectedBuildingId)
    .map(hookUp => (
      <Option value={hookUp.id} key={hookUp.id}>
        {`${hookUp.name}, ${enums.hookUpTypes[hookUp.type - 1].name}`}
      </Option>
    ));

  const filteredInvoices = invoices
    .filter(invoice => invoice.hookUpId === selectedHookUpId)
    .map(invoice => ({
      name: `Faktura ${moment(invoice.month).format(
        'MM-YYYY',
      )}${getHookUpDetails(invoice.hookUpId, hookUps, buildings)}`,
      description: `Zużycie ${invoice.monthlyUsage} ${getHookUpUnit(
        invoice.hookUpId,
        hookUps,
      )}`,
      id: invoice.id,
    }));

  return (
    <>
      <Select
        placeholder="Wybierz budynek"
        style={{ width: 350, margin: '20px 20px 20px 4px' }}
        value={selectedBuildingId}
        onChange={handleBuildingChange}
      >
        {buildingOptions}
      </Select>
      {selectedBuildingId && (
        <Select
          placeholder="Wybierz przyłącze"
          value={selectedHookUpId}
          style={{ width: 350, margin: '20px 20px 20px 4px' }}
          onChange={handleHookUpChange}
        >
          {hookUpsgOptions}
        </Select>
      )}
      {selectedHookUpId && (
        <CrudList
          items={filteredInvoices}
          addText="Dodaj fakturę"
          onCreate={handleCreateInvoice}
          onRead={handleReadInvoice}
          onUpdate={handleUpdateInvoice}
          onDelete={handleDeleteInvoice}
        />
      )}
    </>
  );
};

export default InvoicesList;
