import React, { useContext } from 'react';
import styled from 'styled-components';
import { filter } from 'lodash';

import { Icon, Select, Empty, TreeSelect } from 'antd';

import { AppContext } from '../AppDataProvider';
import { removePolish } from '../utils/formaters';

const Option = Select.Option;

const userFormatter = id => `user-${id}`;

const buildingsByUser = (users, buildings) =>
    users.map(user => ({
        ...user,
        buildings: (user.buildingsIds || []).map(id => buildings.find(e => e.id === id)).filter(e => e),
    }));

const buildingDefaultFilterOption = (input, option) =>
option.props.isLeaf ?
    removePolish(option.props.title.toLowerCase()).includes(removePolish(input.toLowerCase()))
    :
    option.props.children.reduce((prev, child) => prev || buildingDefaultFilterOption(input, child), false);

export const BuildingSelect = ({onChange, selectedUser, ...props}) => {
    const {measuredBuildings, standardUsers, isAdmin} = useContext(AppContext);
    const usedUser = selectedUser ?
        filter(standardUsers, ({id}) => userFormatter(id) === selectedUser)
        :
        standardUsers;
    return (
        <StyledCommonSelect>
            <IconHolder>
                <Icon type="home" style={{fontSize: "24px"}}/>
            </IconHolder>
            <SelectHolder>
                {isAdmin
                ? (<TreeSelect
                    showSearch
                    placeholder="Wybierz budynek"
                    treeDefaultExpandAll
                    notFoundContent={
                        <Empty description="Brak wyników" image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    }
                    onChange={onChange}
                    filterTreeNode={buildingDefaultFilterOption}
                    treeData={buildingsByUser(usedUser, measuredBuildings).map(e => ({
                        title: e.name,
                        isLeaf: false,
                        value: userFormatter(e.id),
                        selectable: false,
                        key: userFormatter(e.id),
                        children: e.buildings.map(({id, fullName}) => ({
                            title: fullName,
                            value: id,
                            key: id,
                            isLeaf: true,
                        })),
                    }))}
                    style={selectStyle}
                    {...props}
                />)
                : (<Select 
                    placeholder="Wybierz budynek"
                    onChange={onChange}
                    style={selectStyle}
                    {...props}
                >{measuredBuildings.map(({id, fullName}) => (
                    <Option key={id} value={id}>
                        {fullName}
                    </Option>))}
                </Select>)}
            </SelectHolder>
        </StyledCommonSelect>
    );
}

const defaultFilterOption = (input, option) =>
    removePolish(option.props.children.toLowerCase()).includes(removePolish(input.toLowerCase()));

export const UserSelect =  ({
    onChange,
    ...props
}) => {
    const { standardUsers } = useContext(AppContext);
    return (
        <StyledCommonSelect>
            <IconHolder>
                <Icon type="compass" style={{fontSize: "24px"}}/>
            </IconHolder>
            <SelectHolder>
                <Select
                    showSearch
                    placeholder="Wybierz użytkownika"
                    notFoundContent={
                        <Empty description="Brak wyników" image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    }
                    onChange={onChange}
                    filterOption={defaultFilterOption}
                    style={selectStyle}
                    {...props}
                >
                    {standardUsers.map(({id, name}) =>
                        <Option
                            key={userFormatter(id)}
                            value={userFormatter(id)}
                        >
                            {name}
                        </Option>,
                    )}
                </Select>
            </SelectHolder>
        </StyledCommonSelect>
    );
}

const selectStyle = {
    width: '100%',
}

const IconHolder = styled.div`
    padding: 4px 12px 4px 0;
    flex: 0;
`;

const SelectHolder = styled.div`
    flex: 1;
    min-width: 0;
    position: relative;
`;

const StyledCommonSelect = styled.div`
    width: 100%;
    display: flex;
`;
