import React from 'react';
import styled from 'styled-components';

export default ({to, children, prefix, ...props}) => (
    <PageHeading {...props}>
        {prefix && (<Prefix>{prefix}</Prefix>)}
        {to ? <StyledHref href={`#${to}`}>{children}</StyledHref> : children}
    </PageHeading>);

const PageHeading = styled.div`
    font-weight: bold;
    font-size: 28px;
    padding: 8px 0 16px;
    color: darkblue
`;

const StyledHref = styled.a`
    color: inherit;
    text-decoration: inherit;
`;

const Prefix = styled.span`
    min-width: 24px;
    padding-right: 32px;
`;
