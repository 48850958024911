import React, { PureComponent } from "react";
import { XAxis } from "recharts";
import styled from 'styled-components';
// imports
import MathParser from "../MathParser";
import { formatDate, formatHour } from "../../utils/formaters";

const defaultPadding = {
  left: 0,
  right: 10,
};

export const DefaultAxis = ({
  dataKey,
  color,
  showDescription,
  description,
  ...props
}) => (
  <XAxis
    dataKey={dataKey}
    tickMargin={8}
    stroke={color}
    height={xAxisHeight(showDescription, false)}
    label={
      showDescription
        ? <CustomXLabel description={description} color={color} {...props} />
        : undefined
    }
    {...props}
  />
)

export const CustomTimeAxis = ({
    description,
    dataKey,
    showDescription,
    color,
    withTime,
    datesNumber,
    ranges,
    hasLeft = true,
    hasRight = false,
    tickFormatter,
    ...props
  }) => (
    <XAxis
      allowDataOverflow
      padding={defaultPadding}
      tick={<CustomizedTimeTick
        padding={defaultPadding}
        ranges={ranges}
        hasLeft={hasLeft}
        hasRight={hasRight}
        withTime={withTime}
        color={color}
        tickFormatter={tickFormatter}
      />}
      interval={0}
      dataKey={dataKey}
      type="number"
      stroke={color}
      height={xAxisHeight(showDescription, withTime)}
      domain={["dataMin", "dataMax"]}
      ticks={ranges.ticks}
      {...(!ranges.ticks && {tick: false})}
      label={
        showDescription
          ? <CustomXLabel description={description} color={color} {...props} />
          : undefined
      }
      {...props}
    />
  );
  
const defaultTickFormatter = tick => parseFloat(tick.toFixed(2));

  export default ({
    description,
    dataKey,
    ranges,
    showDescription,
    color,
    unit,
    tickFormatter,
    ...props
  }) => (
    <XAxis
      allowDataOverflow
      padding={defaultPadding}
      interval={0}
      dataKey={dataKey}
      type="number"
      stroke={color}
      height={xAxisHeight(showDescription, false)}
      domain={[
        ranges.domainMin === undefined ? 'dataMin' : ranges.domainMin,
        ranges.domainMax === undefined ? 'dataMax' : ranges.domainMax]}
      ticks={ranges.ticks}
      {...(!ranges.ticks && {tick: false})}
      tickFormatter={tickFormatter || defaultTickFormatter}
      label={
        showDescription
          ? <CustomXLabel
                description={description}
                color={color}
                unit={unit}
                {...props}
              />
          : undefined
      }
      {...props}
    />
  );

  const CustomXLabel = ({ description, unit, color, ...props }) => {
    const { x, y, height, width } = props.viewBox;
    const customHeight = 20;
    const ny = y + height - customHeight;
    return (
      <g transform={`translate(${x}, ${ny})`}>
        <foreignObject height={customHeight} width={width}>
          <StyledLabel style={{ color }}>
            <StyledLabelInner>
              <StyledLabelTransform>
                <MathParser>{`${description}${unit ? ` [${unit}]` : ''}`}</MathParser>
              </StyledLabelTransform>
            </StyledLabelInner>
          </StyledLabel>
        </foreignObject>
      </g>
    );
  };

const calculateMiss = (value, {padding, ranges, width, hasLeft, hasRight}) => {
  const tickMaxHalf = 42;
  const {ticks, domainMax, domainMin } = ranges;
  if(Number.isNaN(domainMax) || Number.isNaN(domainMin) ||
    domainMax === undefined || domainMin === undefined ||
    !ticks || ticks.length === 0) return 0;
  if(value === ticks[0] && !hasLeft) {
    const left = (padding && padding.left) || 0;
    const position = width * (value - domainMin) / (domainMax - domainMin);
    return Math.max(0, tickMaxHalf - left - position);
  }
  if(value === ticks[ticks.length - 1] && !hasRight) {
    const right = (padding && padding.right) || 0;
    const position = width * (domainMax - value) / (domainMax - domainMin);
    return - Math.max(0, tickMaxHalf - right - position);
  }
  return 0;
}

  class CustomizedTimeTick extends PureComponent {
    render() {
      const { x, y, textAnchor, payload, withTime, color, tickFormatter } = this.props;
      const {value} = payload;
      const miss = calculateMiss(value, this.props);
      const nx = x + miss;
      const dataFormatter = tickFormatter || formatDate;
      return (
        <g transform={`translate(${nx},${y})`}>
          <text x={0} y={0} dy={14} textAnchor={textAnchor} fill={color}>
            {dataFormatter(value)}
          </text>
          {withTime && (
            <text x={0} y={14} dy={14} textAnchor={textAnchor} fill={color}>
              {formatHour(value)}
            </text>
          )}
        </g>
      );
    }
  }
  
const xAxisHeight = (showDescription, withTime) =>
    30 + (showDescription ? 18 : 0) + (withTime ? 14 : 0);

  const StyledLabel = styled.div`
    text-align: center;
    height: 100%;
    width: 100%;
    font-size: 13px;
    @media (max-width: 1600px) {
        font-size: 12px;
    }
    @media (max-width: 1440px) {
        font-size: 11px;
    }
    @media (max-width: 1366px) {
      font-size: 10px;
    }
    @media print {
      font-size: 16px !important;
    }
  `;

  const StyledLabelInner = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
  `;

  const StyledLabelTransform = styled.div`
    transform: translateX(-50%) translateY(-50%);
    white-space: nowrap;
  `;
