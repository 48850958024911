import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis
} from 'recharts';
import PropTypes from 'prop-types';
import { filter, find } from 'lodash';

import {
  addRegresionValues,
  chartRangesFromConfig,
  dateTicks,
  generateRegresionKey
} from './utils/calculate';
import {
  dataFromXAxis,
  dataFromAxis,
  dataFromLimits,
  dataFromAreas,
  hasLeftAxis,
  hasRightAxis,
  isTimeChart
} from './utils/filters';
import { chartConfigType, usedDataType } from './utils/chartTypes';
import RegresionSummary from './RegresionSummary';
import CustomXAxis, { CustomTimeAxis } from './CustomXAxis';
import CustomYAxis from './CustomYAxis';
import CustomTooltip from './CustomTooltip';
import CustomLegend from './CustomLegend';
import CustomArea from './CustomArea';
import CustomLimit from './CustomLimit';
import {
  StyledChart,
  Container,
  ChartTitle,
  RegresionContainer,
} from './Common';
import MathParser from '../MathParser';

export default Object.assign(
  ({ usedData, data, chartConfig, ...props }) => {
    // console.log('passed to chart');
    // console.log({ usedData, data, chartConfig, ...props });
    const { xAxis, settings, yAxises, limits, areas } = chartConfig;
    const {
      showTitle,
      title,
      stopAnimation,
      stopInteractive,
      forceDateTooltip,
      horizontalGrid,
      verticalGrid,
      showLegend,
      xFormatter,
      yFormatter,
      regresionPosition,
      showRegression,
    } = settings;
    const xAxisRanges = xAxis.useDefault
      ? {
          domainMin: 'dataMin',
          domainMax: 'dataMax'
        }
      : (axisFun => axisFun(dataFromXAxis(data, xAxis.xKey), xAxis))(
          isTimeChart(xAxis.xKey) ? dateTicks : chartRangesFromConfig
        );
    const dataWithReg = addRegresionValues(
      data,
      usedData,
      xAxis.xKey,
      xAxisRanges
    );
    const xAxisChoice = () => {
      if (xAxis.useDefault) return ({ ...prop }) => <XAxis {...prop} />;
      return isTimeChart(xAxis.xKey) ? CustomTimeAxis : CustomXAxis;
    };

    return (
      <StyledChart showRegression={showRegression}>
        {showTitle && (
          <ChartTitle>
            <MathParser>{title}</MathParser>
          </ChartTitle>
        )}
        <Container showTitle={showTitle}>
          <ResponsiveContainer width="100%" height="100%" {...props}>
            <LineChart
              data={dataWithReg}
            >
              {!stopInteractive && (
                <Tooltip
                  content={
                    <CustomTooltip
                      withTime={xAxis.withTime}
                      forceDateTooltip={forceDateTooltip}
                      xAxis={xAxis}
                      xFormatter={xFormatter}
                      yFormatter={yFormatter}
                    />
                  }
                />
              )}
              {showLegend && CustomLegend()}
              {areas &&
                areas.map((area, id) => CustomArea({ ...area, key: id }))}
              <CartesianGrid
                strokeDasharray="5 5"
                horizontal={horizontalGrid}
                vertical={!xAxisRanges.ticks ? verticalGrid : false}
              />
              {verticalGrid &&
                xAxisRanges.ticks &&
                xAxisRanges.ticks.map(x => (
                  <ReferenceLine
                    key={`cartesian-x-${x}`}
                    x={x}
                    yAxisId={yAxises[0].id}
                    strokeDasharray="5 5"
                  />
                ))}
              {limits &&
                limits.map((limit, id) => CustomLimit({ ...limit, key: id }))}
              {filter(usedData, e => e.showRegresion).map(
                ({ id, dataSource, ...used }) =>
                  CustomLine({
                    yAxises,
                    ...used,
                    dataSource: generateRegresionKey(dataSource),
                    lineId: id,
                    key: `regresionLine-${id}`,
                    dotted: false,
                    legendType: 'none',
                    ...(stopAnimation && { isAnimationActive: false }),
                    ...regresionSettings
                  })
              )}
              {usedData.map(({ id, dotted, dataSource, ...used }) =>
                CustomLine({
                  yAxises,
                  ...used,
                  dataSource,
                  dotted: isTimeChart(xAxis.xKey) ? dotted : true,
                  lineId: id,
                  key: `usedData-${id}`,
                  ...(stopAnimation && { isAnimationActive: false })
                })
              )}
              {(axisComp =>
                axisComp({
                  ...xAxis,
                  dataKey: xAxis.xKey,
                  hasLeft: hasLeftAxis(yAxises),
                  hasRight: hasRightAxis(yAxises),
                  ranges: xAxisRanges,
                  ...(xFormatter && { tickFormatter: xFormatter }),
                  ...(stopAnimation && { isAnimationActive: false })
                }))(xAxisChoice())}
              {// WONTFIX recharts ma problem z przeciazaniem komponentow
              yAxises.map(yAxis =>
                CustomYAxis({
                  ...yAxis,
                  key: `yAxis-${yAxis.id}`,
                  dataSpread: [
                    ...dataFromAxis(data, usedData, yAxis.id, yAxis.maxMin, yAxis.minMax),
                    ...dataFromLimits(limits, yAxis.id),
                    ...dataFromAreas(areas, yAxis.id)
                  ],
                  ...(yFormatter && { tickFormatter: yFormatter })
                })
              )}
            </LineChart>
          </ResponsiveContainer>
        </Container>
        {(!stopInteractive || showRegression) && (<RegresionContainer regresionPosition={regresionPosition}>
          <RegresionSummary
            usedData={usedData}
            xAxis={xAxis}
            ranges={xAxisRanges}
          />
        </RegresionContainer>)}
      </StyledChart>
    );
  },
  {
    propTypes: {
      chartConfig: PropTypes.shape(chartConfigType).isRequired,
      usedData: PropTypes.arrayOf(PropTypes.shape(usedDataType)).isRequired,
      data: PropTypes.arrayOf(PropTypes.any)
    }
  }
);

const regresionSettings = {
  activeDot: false,
  lineWidth: 1,
  dashed: true,
  dashSpacing: 5,
  dashLength: 5
};

const CustomLine = ({
  yAxises,
  description,
  showDescription,
  lineId,
  axis,
  dotted,
  dashed,
  dashSpacing,
  dashLength,
  dataSource,
  lineColor,
  lineWidth,
  unit,
  ...props
}) => (
  <Line
    activeDot
    type="monotone"
    connectNulls
    unit={(e => (e && e.unit) || unit)(find(yAxises, e => e.id === axis))}
    name={description}
    lineId={lineId}
    strokeDasharray={dashed ? `${dashLength} ${dashSpacing}` : undefined}
    strokeWidth={dotted ? 0 : lineWidth}
    stroke={lineColor}
    dot={dotted ? { stroke: lineColor, strokeWidth: lineWidth } : false}
    dataKey={dataSource}
    yAxisId={axis}
    {...props}
  />
);
