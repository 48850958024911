import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Button, Empty } from 'antd';
import { getDisableReason, NOT_LOADED_DATA_REASONS } from './ChartSetup/utils/constants';

export default ({loadedData, usedData, config, isOverflow, accept}) => {
    const status = usedData.map(({show, dataSource}) => {
        const disableReason = getDisableReason(
            dataSource,
            loadedData,
            config.xAxis.xKey,
        );
        return {
            show,
            noAxisData: disableReason === NOT_LOADED_DATA_REASONS.NOAXISDATA,
            asKey: disableReason === NOT_LOADED_DATA_REASONS.AXISKEY,
            canShow: disableReason === null,
        };
    });
    const canShowData = status.find(e => e.canShow);
    const onlyXkey = status.reduce((prev, e) => prev && e.asKey, true);
    let description = null;
    if (isOverflow) {
        description = (<StyledDescription>
            <Tip>Duża ilość danych wyświetlanych na wykresie.</Tip>
            <Tip>Pokazywania wykresu może spowolić działanie przeglądarki oraz mogą wystąpić niepłynne animacje.</Tip>
            <Tip>
                <Button type="danger" onClick={() => accept()}>
                    Pokazuj dużą ilość danych
                </Button>
            </Tip>
        </StyledDescription>);
    }
    else if (usedData.length === 0) {
        description = (<StyledDescription>
            <Tip>W tym miejscu pojawi się wykres.</Tip>
            <Tip>Pobierz dane do wyświetlenia w sekcji <b>Pobranie danych</b></Tip>
        </StyledDescription>);
    }
    else if(canShowData) {
        description = (<StyledDescription>
            <Tip>Aby pokazać dane, zmień ich wyświetlanie za pomocą suwaka.</Tip>
            <Tip>Konfiguracja wyświetlania ma miejsce w sekcji <b>Załadowane dane</b></Tip>
        </StyledDescription>);
    }
    else if(onlyXkey) {
        description = (<StyledDescription>
            <Tip>Pobrane zostały tylko dane odkładane na osi X.</Tip>
            <Tip>Aby pobrać dane do wyświetlenia na innych osiach, pobierz inne dany w sekcji <b>Pobranie danych </b>
            lub zmień dane z osi odniesienia na zakres czasowy w sekcji <b>Konfiguracja wykresu - Oś pozioma (odniesienia)</b>.</Tip>
        </StyledDescription>);
    }
    else {
        description = (<StyledDescription>
            <Tip>Pobrane dane nie mają odpowiadających im danych na osi X.</Tip>
            <Tip>Wybór danych odkładanych na osi X można zmienić w sekcji <b>Konfiguracja wykresu - Oś pozioma (odniesienia)</b>.</Tip>
        </StyledDescription>);
    }

    return (<NoChartReason
        image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
        description={description}
    />);
}

const NoChartReason = styled(Empty)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const StyledDescription = ({children}) =>
    <Fragment>
        {React.Children.map(children, (child, id) => (
            <Fragment>
                {child}
                {id + 1 < React.Children.count(children) && <br />}
            </Fragment>
        ))}
    </Fragment>

const Tip = styled.span`
    font-size: 1rem;
    & + & {
        margin-top: 8px;
    }
`;
