import React, { useContext } from 'react';

import CrudList from '../../../../components/CRUDs/CrudList';
import { AdministrationContext } from '../../AdministrationDataProvider';

const UsersList = () => {
  const {
    users,
    setCurrentId,
    setMode,
    setKind,
    setIsModalVisible,
  } = useContext(AdministrationContext);

  const showUsersModal = id => {
    setIsModalVisible(true);
    setCurrentId(id);
    setKind('Users');
  };

  const handleCreateUser = () => {
    showUsersModal();
    setMode('create');
  };

  const handleReadUser = id => {
    showUsersModal(id);
    setMode('read');
  };

  const handleUpdateUser = id => {
    showUsersModal(id);
    setMode('update');
  };

  const handleDeleteUser = id => {
    showUsersModal(id);
    setMode('delete');
  };

  const handleChangePasswordUser = id => {
    showUsersModal(id);
    setMode('changePassword');
  };

  return (
    <CrudList
      items={users.map(user => ({
        description: `Kontakt: ${user.contactPerson} - ${user.contactPhone}`,
        ...user,
      }))}
      addText="Dodaj użytkownika"
      onCreate={handleCreateUser}
      onRead={handleReadUser}
      onUpdate={handleUpdateUser}
      onDelete={handleDeleteUser}
      customAction={{
        onClick: handleChangePasswordUser,
        label: 'Zmień hasło',
      }}
    />
  );
};

export default UsersList;
