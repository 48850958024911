import React, { useContext } from 'react';
import styled from 'styled-components';

import AnnualData from '../components/AnnualData';
import EnergyUseCompare from '../components/EnergyUseCompare';
import TimeSection from '../components/TimeSection';

import { DashboardContext } from '../DashboardDataProvider';

export default () => {
  const { currentBuilding } = useContext(DashboardContext);
  const building = currentBuilding();
  const fetchingData = !building.finished.data;
  return (
    <BuildingDetails>
      <EnergyUseCompare data={building.data} loading={fetchingData} />
      <TimeSection data={building.data} loading={fetchingData} />
      <AnnualData data={building.data} loading={fetchingData} />
    </BuildingDetails>
  );
};

const BuildingDetails = styled.div`
  width: 272px;
  padding: 0 16px;
  @media (max-width: 1600px) {
    width: 236px;
    padding: 0 12px;
  }
  @media (max-width: 1440px) {
    width: 200px;
    padding: 0 8px;
  }
`;
