import moment from 'moment';

import { formatNumber } from '../../../../utils/formaters';
import { multiChartLineColors } from '../../../../components/ColorSelect';
import { CHART_TYPES } from '../../../../components/CustomChart';

const barProps = {
  minTickGap: 15,
  interval: "preserveStart",
};

export const weekConfig = {
    heatPowerConsumption: ({settings}) => ({
        chartConfig: {
          settings: {
            stopAnimation: false,
            stopInteractive: false,
            title: "",
            showTitle: false,
            showLegend: false,
            horizontalGrid: false,
            verticalGrid: false,
            yFormatter: (e) => formatNumber(e, {position: -1}),
          },
          ...(settings.limit && {
            limits: [
              {
                description: null,
                axis: "0",
                lineWidth: 2,
                lineColor: "#C9776B",
                dashLength: 10,
                dashSpacing: 15,
                value: settings.limit,
              },
            ],
          }),
          xAxis: {
            xKey: "time",
            description: "Czas",
            showDescription: false,
            color: "#333",
            datesNumber: 3,
            withTime: true,
          },
          yAxises: [
            {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Pobór mocy cieplnej",
              showDescription: true,
              unit: "kW",
              rangeFrom: "0",
              rangeTo: "auto",
              rangeSpan: "auto",
            },
          ],
        },
        chartType: CHART_TYPES.LINE_CHART,
        usedData: [
          {
            id: "0",
            description: "Pobór mocy cieplnej",
            dataSource: "heatPower",
            axis: "0",
            showRegresion: false,
            dotted: false,
            lineWidth: 2,
            lineColor: "#0E4194",
            dashed: false,
            dashLength: 5,
            dashSpacing: 5,
            unit: "kW",
          },
        ],
    }),
    electricityPowerConsumption: ({settings}) => ({
        chartConfig: {
          settings: {
            stopAnimation: false,
            stopInteractive: false,
            title: "",
            showTitle: false,
            showLegend: false,
            horizontalGrid: false,
            verticalGrid: false,
            yFormatter: (e) => formatNumber(e, {position: -1}),
          },
          ...(settings.limit && {
            limits: [
              {
                description: null,
                axis: "0",
                lineWidth: 2,
                lineColor: "#C9776B",
                dashLength: 10,
                dashSpacing: 15,
                value: settings.limit,
              },
            ],
          }),
          xAxis: {
            xKey: "time",
            description: "Czas",
            showDescription: false,
            color: "#333",
            datesNumber: 3,
            withTime: true,
          },
          yAxises: [
            {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Pobór mocy elekt.",
              showDescription: true,
              unit: "kW",
              rangeFrom: "0",
              rangeTo: "auto",
              rangeSpan: "auto",
            },
          ],
        },
        chartType: CHART_TYPES.LINE_CHART,
        usedData: [
          {
            id: "0",
            description: "Pobór mocy elektrycznej",
            dataSource: "electricityPower",
            axis: "0",
            showRegresion: false,
            dotted: false,
            lineWidth: 2,
            lineColor: "#0E4194",
            dashed: false,
            dashLength: 5,
            dashSpacing: 5,
            unit: "kW",
          },
        ],
    }),
    totalConsumption: () => ({
        chartConfig: {
          settings: {
            stopAnimation: false,
            stopInteractive: false,
            title: "",
            showTitle: false,
            showLegend: false,
            horizontalGrid: false,
            verticalGrid: false,
            xFormatter: (e) => moment(e).format("YYYY-MM-DD"),
            yFormatter: (e) => formatNumber(e, {position: -1}),
          },
          xAxis: {
            xKey: "time",
            description: "Czas",
            showDescription: false,
            color: "#333",
            datesNumber: 3,
            withTime: false,
            useDefault: true,
            ...barProps,
          },
          yAxises: [
            {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Zużycie energii",
              showDescription: true,
              unit: "kWh",
              rangeFrom: "0",
              rangeTo: "auto",
              rangeSpan: "auto",
            },
          ],
        },
        chartType: CHART_TYPES.BAR_CHART,
        usedData: [
          {
            id: "0",
            description: "Dzienne zużycie energii elektrycznej",
            dataSource: "dailyElectricityConsumption",
            axis: "0",
            showRegresion: false,
            dotted: false,
            lineWidth: 2,
            lineColor: '#10C8CA',
            dashed: false,
            dashLength: 5,
            dashSpacing: 5,
            unit: "kWh",
          },
          {
            id: "1",
            description: "Dzienne zużycie ciepła",
            dataSource: "dailyHeatConsumption",
            axis: "0",
            showRegresion: false,
            dotted: false,
            lineWidth: 2,
            lineColor: '#4462ba',
            dashed: false,
            dashLength: 5,
            dashSpacing: 5,
            unit: "kWh",
          },
        ],
    }),
    temperatures: ({settings}) => ({
        chartConfig: {
          settings: {
            stopAnimation: false,
            stopInteractive: false,
            title: "",
            showTitle: false,
            showLegend: false,
            horizontalGrid: false,
            verticalGrid: false,
            yFormatter: (e) => formatNumber(e, {position: -1}),
          },
          xAxis: {
            xKey: "time",
            description: "Czas",
            showDescription: false,
            color: "#333",
            datesNumber: 3,
            withTime: true,
          },
          yAxises: [
            {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Temperatura",
              showDescription: true,
              unit: "^oC",
              rangeFrom: "auto",
              rangeTo: "auto",
              rangeSpan: "auto",
            },
          ],
        },
        chartType: CHART_TYPES.LINE_CHART,
        usedData: settings.measurements.map(
            ({dataSource, description}, id) => ({
                id: id.toString(),
                description,
                dataSource,
                axis: "0",
                showRegresion: false,
                dotted: false,
                lineWidth: 2,
                lineColor: multiChartLineColors[id % multiChartLineColors.length].color,
                dashed: false,
                dashLength: 5,
                dashSpacing: 5,
                unit: "^oC",
            }),
        ),
    }),
    co2: ({settings}) => ({
        chartConfig: {
          settings: {
            stopAnimation: false,
            stopInteractive: false,
            title: "",
            showTitle: false,
            showLegend: false,
            horizontalGrid: false,
            verticalGrid: false,
            yFormatter: (e) => formatNumber(e, {position: -1}),
          },
          limits: [
            {
              description: null,
              axis: "0",
              lineWidth: 2,
              lineColor: "#C9776B",
              dashLength: 10,
              dashSpacing: 15,
              value: settings.limit || 1000,
            },
          ],
          xAxis: {
            xKey: "time",
            description: "Czas",
            showDescription: false,
            color: "#333",
            datesNumber: 3,
            withTime: true,
          },
          yAxises: [
            {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Stężenie CO_2",
              showDescription: true,
              unit: "ppm",
              rangeFrom: "0",
              rangeTo: "auto",
              rangeSpan: "auto",
              minMax: 1500,
            },
          ],
        },
        chartType: CHART_TYPES.LINE_CHART,
        usedData: settings.measurements.map(
            ({dataSource, description}, id) => ({
                id: id.toString(),
                description,
                dataSource,
                axis: "0",
                showRegresion: false,
                dotted: false,
                lineWidth: 2,
                lineColor: multiChartLineColors[id % multiChartLineColors.length].color,
                dashed: false,
                dashLength: 5,
                dashSpacing: 5,
                unit: "ppm",
            }),
        ),
    }),
    OZEProduction: () => ({
        chartConfig: {
          settings: {
            stopAnimation: false,
            stopInteractive: false,
            title: "",
            showTitle: false,
            showLegend: false,
            horizontalGrid: false,
            verticalGrid: false,
            xFormatter: (e) => moment(e).format("YYYY-MM-DD"),
            yFormatter: (e) => formatNumber(e, {position: -1}),
          },
          xAxis: {
            xKey: "time",
            description: "Czas",
            showDescription: false,
            color: "#333",
            datesNumber: 3,
            withTime: false,
            useDefault: true,
            ...barProps,
          },
          yAxises: [
            {
              id: "0",
              color: "#333",
              showAxis: true,
              side: "left",
              description: "Produkcja energii",
              showDescription: true,
              unit: "kWh",
              rangeFrom: "0",
              rangeTo: "auto",
              rangeSpan: "auto",
            },
          ],
        },
        chartType: CHART_TYPES.BAR_CHART,
        usedData: [
          {
            id: "0",
            description: "Produkcja energii elektrycznej z OZE",
            dataSource: "dailyOZEProduction",
            axis: "0",
            showRegresion: false,
            dotted: false,
            lineWidth: 2,
            lineColor: "#2490be",
            dashed: false,
            dashLength: 5,
            dashSpacing: 5,
            unit: "kWh",
          },
        ],
    }),
}
