import React from 'react';

import AreaChart from './Charts/AreaChart';
import BarChart from './Charts/BarChart';
import LineChart from './Charts/LineChart';
import PieChart from './Charts/PieChart';
import ScatteredChart from './Charts/ScatteredChart';


export const CHART_TYPES = Object.freeze({
    AREA_CHART: 'area-chart',
    LINE_CHART: 'line-chart',
    BAR_CHART: 'bar-chart',
    PIE_CHART: 'pie-chart',
    SCATTERED_CHART: 'scattered-chart',
});

export default ({chartType, ...rest}) => (Chart => Chart ? <Chart {...rest} /> : null)(
    {
      [CHART_TYPES.AREA_CHART]: AreaChart,
      [CHART_TYPES.BAR_CHART]: BarChart,
      [CHART_TYPES.LINE_CHART]: LineChart,
      [CHART_TYPES.PIE_CHART]: PieChart,
      [CHART_TYPES.SCATTERED_CHART]: ScatteredChart,
    }[chartType]);
