import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Alert } from 'antd';

import CustomChart from '../../../../components/CustomChart';
import ChartCard from '../../../Dashboard/components/ChartCard';

import { getMyBuildingsChartsConfig } from './chartConfig';
import { BenchmarkingContext } from '../../BenchmarkingDataProvider';
import ContentWrapper from '../../../../components/ContentWrapper';

const MyBuildings = () => {
  const { fetchMyBuildingsBenchmark, myBuildingsBenchmark } = useContext(
    BenchmarkingContext,
  );

  useEffect(() => {
    fetchMyBuildingsBenchmark();
  }, []);

  if (!myBuildingsBenchmark) {
    return null;
  }

  const {
    avgElectricityUsage,
    avgEnergyUsage,
    avgHeatUsage,
    avgWaterUsage,
  } = myBuildingsBenchmark;

  const chartConfigs = getMyBuildingsChartsConfig({
    avgElectricityUsage,
    avgEnergyUsage,
    avgHeatUsage,
    avgWaterUsage,
  });

  return (
    <ContentWrapper>
      <AlertWrapper>
        <Alert
          description="Przerywana linia określa wartość średnią."
          type="info"
          showIcon
        />
      </AlertWrapper>
      <Container>
        {chartConfigs.map(cfg => (
          <Cell
            loading={false}
            title={cfg.title}
            key={cfg.title}
            spread
            hide={false}
          >
            <CustomChart
              chartType={cfg.chartType}
              chartConfig={cfg.chartConfig}
              usedData={cfg.usedData}
              data={myBuildingsBenchmark[cfg.dataFieldName]}
            />
          </Cell>
        ))}
      </Container>
    </ContentWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const AlertWrapper = styled.div`
  position: absolute;
  top: -56px;
  right: 24px;
`;

const Cell = ({ children, loading, title, spread, hide }) => (
  <StyledCell>
    <ChartCard loading={loading} title={title} spread={spread} hide={hide}>
      {children}
    </ChartCard>
  </StyledCell>
);

const StyledCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default MyBuildings;
