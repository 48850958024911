import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { List, Switch, Tooltip, Icon } from 'antd';

import { ConfigType, DataType, UsedDataType } from '../utils/types'

import DataInputForm from '../../../../components/DataInputForm';
import StyledDrawer from '../../../../components/StyledDrawer';
import { ButtonForList, SetupList} from '../../../../components/SetupList';

import UseDataDialog from '../Dialogs/UseDataDialog';
import { MODES, formatReason, getDisableReason, formatReasonColor, formatReasonSymbol } from '../utils/constants';
import MathParser from '../../../../../../components/MathParser';

// TODO przerobic na funkcyjny
class UseData extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape(UsedDataType)).isRequired,
    loadedData: PropTypes.arrayOf(PropTypes.shape(DataType)).isRequired,
    config: PropTypes.shape(ConfigType).isRequired,
    update: PropTypes.func.isRequired,
  };

  state = {
    openedDialog: false,
    editedData: null,
  };

  getIndex = (key) => this.props.data.findIndex(e => e.dataSource === key);

  onCloseDialog = () => {
    this.setState({
      openedDialog: false,
      editedData: null,
    });
  };

  openDialogEdit = key => {
    this.setState({
      openedDialog: true,
      editedData: key,
    });
  };

  changeShow = (key, checked) => {
    const { data, update } = this.props;
    const newValue = [ ...data ];
    newValue[this.getIndex(key)].show = checked;
    update(newValue);
  };

  updateData = (key, value) => {
    const { data, update } = this.props;
    const newValue = [ ...data ];
    newValue[this.getIndex(key)] = value;
    update(newValue);
  };

  render() {
    const { data, loadedData, config } = this.props;
    return (
      <DataInputForm>
        <SetupList>
          {data.map(({dataSource, fields, show}) => {
            const reason = getDisableReason(dataSource, loadedData, config.xAxis.xKey);
            return (
            <List.Item
              key={dataSource}
              actions={[
                <ButtonForList disabled={reason} onClick={() => this.openDialogEdit(dataSource)}>edytuj</ButtonForList>,
              ]}
            >
              <List.Item.Meta
                title={<Details
                  reason={reason}
                  description={fields.description}
                  show={show}
                  onChange={(checked) => this.changeShow(dataSource, checked)}
                />}
              />
            </List.Item>)},
          )}
        </SetupList>
        {this.state.editedData !== null && <StyledDrawer
          title='Edycja wyświetlanych danych'
          placement='left'
          onClose={this.onCloseDialog}
          visible={this.state.openedDialog}
          width={480}
        >
          <UseDataDialog
              mode={MODES.EDIT}
              onClose={this.onCloseDialog}
              editedData={data[this.getIndex(this.state.editedData)]}
              loadedData={loadedData}
              config={config}
              updateData={value =>
                this.updateData(this.state.editedData, value)
              }
            />
        </StyledDrawer>}
      </DataInputForm>
    );
  }
}

export default UseData;

const StyledDetails = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledSwitch = styled.div`
  flex: 0;
  padding: 2px 0;
`;

const DetailsTitle = styled.div`
  flex: 1;
  margin-right: 16px;
`;

const StyledWaringTip = styled.div`
  font-size: 1.2rem;
  padding-right: 16px;
  color: ${({reason}) => formatReasonColor(reason)};
`;

const Details = ({description, show, onChange, reason}) =>
  <StyledDetails>
    {reason &&
      <StyledWaringTip reason={reason}>
        <Tooltip title={formatReason(reason)}>
          <Icon type={formatReasonSymbol(reason)} />
        </Tooltip>
      </StyledWaringTip>
    }
    <DetailsTitle>
      <MathParser>
        {description}
      </MathParser>
    </DetailsTitle>
    <StyledSwitch>
      <Tooltip
        visible={reason ? false : undefined}
        title="Pokaż lub ukryj dane"
      >
        <Switch
          checked={reason ? false : show}
          onChange={reason ? undefined : onChange}
        />
      </Tooltip>
    </StyledSwitch>
  </StyledDetails>
