import React from 'react';
import styled from 'styled-components';

import Footer from '../components/Footer';
import FrontPage from '../components/FrontPage';
import Header from '../components/Header';
import { PdfHeader, PdfFooter, PdfContainer } from '../components/PdfComponents';
import ReportNavigation from '../components/ReportNavigation';
import AllBuildings from './AllBuildings';
import BuildingReport from './BuildingReport';
import Introduction from './Introduction';

export default ({
    request,
    user,
    creationDate,
    allBuildings,
    measuredBuildings,
    pms,
    printRef,
    comments = {},
    ...props
}) => (
    <StyledReportGeneration {...props}>
        <ReportInner>
            <ReportContent ref={printRef}>
                <FrontPage
                    config={request}
                    user={user}
                    creationDate={creationDate}
                />
                <PdfHeader>
                    <Header />
                </PdfHeader>
                <PdfFooter>
                    <Footer />
                </PdfFooter>
                <PdfContainer padding={{top: 105, bottom: 80}}>
                    <ReportNavigation
                        buildings={measuredBuildings}
                    />
                    <Introduction comments={comments} />
                    <AllBuildings
                        config={request}
                        comments={comments}
                        data={allBuildings}
                        pms={pms}
                    />
                    {measuredBuildings.map((building, id) =>(
                        <BuildingReport
                            id={id}
                            comments={comments[building.buildingId]}
                            key={building.buildingId}
                            data={building}
                        />))}
                </PdfContainer>
            </ReportContent>
        </ReportInner>
    </StyledReportGeneration>
);

const StyledReportGeneration = styled.div`
    position: relative;
    background: #EEF1F1;
    margin: 0 -20px;
    padding: 24px 0;
`;

const ReportInner = styled.div`
    position: relative;
    margin: auto;
    max-width: 1224px;
    background: white;
    min-height: 1600px;
`;

const ReportContent = styled.div`
    position: relative;
    padding: 24px 0;
    width: 960px;
    margin: auto;
    @media print {
        padding: 0;
    }
`;
