import React from "react";
import styled from "styled-components";

export default ({ visible, ...props }) => (
  <VisibilitySwitch visible={visible} {...props} />
);

const VisibilitySwitch = styled.div`
  display: ${({visible}) => visible ? 'unset' : 'none'};
`;
