import React, { useContext } from 'react';
import { Modal } from 'antd';

import { DbContext } from '../DbDataProvider';
import BuildingForm from './Buildings/BuildingForm';
import HookUpForm from './HookUps/HookUpForm';
import InvoiceForm from './Invoices/InvoiceForm';

const CommonModal = () => {
  const {
    // Buildings
    updateBuilding,
    // HookUps
    createHookUp,
    updateHookUp,
    deleteHookUp,
    // Invoices
    createInvoice,
    updateInvoice,
    deleteInvoice,
    // Rest
    currentEntry,
    setMode,
    setKind,
    currentConfig,
    setIsModalVisible,
    isModalVisible,
  } = useContext(DbContext);

  const closeModal = () => {
    setIsModalVisible(false);
    setMode(undefined);
    setKind(undefined);
  };

  const buildingModalConfigs = {
    readBuildings: {
      header: 'Szczegóły Obiektu',
      component: () => (
        <BuildingForm
          actionLabel="Ok"
          initialState={currentEntry}
          onAction={closeModal}
          onCancel={closeModal}
        />
      ),
    },
    updateBuildings: {
      header: 'Edytuj Obiekt',
      component: () => (
        <BuildingForm
          editable
          actionLabel="Zapisz"
          initialState={currentEntry}
          onAction={updateBuilding}
          onCancel={closeModal}
        />
      ),
      actionLabel: 'Zapisz',
    },
  };

  const hookUpModalConfigs = {
    createHookUps: {
      header: 'Nowe przyłącze',
      component: () => (
        <HookUpForm
          editable
          actionLabel="Stwórz"
          onAction={createHookUp}
          onCancel={closeModal}
        />
      ),
    },
    readHookUps: {
      header: 'Szczegóły Przyłącza',
      component: () => (
        <HookUpForm
          actionLabel="Ok"
          initialState={currentEntry}
          onAction={closeModal}
          onCancel={closeModal}
        />
      ),
    },
    updateHookUps: {
      header: 'Edytuj Przyłącze',
      component: () => (
        <HookUpForm
          editable
          actionLabel="Zapisz"
          initialState={currentEntry}
          onAction={updateHookUp}
          onCancel={closeModal}
        />
      ),
      actionLabel: 'Zapisz',
    },
    deleteHookUps: {
      header: 'Usuń Przyłącze',
      component: () => (
        <HookUpForm
          removeMode
          actionLabel="Usuń"
          initialState={currentEntry}
          onAction={deleteHookUp}
          onCancel={closeModal}
        />
      ),
    },
  };

  const invoiceModalConfigs = {
    createInvoices: {
      header: 'Nowa faktura',
      component: () => (
        <InvoiceForm
          editable
          actionLabel="Stwórz"
          onAction={createInvoice}
          onCancel={closeModal}
        />
      ),
    },
    readInvoices: {
      header: 'Szczegóły Faktury',
      component: () => (
        <InvoiceForm
          actionLabel="Ok"
          initialState={currentEntry}
          onAction={closeModal}
          onCancel={closeModal}
        />
      ),
    },
    updateInvoices: {
      header: 'Edytuj Fakturę',
      component: () => (
        <InvoiceForm
          editable
          actionLabel="Zapisz"
          initialState={currentEntry}
          onAction={updateInvoice}
          onCancel={closeModal}
        />
      ),
      actionLabel: 'Zapisz',
    },
    deleteInvoices: {
      header: 'Usuń Fakturę',
      component: () => (
        <InvoiceForm
          removeMode
          actionLabel="Usuń"
          initialState={currentEntry}
          onAction={deleteInvoice}
          onCancel={closeModal}
        />
      ),
    },
  };
  const modalConfigs = {
    ...buildingModalConfigs,
    ...hookUpModalConfigs,
    ...invoiceModalConfigs,
  };

  const currentModalConfig = modalConfigs[currentConfig];
  const ModalComponent = currentModalConfig
    ? currentModalConfig.component
    : null;

  return currentModalConfig ? (
    <Modal
      title={currentModalConfig.header}
      visible={isModalVisible}
      footer={null}
      onCancel={closeModal}
      cancelText="Anuluj"
    >
      <ModalComponent />
    </Modal>
  ) : null;
};

export default CommonModal;
