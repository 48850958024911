import React from 'react';
import { ReferenceArea } from 'recharts';

export default ({
    axis,
    color,
    opacity,
    from,
    to,
    ...props
}) =>
<ReferenceArea
    y1={from}
    y2={to}
    fill={color}
    fillOpacity={opacity}
    {...props}
/>
