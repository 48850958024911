import React from 'react';

import chartConfig from './chartsConfig';

import ChartHolder, { WrappedChart } from '../components/ChartHolder';
import Conclusion from '../components/Conclusion';
import Page from '../components/Page';
import PageHeading from '../components/PageHeading';
import { genReportLink } from '../components/ReportNavigation';

export default ({
    data,
    comments = {},
    id,
    ...props
}) => (<Page {...props}>
    {(id === 0) && (
    <PageHeading
        id="AllMeasuredBuildings"
        to="Navigation"
        prefix="4."
    >
        Analiza dla budynków opomiarowanych
    </PageHeading>)}
    <PageHeading
        id={genReportLink(data.buildingId)}
        to="Navigation"
        prefix={`4.${id + 1}.`}
    >
        {data.fullName}
    </PageHeading>
    <ChartHolder>
        <WrappedChart {...passedChartConfig(data, "climateIndex")}/>
    </ChartHolder>
    <ChartHolder>
        <WrappedChart {...passedChartConfig(data, "temperatures")}/>
    </ChartHolder>
    <ChartHolder>
        <WrappedChart {...passedChartConfig(data, "heatingCurve")}/>
    </ChartHolder>
    <ChartHolder>
        <WrappedChart {...passedChartConfig(data, "hourlyElectricity")}/>
    </ChartHolder>
    <ChartHolder>
        <WrappedChart {...passedChartConfig(data, "hourlyHeat")}/>
    </ChartHolder>
    <Conclusion
        comment={comments[data.buildingId]}
        refId={`${data.buildingId}`}
    />
</Page>);

const passedChartConfig = (data, key) =>
({
    data: data[key].data,
    ...chartConfig.measuredBuilding[key](data[key]),
});
