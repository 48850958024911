import React, { useState, useContext, useEffect } from 'react';
import { Input, Button, Form } from 'antd';
import styled from 'styled-components';
import { omit } from 'lodash';

import CheckboxField from '../../../../components/CRUDs/CheckboxField';
import TextField from '../../../../components/CRUDs/TextField';
import { AdministrationContext } from '../../AdministrationDataProvider';

const ROLES = {
  USER: 2,
  PROVIDER: 3,
};

const UserForm = ({
  editable,
  initialState,
  actionLabel,
  onCancel,
  onAction,
}) => {
  const data = initialState || {
    login: '',
    name: '',
    reportEmail: '',
    contactPerson: '',
    contactPhone: '',
    address: '',
    appHeader: '',
    weatherStationName: '',
    roleId: ROLES.USER,
    password: '',
    passwordRepeat: '',
  };

  const { mode } = useContext(AdministrationContext);

  const [formData, setFormData] = useState(data);
  const [passwordError, setPasswordError] = useState(null);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const onChange = (fieldName, e) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
    });
  };

  const handleAction = () => {
    switch (mode) {
      case 'changePassword':
        onAction(data.id, {
          newPassword: formData.password,
        });
        break;
      case 'create':
        if (isPasswordValid) {
          onAction(omit(formData, ['passwordRepeat']));
        }
        break;
      default:
        onAction(omit(formData, ['passwordRepeat']));
    }
    onCancel();
  };

  const handleRoleCheck = e => {
    const roleId = e.target.checked ? ROLES.PROVIDER : ROLES.USER;
    setFormData({
      ...formData,
      roleId,
    });
  };

  const textProps = {
    editable,
    formData,
    onChange,
  };

  const validatePassword = pass => {
    const passwordRegexp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
    let isValid = true;
    let error = null;
    if (pass !== formData.passwordRepeat) {
      isValid = false;
      error = 'Hasła muszą być takie same';
    }
    if (!passwordRegexp.test(pass)) {
      isValid = false;
      error =
        'Hasło powinno mieć co najmniej: 6 znaków, w tym 1 małą literę , 1 dużą literę i 1 cyfrę';
    }

    return { isValid, error };
  };

  const handlePassword = e => {
    const newValue = e.target.value;
    setFormData({
      ...formData,
      password: newValue,
    });
  };

  const handlePasswordRepeat = e => {
    const newValue = e.target.value;
    setFormData({
      ...formData,
      passwordRepeat: newValue,
    });
  };

  useEffect(() => {
    const { isValid, error } = validatePassword(formData.password);
    setIsPasswordValid(isValid);
    setPasswordError(error);
  }, [formData.password, formData.passwordRepeat]);

  const passwordInputs = (label1, label2) => (
    <>
      <Form.Item label={label1}>
        <Input.Password value={formData.password} onChange={handlePassword} />
      </Form.Item>
      <Form.Item label={label2}>
        <Input.Password
          value={formData.passwordRepeat}
          onChange={handlePasswordRepeat}
        />
      </Form.Item>
      {!isPasswordValid && (
        <div
          style={{
            margin: 5,
            color: '#dd2222',
            marginLeft: 25,
            marginTop: -20,
          }}
        >
          {passwordError}
        </div>
      )}
    </>
  );

  const actionDisabled =
    ['changePassword', 'create'].includes(mode) && !isPasswordValid;
  const action = (
    <DeleteContainer>
      <Button onClick={onCancel}>Anuluj</Button>
      <Button type="primary" disabled={actionDisabled} onClick={handleAction}>
        {actionLabel}
      </Button>
    </DeleteContainer>
  );

  if (mode === 'changePassword') {
    return (
      <div>
        {passwordInputs('Nowe hasło', 'Powtórz nowe hasło')}
        {action}
      </div>
    );
  }

  if (mode === 'delete') {
    return (
      <>
        <div>Czy na pewno chcesz usunąć użytkownika?</div>
        {action}
      </>
    );
  }

  return (
    <div style={{paddingRight: '16px'}}>
      <TextField {...textProps} name="login" label="Login" />
      {mode === 'create' && passwordInputs('Hasło', 'Powtórz hasło')}
      <TextField {...textProps} name="name" label="Nazwa klienta" />
      <TextField {...textProps} name="reportEmail" label="E-mail" />
      <TextField
        {...textProps}
        name="contactPerson"
        label="Osoba do kontaktu"
      />
      <TextField {...textProps} name="contactPhone" label="Telefon" />
      <TextField {...textProps} name="address" label="Adres klienta" />
      <TextField {...textProps} name="appHeader" label="Nagłówek aplikacji" />
      <TextField
        {...textProps}
        name="weatherStationName"
        label="Nazwa stacji pogodowej"
      />
      <CheckboxField
        {...textProps}
        onChange={handleRoleCheck}
        value={formData.roleId === ROLES.PROVIDER}
      >
        Dostawca danych
      </CheckboxField>
      {action}
    </div>
  );
};

const DeleteContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 30px;
`;

export default UserForm;
