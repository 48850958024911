import axios from 'axios';

export const getHookUps = async () => {
  const resp = await axios.get('/HookUp');
  return resp;
};
export const postNewHookUp = async hookUp => {
  const resp = await axios.post('/HookUp', hookUp);
  return resp;
};
export const postHookUpUpdate = async hookUp => {
  const resp = await axios.put(`/HookUp/${hookUp.id}`, hookUp);
  return resp;
};
export const postHookUpRemoval = async hookUpId => {
  const resp = await axios.delete(`/HookUp/${hookUpId}`, {});
  return resp;
};
export const postDatabaseBuildingUpdate = async building => {
  const resp = await axios.put(`/Buildings/database/${building.id}`, building);
  return resp;
};
export const getInvoices = async () => {
  const resp = await axios.get('/Invoice');
  return resp;
};
export const postNewInvoice = async invoice => {
  const resp = await axios.post('/Invoice', invoice);
  return resp;
};
export const postInvoiceUpdate = async invoice => {
  const resp = await axios.put(`/Invoice/${invoice.id}`, invoice);
  return resp;
};
export const postInvoiceRemoval = async invoiceId => {
  const resp = await axios.delete(`/Invoice/${invoiceId}`, {});
  return resp;
};
