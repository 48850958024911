// Converts a #ffffff hex string into an [r,g,b] array
function hex2rgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
}

function rgb2hsl(color) {
  const r = color[0] / 255;
  const g = color[1] / 255;
  const b = color[2] / 255;

  const max = Math.max(r, g, b);

  const min = Math.min(r, g, b);
  let h;

  let s;

  const l = (max + min) / 2;

  if (max === min) {
    s = 0;
    h = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }

  return [h, s, l];
}

function interpolateHSL(color1, color2, factor) {
  const hsl1 = rgb2hsl(hex2rgb(color1));
  const hsl2 = rgb2hsl(hex2rgb(color2));
  for (let i = 0; i < 3; i++) {
    hsl1[i] += factor * (hsl2[i] - hsl1[i]);
  }
  return `hsl(${hsl1[0] * 360},${hsl1[1] * 100}%,${hsl1[2] * 100}%)`;
}

export default interpolateHSL;
