import React from 'react';
import styled from 'styled-components';

const List = ({prefix, children, title, ...props}) =>
    <StyledList {...props}>
        {title && (<Item prefix={prefix}>{title}</Item>)}
        {React.Children.map(children, (child, index) => 
            React.cloneElement(child, {prefix: prefix ? `${prefix}${index + 1}.`: `${index + 1}.`}),
            )}
    </StyledList>

const StyledList = styled.div`
    & + & {
        padding-top: 16px;
    }
`;

const Item = ({prefix, children, ...props}) => (
    <StyledItem {...props}>
        <Index>{prefix}</Index>
        {children}
    </StyledItem>
);

const StyledItem = styled.div`
    font-size: 20px;
    line-height: 28px;
    padding: 4px 0;
    @media print {
        color: black
    }
`;

const Index = styled.span`
    min-width: 16px;
    margin-right: 24px;
`;

Object.assign(List, {
    Item,
});

export default List;
