import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';

const acceptCookies = localStorage.getItem('acceptCookies');

const cookieLink = "https://www.kape.gov.pl/page/polityka-prywatnosci-i-cookies";

export default () => {
    const [accepted, setAccepted] = useState(acceptCookies);

    const onAccept = () => {
        setAccepted("true");
        localStorage.setItem('acceptCookies', "true");
    }

    return !accepted ? (
        <Layer>
            <Container>
                <Row gutter={30}>
                    <Col span={20}>
                        <Info>
                            Korzystanie z witryny oznacza zgodę na wykorzystanie plików cookie z których niektóre mogą być już zapisane w folderze przeglądarki.
                            Więcej informacji pod adresem <Link href={cookieLink}>polityki prywatności</Link>. W celu ich wyłączenia, skonfiguruj odpowiednio przeglądarkę.
                        </Info>
                    </Col>
                    <Col span={4}>
                        <Button onClick={onAccept}>
                            Ok
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Layer>) : null;
}

const Container = styled.div`
    width: 1170px;
    max-width: 100%;
    box-sizing: border-box;

    margin-right: auto;
    margin-left: auto;
    padding: 20px 15px;

    @media (max-width: 991px) {
        width: 970px;
    }
    @media (max-width: 767px) {
        width: 750px;
    }
`;

const Layer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #324465;
    color: #ffffff;
    width: 100%;
    z-index: 1;
`;

const Info = styled.div`
    font-size: 16px;
    color: white;
`;

const Link = styled.a`
    color: #0099ff;
    text-decoration: none;
    &:hover, &:active {
        color: #0099ff !important;
        text-decoration: underline;
    }
`;

const Button = styled.a`
    height: 60px;
    width: 80px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 5px;
    border: solid 1px transparent;
    transition: all 0.3s ease-in-out;
    color: #ffffff;
    background-color: #9ea5ab;
    border-color: #90989f;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.3;
    box-sizing: border-box;
    text-decoration: none;
    user-select: none;
    &:hover {
        color: white !important;
        background: #838c93;
        border-color: #838c93;
    }
}
`;
