import React, { useState, useEffect } from 'react';
import axios from 'axios';
import to from 'await-to-js';
import { notification } from 'antd';
import {
  postSignIn,
  postSignInByToken,
  getEnums,
} from './scenes/SignIn/services/api';
import { getBuildings, getUsers } from './scenes/Administration/services/api';
import { getActiveAlarms } from './scenes/Alarms/services/api';

import { Refresh } from './components/Refresh';

export const clearStorageOnLogout = () => {
  localStorage.removeItem('user-token');
  localStorage.removeItem('is-admin');
}

const getDependentUsers = async isAdmin => {
  if (isAdmin) {
    return to(getUsers());
  }
  return [null, { data: [] }];
};

const getBaseData = async isAdmin => {
  try {
    const [
      [err, buildings],
      [enumErr, enums],
      [userErr, users],
      [alarmsErr, alarms],
    ] = await Promise.all([
      to(getBuildings()),
      to(getEnums()),
      getDependentUsers(isAdmin),
      to(getActiveAlarms()),
    ]);
    if (err || enumErr || userErr || alarmsErr) {
      throw new Error('Resourses not loaded');
    }
    const result = {
      enums: enums.data,
      buildings: buildings.data,
      users: users.data,
      alarms: alarms.data,
    };
    return result;
  } catch {
    clearStorageOnLogout();
    return undefined;
  }
};

const AppContext = React.createContext({});

const AppDataProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const startAsyncTask = message => setIsLoading(message || true);
  const finishAsyncTask = () => setIsLoading(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState();
  const [anyActiveAlarms, setAnyActiveAlarms] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [appHeader, setAppHeader] = useState();
  const [weatherStationName, setWeatherStationName] = useState();
  const [enums, setEnums] = useState();

  const wrapRequest = async (
    request,
    { ignoreError = false, onError, onSuccess } = {},
  ) => {
    try {
      const response = await request();
      if (onSuccess) {
        await onSuccess(response.data);
      }
      return undefined;
    } catch (error) {
      if (!error.response && error.request && ignoreError) {
        setIsLoading(false);
        setError({
          title: 'Napotkano problem z wykonaniem zapytania',
          message:
            'Sprawdź połączenie z internetem. Jeśli masz dostęp do sieci, prosimy o skontaktowanie się z administratorem strony.',
          footer: <Refresh />,
        });
        return undefined;
      }
      if (!ignoreError) {
        if (onError) {
          await onError(error.response);
          return undefined;
        }
        setIsLoading(false);
        setError({
          title: 'Napotkano problem z wykonaniem zapytania',
          message:
            'Serwis zwrócił nieoczekiwany błąd. Jeśli sytuacja będzie się powtarzać, prosimy o kontakt z administratorem w celu wyeliminowania błędu.',
          footer: <Refresh />,
        });
      }
    }
    return undefined;
  };

  const clearError = () => setError(null);

  const tryLogin = async loginData => {
    startAsyncTask();
    const [err, resp] = await to(
      loginData
        ? postSignIn(loginData.email, loginData.password)
        : postSignInByToken(),
    );
    if (err) {
      notification.error({ message: 'Nie udało się zalogować' });
      clearStorageOnLogout();
      finishAsyncTask();
      return;
    }
    const { data, status } = resp;
    if (status === 200) {
      const isAdmin = data.roleId === 1;
      const token = data.token;
      localStorage.setItem('user-token', token);
      localStorage.setItem('is-admin', isAdmin);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      notification.success({ message: 'Pomyślnie zalogowano' });
      const baseData = await getBaseData(isAdmin);
      if (baseData) {
        notification.success({
          message: 'Pomyślnie pobrano dane o użytkowniku',
        });
        setEmail(data.reportEmail);
        setUserId(data.login);
        setIsAdmin(isAdmin);
        setBuildings(baseData.buildings);
        setEnums(baseData.enums);
        setUsers(baseData.users);
        setAnyActiveAlarms(baseData.alarms.length > 0);
        setAppHeader(data.appHeader);
        setWeatherStationName(data.weatherStationName);
        setIsSignedIn(true);
      } else {
        clearStorageOnLogout();
        notification.error({
          message: 'Nie udało się pobrać danych o użytkowniku',
        });
      }
    }
    finishAsyncTask();
  };

  const submitLogin = async (email, password) => {
    tryLogin({ email, password });
  };

  useEffect(() => {
    if (localStorage.getItem('user-token')) {
      tryLogin();
    }
  }, []);

  useEffect(() => {
    async function checkAlarms() {
      const [err, resp] = await to(getActiveAlarms());
      if (err) {
        throw new Error('Resourses not loaded');
      }
      setAnyActiveAlarms(resp.data.length > 0);
    }
    const id = setInterval(() => checkAlarms(), 1000 * 60 * 15);
    return () => clearInterval(id);
  }, []);

  const state = {
    isSignedIn,
    isAdmin,
    isLoading,
    email,
    error,
    userId,
    anyActiveAlarms,
    weatherStationName,
    appHeader,
    buildings,
    get measuredBuildings() {
      return buildings.filter(e => e.hasMetersInstalled);
    },
    enums,
    users,
    get standardUsers() {
      return users.filter(e => e.roleId === 2);
    },
    clearError,
    setError,
    startAsyncTask,
    finishAsyncTask,
    setIsSignedIn,
    setEmail,
    setUserId,
    setBuildings,
    setAnyActiveAlarms,
    setIsAdmin,
    setUsers,
    submitLogin,
    wrapRequest,
  };

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};

export { AppContext };
export default AppDataProvider;
