import axios from 'axios';
import moment from 'moment';

import { mapMulti, mapListData} from '../../Dashboard/services/api';


export const getReportData = async ({period, userId}) => {
  const request = {
    startDate: moment(period[0]).startOf('month').toISOString(),
    endDate:  moment(period[1]).endOf('month').toISOString(),
    userId: parseInt(userId.substring(5), 10),
  }
  const resp = await axios.post(`/Reports`, request);

  return {
    ...resp,
    data: {
      buildingsUsages: getBuildingsUsages(resp.data.buildingsUsages),
      pms: mapMulti({data: resp.data.pms || []}),
      config: {
        period,
        userId,
      },
      request,
    },
  }
};

const getBuildingsUsages = (buildingsUsages = []) => buildingsUsages.map(
  ({temperatures, heatingCurve, climateIndex, hourlyElectricity, hourlyHeat, ...rest}) => ({
    ...rest,
    unitElectricityTotal: rest.electricityTotal / rest.area,
    unitHeatTotal: rest.heatTotal / rest.area,
    unitWaterTotal: rest.waterTotal / rest.area,
    temperatures: mapMulti({
      data: temperatures || [],
      settings: {
        limitMin: rest.minTempThreshold,
        limitMax: rest.maxTempThreshold,
      },
    }, false),
    heatingCurve: mapMulti({data: heatingCurve || []}, false),
    climateIndex: mapMulti({data: climateIndex.map(({time, cold, good, hot}) => ({
      time,
      cold: 100 * cold,
      good: 100 * good,
      hot: 100 * hot,
    })) || []}),
    hourlyElectricity: mapListData(hourlyElectricity || [], 'electricityPower'),
    hourlyHeat: mapListData(hourlyHeat || [], 'heatPower'),
  }));
