import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { filter, isNil } from 'lodash';
import { Button, Select, DatePicker, message } from 'antd';

import { AppContext } from '../../../../../../AppDataProvider';
import { AnalysisContext } from '../../../../AnalysisDataProvider';

import { getLineChartData } from '../../../../services/api';

import { MODES } from '../utils/constants';

import DisabledInput from '../../../../../../components/DisabledInput';
import {
  BuildingSelect,
  UserSelect,
} from '../../../../../../components/BuildingSelect';
import FormItemWrapper, {
  validate,
} from '../../../../../../components/FormItemWrapper';
import {
  FormFooter,
  FormInner,
  StyledFrom,
} from '../../../../components/StyledForm';
import MeasurementsPicker, {
  getMeasurementLabel,
} from '../../../../components/MeasurementsPicker';

const { Option } = Select;
const { RangePicker } = DatePicker;

const isDateAfter = date => moment().isBefore(date);

const LoadDataForm = Object.assign(
  ({ mode, editedData, onClose, loadData, updateData, now }) => {
    const isEdit = mode === MODES.EDIT;

    const {
      buildings,
      users,
      isAdmin,
      startAsyncTask,
      finishAsyncTask,
      wrapRequest,
    } = useContext(AppContext);
    const { buildingsMeasurements, fetchMeasurements } = useContext(
      AnalysisContext,
    );

    const [triggered, setTriggered] = useState(false);
    const [_value, setValue] = useState(editedData);

    const formSettings = {
      isEdit,
      triggered,
    };

    useEffect(() => {
      setValue(editedData);
    }, [editedData]);

    const updateWithValid = (data, isValid) =>
      isValid
        ? updateData({ ...editedData, ...data })
        : setValue({ ..._value, ...data });

    const update = key => (data, isValid) =>
      updateWithValid({ [key]: data }, isValid);

    const changeUser = (user, isValid) =>
      updateWithValid(
        {
          user,
          ...(user !== undefined && {
            building: undefined,
            measurements: undefined,
          }),
        },
        isValid,
      );

    const changePeriodType = (periodType, isValid) =>
      updateWithValid(
        {
          periodType,
          ...(!_value[periodType] && {
            [periodType]: periods[periodType].default,
          }),
        },
        isValid,
      );

    const getBuildingShortcut = building => {
      const found = buildings.find(e => e.id === building);
      if (!isAdmin) {
        return found && found.shortName;
      }
      const user = users.find(c => c.buildingsIds.includes(building));
      return user && found && `${user.name} - ${found.shortName}`;
    };

    const updateMeasurementsOnEdit = value =>
      setValue({ ..._value, measurements: value });

    const changeBuilding = (building, isValid) => {
      if (isValid && !isNil(building)) {
        fetchMeasurements(building);
      }
      updateWithValid(
        {
          building,
          measurements: undefined,
        },
        isValid,
      );
    };

    const onReset = () => {
      setTriggered(false);
      if (isEdit) {
        setValue(editedData);
      } else {
        updateData(genDefaultData());
      }
    };

    const onSubmit = async event => {
      event.preventDefault();
      const toValidate = ['building', 'periodType', 'measurements'];
      if (_value.periodType && periods[_value.periodType].element)
        toValidate.push(_value.periodType);
      const isValid = toValidate
        .map(key =>
          validate({
            required: true,
            value: _value[key],
            rules: setupSettings[key] ? setupSettings[key].rules || [] : [],
          }),
        )
        .reduce(
          (prev, { validateStatus }) => prev && validateStatus !== 'error',
          true,
        );
      if (!isValid) {
        setTriggered(true);
      } else {
        const request = {
          building: _value.building,
          ...periods[_value.periodType].formatter(
            _value[_value.periodType],
            now,
          ),
          measurements: isEdit
            ? filter(
                _value.measurements,
                e => !editedData.measurements.includes(e),
              )
            : _value.measurements,
        };
        if (request.measurements.length) {
          startAsyncTask(
            'Pobieranie danych z bazy. Operacja zajmuje dużo czasu przy obszernym zapytaniu.',
          );
          await wrapRequest(() => getLineChartData(request), {
            onError: (error = {}) => {
              message.error(
                error.data ||
                  'Nie udało się pobrać danych do wykresów. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.',
              );
            },
            onSuccess: data => {
              loadData({
                fields: {
                  ..._value,
                  description: getBuildingShortcut(_value.building),
                  measurementsLabels: request.measurements.map(m =>
                    getMeasurementLabel(
                      m,
                      buildingsMeasurements[_value.building],
                    ),
                  ),
                },
                data,
              });
              onClose();
              onReset();
            },
          });
          finishAsyncTask();
        } else {
          onClose();
          onReset();
        }
      }
    };

    return (
      <StyledFrom
        layout="vertical"
        hideRequiredMark={false}
        onSubmit={onSubmit}
      >
        <FormInner>
          {isAdmin && (
            <FormItemWrapper
              value={_value.user}
              onChange={changeUser}
              {...formSettings}
              {...setupSettings.user}
            >
              <UserSelect allowClear />
            </FormItemWrapper>
          )}
          <FormItemWrapper
            value={_value.building}
            onChange={changeBuilding}
            {...formSettings}
            {...setupSettings.building}
          >
            <BuildingSelect selectedUser={_value.user} />
          </FormItemWrapper>
          <FormItemWrapper
            label="Nazwa zestawu"
            value={getBuildingShortcut(_value.building)}
          >
            <DisabledInput placeholder="Wygenerowana nazwa zestawu" />
          </FormItemWrapper>
          <FormItemWrapper
            value={_value.periodType}
            onChange={changePeriodType}
            {...formSettings}
            {...setupSettings.periodType}
          >
            <PeriodSelect />
          </FormItemWrapper>
          {_value.periodType && periods[_value.periodType].element && (
            <FormItemWrapper
              onChange={update(_value.periodType)}
              value={_value[_value.periodType]}
              {...extractPeriodSettings(periods[_value.periodType])}
              {...formSettings}
            >
              {periods[_value.periodType].element()}
            </FormItemWrapper>
          )}
          <FormItemWrapper
            value={_value.measurements}
            onChange={
              isEdit ? updateMeasurementsOnEdit : update('measurements')
            }
            {...formSettings}
            {...setupSettings.measurements}
          >
            <MeasurementsPicker
              disabledValues={isEdit ? editedData.measurements : []}
              building={_value.building}
            />
          </FormItemWrapper>
        </FormInner>
        <FormFooter>
          <Button onClick={onReset} style={{ marginRight: 16 }}>
            Zresetuj
          </Button>
          <Button
            type="primary"
            icon={isEdit ? 'redo' : 'plus'}
            htmlType="submit"
          >
            {isEdit ? 'Aktualizuj' : 'Dodaj dane'}
          </Button>
        </FormFooter>
      </StyledFrom>
    );
  },
  {
    propTypes: {
      mode: PropTypes.oneOf([MODES.EDIT, MODES.LOAD]).isRequired,
      editedData: PropTypes.shape({
        building: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      onClose: PropTypes.func,
      loadData: PropTypes.func,
      updateData: PropTypes.func,
    },
  },
);

const setupSettings = {
  user: {
    label: 'Wybór gminy',
    disableOnEdit: true,
    hasIcon: true,
  },
  building: {
    label: 'Wybór budynku',
    required: true,
    disableOnEdit: true,
    hasIcon: true,
  },
  periodType: {
    label: 'Wybór zakresu danych',
    required: true,
    disableOnEdit: true,
  },
  measurements: {
    label: 'Wybór możliwych danych',
    required: true,
    rules: [
      {
        validate: v => v.length,
        errorMessage: 'Wybierz min. 1 zestaw danych',
      },
    ],
  },
};

const PeriodSelect = ({ ...props }) => (
  <Select placeholder="Rodzaj zakresu danych" {...props}>
    {Object.keys(periods).map(e => (
      <Option key={e} value={e}>
        {periods[e].description}
      </Option>
    ))}
  </Select>
);

const extractPeriodSettings = ({ label, rules }) => ({
  label,
  rules,
  required: true,
  disableOnEdit: true,
});

const periods = {
  day: {
    description: '1 dzien',
    formatter: (value, now) => ({
      from: now
        .clone()
        .subtract(1, 'days')
        .format(),
      to: now.format(),
    }),
  },
  days3: {
    description: '3 dni',
    formatter: (value, now) => ({
      from: now
        .clone()
        .subtract(3, 'days')
        .format(),
      to: now.format(),
    }),
  },
  week: {
    description: '1 tydzień',
    formatter: (value, now) => ({
      from: now
        .clone()
        .subtract(1, 'weeks')
        .format(),
      to: now.format(),
    }),
  },
  weeks2: {
    description: '2 tygodnie',
    formatter: (value, now) => ({
      from: now
        .clone()
        .subtract(2, 'weeks')
        .format(),
      to: now.format(),
    }),
  },
  month: {
    description: '1 miesiąc',
    formatter: (value, now) => ({
      from: now
        .clone()
        .subtract(1, 'months')
        .format(),
      to: now.format(),
    }),
  },
  months3: {
    description: '3 miesiące',
    formatter: (value, now) => ({
      from: now
        .clone()
        .subtract(3, 'months')
        .format(),
      to: now.format(),
    }),
  },
  year: {
    description: '1 rok',
    formatter: (value, now) => ({
      from: now
        .clone()
        .subtract(1, 'years')
        .format(),
      to: now.format(),
    }),
  },
  userDefined: {
    description: 'Inny zakres',
    default: [moment().subtract(1, 'months'), moment()],
    label: 'Wybór zakresu dni',
    name: 'range-picker',
    element: ({ ...props }) => (
      <RangePicker disabledDate={isDateAfter} {...props} />
    ),
    formatter: values => ({
      from: values[0].startOf('day').format(),
      to: values[1].endOf('day').format(),
    }),
  },
};

const genDefaultData = () => ({
  periodType: 'month',
});

export default Object.assign(
  ({ mode, editedData, onClose, loadData, updateData, now }) => {
    const isEdit = mode === MODES.EDIT;
    const [newData, updateNewData] = useState(genDefaultData());
    return (
      <LoadDataForm
        mode={mode}
        onClose={onClose}
        editedData={isEdit ? editedData.fields : newData}
        updateData={isEdit ? updateData : updateNewData}
        loadData={loadData}
        now={now}
      />
    );
  },
  {
    propTypes: {
      mode: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      editedData: PropTypes.object,
      onClose: PropTypes.func,
      loadData: PropTypes.func,
      updateData: PropTypes.func,
    },
  },
);
