import styled from 'styled-components';
import { StyledRegresionSummary } from './RegresionSummary';

export const StyledChart = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .recharts-cartesian-axis-tick {
    font-size: 13px;
    @media (max-width: 1600px) {
        font-size: 12px;
    }
    @media (max-width: 1440px) {
        font-size: 11px;
    }
    @media (max-width: 1366px) {
      font-size: 10px;
    }
    @media print {
      font-size: 16px !important;
    }
  }
  .recharts-tooltip-wrapper {
    z-index: 100;
  }
  ${StyledRegresionSummary} {
    display: ${({showRegression}) => showRegression ? 'block' : 'none'};
  }
  &:hover {
    ${StyledRegresionSummary} {
      display: block;
    }
  }
`;

const calculateHeight = ({showTitle, legendItems}, size) =>
  (v => v > 0 ? `calc(100% - ${v}px)` : '100%')((showTitle ? 40 : 0) + ((legendItems || 0) * size));

export const Container = styled.div`
  width: 100%;
  height: ${({showTitle, legendItems}) => calculateHeight({showTitle, legendItems}, 16)}
`;

export const ChartTitle = styled.div`
  height: 40px;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 4px;
`;

export const RegresionContainer = styled.div`
  position: absolute;
  ${({regresionPosition}) => regresionPosition === "left"
  ? `left: 80px;
    text-align: left;`
  : `right: 16px;
    text-align: right;`}
  bottom: 80px;
  background: white;
  max-width: 208px;
`;
