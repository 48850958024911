import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isNil, isArray } from 'lodash';
import { Form } from 'antd';

const Item = Form.Item;

export const validate = ({
    showSuccess,
    required,
    rules,
    value,
}) => {
    const isNull = isNil(value) || value === '';
    const onSuccess = showSuccess ? 'success' : undefined;
    if(!required && isNull) {
        return {};
    }
    if(required && (isNull || (isArray(value) && value.length === 0))) {
        return {
            validateStatus: 'error',
            help: 'Podaj wymaganą wartość',
        }
    }
    let isValid = true;
    let errorMessage;
    for(let i = 0; i < rules.length; i+=1) {
        const result = rules[i].validate(value);
        if(!result) {
            errorMessage = rules[i].errorMessage || errorMessage;
            isValid = false;
            break;
        }
    }
    return {
        validateStatus: isValid ? onSuccess : 'error',
        help: errorMessage,
    };
}

export default Object.assign(({
    onChange,
    value,
    valueKey = 'value',
    children,
    rules = [],
    triggered,
    showSuccess,
    required,
    isEdit,
    disableOnEdit,
    fetching,
    disableOnFetching,
    ...props
}) => {
    const isNull = isNil(value) || value === '';
    const [_triggered, setTrigger] = useState(triggered);

    useEffect(() => {
        setTrigger(triggered);
    }, [triggered]);

    const { onBlur } = children.props;
    React.Children.only(children);
    const wrappedBlur = (v) => {
        if(onBlur) onBlur(v);
        setTrigger(true);
    }
    const validateFun = (v) => validate({
        showSuccess,
        required,
        rules,
        value: v,
    });
    const wrappedOnChange = (v) => {
        let value = v;
        if(v.target) {
            if(v.target.type === "checkbox") {
                value = v.target.checked;
            }
            if(v.target.type === "text") {
                value = v.target.value;
            }
        }
        onChange(value, validateFun(value).validateStatus !== 'error');
    }
    return (<StyledItem
        {...((!isNull || _triggered) && validateFun(value))}
        {...props}
        >
        {React.cloneElement(
            children,
            {
                onBlur: wrappedBlur,
                [valueKey]: value,
                onChange: wrappedOnChange,
                disabled: (isEdit && disableOnEdit) || (fetching && disableOnFetching),
            },
        )}
    </StyledItem>);
},
{
    propTypes: {
        rules: PropTypes.arrayOf(
            PropTypes.shape({
                validate: PropTypes.func.isRequired,
                errorMessage: PropTypes.string,
            }),
        ),
        showSuccess: PropTypes.bool,
    },
});

const StyledItem = styled(Item)`
    ${({hasIcon}) => hasIcon ? `.ant-form-explain {
        padding-left: 36px;
    }` : ''}
`;
