import React from 'react';
import { Input, Form } from 'antd';
import DisabledInput from '../DisabledInput';

const TextField = ({ name, label, formData, onChange, editable, customKey, ...rest }) => {
  return (
    <Form.Item label={label} {...rest}>
      <CrudInput
        value={formData[name]}
        onChange={e => onChange(name, e)}
        editable={editable}
        key={customKey}
      />
    </Form.Item>
  );
};

const CrudInput = ({ value, onChange, editable, ...rest }) => {
  return editable ? (
    <Input value={value} onChange={onChange} {...rest} />
  ) : (
    <DisabledInput {...rest} value={value || 'brak'} />
  );
};

export default TextField;
