import React from "react";
import { Select } from "antd";

const { Option } = Select;

export default ({...props}) => (
  <Select placeholder="Pozycja na wykresie" {...props}>
    <Option key="left" value="left">
      Po lewej stronie
    </Option>
    <Option key="right" value="right">
      Po prawej stronie
    </Option>
  </Select>
);
