import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
} from "antd";

import FormItemWrapper, { validate } from "../../../../../../components/FormItemWrapper";
import SelectxKey from "../../../../components/SelectxKey";
import { FormFooter, FormInner, StyledFrom } from "../../../../components/StyledForm";
import ColorSelect from "../../../../../../components/ColorSelect";
import { xAxisType, DataType, UsedDataType } from "../utils/types";
import VisibilitySwitch from "../../../../../../components/VisibilitySwitch";
import { withDelayedUpdate } from "../../../../../../utils/delayed";
import { getMeasurementLabel, getMeasurementUnit, getMultipleLabelFromLoaded } from "../../../../components/MeasurementsPicker";

const DelayedInput = withDelayedUpdate(Input);

// TODO usuniecie valueKey z formy i przeniesienei do setup
const XAxisForm = Object.assign(
({editedData, loadedData, usedData, onClose, updateData}) => {

  const [triggered, setTriggered] = useState(false);
  const [_value, setValue] = useState(editedData);

  const formSettings = {
    isEdit: true,
    triggered,
  };

  useEffect(() => {
    setValue(editedData);
  }, [editedData]);

  const updateWithValid = ((data, isValid) =>
    isValid ? updateData({...editedData, ...data}): setValue({..._value, ...data}));

  const update = (key) => ((data, isValid) => updateWithValid({[key]: data}, isValid));

  const onReset = () => {
    setTriggered(false);
    setValue(editedData);
  }

  const onChangeXKey = (data, isValid) =>
  updateWithValid({
    xKey: data,
    ...(isValid && {
      description: (data.includes('_')
      ? getMultipleLabelFromLoaded(data, loadedData)
      : getMeasurementLabel(data, [])),
      ...(data !== 'time' && {
        unit: getMeasurementUnit(data),
      }),
    }),
  }, isValid);

  const onSubmit = async (event) => {
    event.preventDefault();
    const toValidate = [
      'description',
      'color',
      'xKey',
      'unit',
    ];
    const isValid = toValidate.map((key) => validate({
      ...setupSettings[key],
      value: _value[key],
      rules: setupSettings[key] ? (setupSettings[key].rules || []) : [],
    })).reduce((prev, {validateStatus}) => prev && validateStatus !== 'error', true);
    if(!isValid) {
      setTriggered(true);
    }
    else {
      onClose();
      onReset();
    }
  }

  return (
    <StyledFrom
      layout="vertical"
      onSubmit={onSubmit}
      hideRequiredMark
    >
      <FormInner>
        <FormItemWrapper
          value={_value.description}
          onChange={update('description')}
          {...formSettings}
          {...setupSettings.description}
        >
          <DelayedInput placeholder="Opis osi" />
        </FormItemWrapper>
        <FormItemWrapper
          value={_value.showDescription}
          valueKey="checked"
          onChange={update('showDescription')}
          {...formSettings}
          {...setupSettings.showDescription}
        >
          <Checkbox>Pokaż nazwę osi na wykresie</Checkbox>
        </FormItemWrapper>
        <Row gutter={24}>
          <Col span={12}>
            <FormItemWrapper
              value={_value.color}
              onChange={update('color')}
              {...formSettings}
              {...setupSettings.color}
            >
              <ColorSelect />
            </FormItemWrapper>
          </Col>
        </Row>
        <FormItemWrapper
          value={_value.xKey}
          onChange={onChangeXKey}
          {...formSettings}
          {...setupSettings.xKey}
        >
          <SelectxKey loadedData={loadedData} />
        </FormItemWrapper>
        <VisibilitySwitch visible={_value.xKey === 'time'}>
          <Row gutter={24}>
            <Col span={12}>
              <FormItemWrapper
                value={_value.datesNumber}
                onChange={update('datesNumber')}
                {...formSettings}
                {...setupSettings.datesNumber}
              >
                <InputNumber
                    className="AutoInputNumber"
                    min={2}
                    max={8}
                    step={1}
                    precision={0}
                  />
              </FormItemWrapper>
            </Col>
            <Col span={12}>
              <FormItemWrapper
                value={_value.withTime}
                valueKey="checked"
                onChange={update('withTime')}
                {...formSettings}
                {...setupSettings.withTime}
              >
                <Checkbox>Pokaż godzinę</Checkbox>
              </FormItemWrapper>
            </Col>
          </Row>
        </VisibilitySwitch>
        <VisibilitySwitch visible={_value.xKey !== 'time'}>
          <FormItemWrapper
            value={_value.unit}
            onChange={update('unit')}
            {...formSettings}
            {...setupSettings.unit}
          >
            <DelayedInput placeholder="Jednostka" />
          </FormItemWrapper>
          <Form.Item
            label="Zarządzanie zakresem danych"
            help="Aby ustawić automatyczne wyliczanie, wpisz auto"
          >
            <Row gutter={24}>
              <Col span={12}>
                <FormItemWrapper
                  value={_value.rangeFrom}
                  onChange={update('rangeFrom')}
                  {...formSettings}
                  {...setupSettings.rangeFrom}
                >
                  <DelayedInput placeholder="Początek zakresu" />
                </FormItemWrapper>
              </Col>
              <Col span={12}>
                  <FormItemWrapper
                    value={_value.rangeTo}
                    onChange={update('rangeTo')}
                    {...formSettings}
                    {...setupSettings.rangeTo}
                  >
                    <DelayedInput placeholder="Koniec zakresu" />
                  </FormItemWrapper>
              </Col>
              <Col span={12}>
                  <FormItemWrapper
                    value={_value.rangeSpan}
                    onChange={update('rangeSpan')}
                    {...formSettings}
                    {...setupSettings.rangeSpan}
                  >
                    <DelayedInput placeholder="Krok" />
                  </FormItemWrapper>
              </Col>
            </Row>
          </Form.Item>
        </VisibilitySwitch>
      </FormInner>
      <FormFooter>
        <Button
          onClick={onReset}
          style={{ marginRight: 16 }}
        >
          Zresetuj
        </Button>
        <Button
          type="primary"
          icon="redo"
          htmlType="submit"
        >
          Zakończ
        </Button>
      </FormFooter>
    </StyledFrom>
  );
},
{
  propTypes: {
    editedData: PropTypes.shape(xAxisType).isRequired,
    loadedData: PropTypes.arrayOf(PropTypes.shape(DataType)).isRequired,
    usedData: PropTypes.arrayOf(PropTypes.shape(UsedDataType)).isRequired,
    onClose: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
  },
});

const setupSettings = {
  description: {
    label: "Opis osi",
    required: true,
    rules: [
      {
        validate: (v) => v.length >= 3,
        errorMessage: 'Wymagane min. 3 znaki',
      },
      {
        validate: (v) => v.length <= 20,
        errorMessage: 'Maksymalnie 20 znaków',
      },
    ],
  },
  showDescription: {},
  color: {
    label: "Kolor osi",
    required: true,
  },
  xKey: {
    label: 'Dane na osi X',
    required: true,
  },
  datesNumber: {
    label: "Minimalna ilość dat na osi",
    help: "Wyświetlna liczba może być mniejsza dla krótkich okresów",
    rules: [
      {
        validate: (v) => v >= 2,
        errorMessage: 'Wymagane min. 2 daty',
      },
      {
        validate: (v) => v <= 8,
        errorMessage: 'Maksymalnie 8 dat',
      },
    ],
  },
  withTime: {},
  unit: {
    label: "Jednostka",
    required: false,
    rules: [
      {
        validate: (v) => v.length <= 10,
        errorMessage: 'Maksymalnie 10 znaków',
      },
    ],
  },
  rangeFrom: {
    label: "Od",
    required: true,
  },
  rangeTo: {
    label: "Do",
    required: true,
  },
  rangeSpan: {
    label: "Krok",
    required: true,
  },
};

export default Object.assign(
({editedAxis, loadedData, usedData, onClose, updateData}) => {
  return (
    <XAxisForm
      editedData={editedAxis}
      onClose={onClose}
      updateData={updateData}
      loadedData={loadedData}
      usedData={usedData}
    />
  );
},
{
  propTypes: {
    editedAxis: PropTypes.shape(xAxisType).isRequired,
    loadedData: PropTypes.arrayOf(PropTypes.shape(DataType)).isRequired,
    usedData: PropTypes.arrayOf(PropTypes.shape(UsedDataType)).isRequired,
    onClose: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
  },
});
