import React from "react";
import {
  CartesianGrid,
  Area,
  AreaChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import PropTypes from "prop-types";
import { find } from "lodash";

import {
  chartRangesFromConfig,
  dateTicks,
} from "./utils/calculate";
import {
  dataFromXAxis,
  dataFromAxis,
  hasLeftAxis,
  hasRightAxis,
  isTimeChart,
} from "./utils/filters";
import {
  chartConfigType,
  usedDataType,
} from "./utils/chartTypes";
import CustomXAxis, { CustomTimeAxis } from "./CustomXAxis";
import CustomYAxis from "./CustomYAxis";
import CustomTooltip from "./CustomTooltip";
import CustomLegend from "./CustomLegend";
import { StyledChart, Container, ChartTitle } from './Common';
import MathParser from "../MathParser";

export default Object.assign(
  ({ usedData, data, chartConfig, ...props }) => {
    const { xAxis, settings, yAxises } = chartConfig;
    const {
      showTitle,
      title,
      stopAnimation,
      stopInteractive,
      horizontalGrid,
      verticalGrid,
      showLegend,
      xFormatter,
      yFormatter,
      stackData = true,
    } = settings;
    const xAxisRanges = xAxis.useDefault
    ? {
      domainMin: 'dataMin',
      domainMax: 'dataMax',
    }
    : (axisFun =>
      axisFun(dataFromXAxis(data, xAxis.xKey), xAxis))(
        isTimeChart(xAxis.xKey) ? dateTicks : chartRangesFromConfig,
      );
    const xAxisChoice = () => {
      if(xAxis.useDefault) return ({...prop}) => <XAxis {...prop} />;
        return isTimeChart(xAxis.xKey) ? CustomTimeAxis : CustomXAxis
      }
    
    return (
      <StyledChart>
        {showTitle && (
          <ChartTitle><MathParser>{title}</MathParser></ChartTitle>
        )}
        <Container showTitle={showTitle}>
          <ResponsiveContainer width="100%" height="100%" {...props}>
            <AreaChart
              data={data}
            >
              {!stopInteractive && (
                <Tooltip
                  content={
                    <CustomTooltip
                      withTime={xAxis.withTime}
                      xAxis={xAxis}
                      xFormatter={xFormatter}
                      yFormatter={yFormatter}
                    />
                  }
                />
              )}
              {showLegend && (
                CustomLegend()
              )}
              <CartesianGrid
                strokeDasharray="5 5"
                horizontal={horizontalGrid}
                vertical={!xAxisRanges.ticks ? verticalGrid : false}
              />
              {verticalGrid &&
                xAxisRanges.ticks &&
                xAxisRanges.ticks.map((x) => (
                  <ReferenceLine
                    key={`cartesian-x-${x}`}
                    x={x}
                    yAxisId={yAxises[0].id}
                    strokeDasharray="5 5"
                  />
                ))}
              {usedData.map(({ id, dotted, dataSource, ...used }) =>
                CustomArea({
                  yAxises,
                  ...used,
                  dataSource,
                  areaId: id,
                  stackData,
                  key: `usedData-${id}`,
                  ...(stopAnimation && {isAnimationActive: false}),
                }),
              )}
              {(axisComp =>
                axisComp({
                  ...xAxis,
                  dataKey: xAxis.xKey,
                  hasLeft: hasLeftAxis(yAxises),
                  hasRight: hasRightAxis(yAxises),
                  ranges: xAxisRanges,
                  ...(xFormatter && {tickFormatter: xFormatter}),
                  ...(stopAnimation && {isAnimationActive: false}),
                }))(xAxisChoice())
              }
              {// WONTFIX recharts ma problem z przeciazaniem komponentow
                yAxises.map(yAxis =>
                  CustomYAxis({
                    ...yAxis,
                    key: `yAxis-${yAxis.id}`,
                    dataSpread: dataFromAxis(data, usedData, yAxis.id, yAxis.maxMin, yAxis.minMax),
                    ...(yFormatter && {tickFormatter: yFormatter}),
                  }),
                )}
            </AreaChart>
          </ResponsiveContainer>
        </Container>
      </StyledChart>
    );
  },
  {
    propTypes: {
      chartConfig: PropTypes.shape(chartConfigType).isRequired,
      usedData: PropTypes.arrayOf(PropTypes.shape(usedDataType)).isRequired,
      data: PropTypes.arrayOf(PropTypes.any),
    },
  });

const CustomArea = ({
  yAxises,
  description,
  areaId,
  axis,
  dataSource,
  lineColor,
  lineWidth,
  unit,
  stackData,
  ...props
}) => (
    <Area
      activeDot
      type="linear"
      connectNulls
      unit={(e => (e && e.unit) || unit)(find(yAxises, e => e.id === axis))}
      name={description}
      id={areaId}
      stackId={stackData ? "1" : undefined}
      stroke={lineColor}
      fill={lineColor}
      dot={false}
      dataKey={dataSource}
      yAxisId={axis}
      {...props}
    />
  );
