import React from 'react';
import { Tabs } from 'antd';

import ContentWrapper from '../../components/ContentWrapper';
import Database from './scenes/Database';
import MyBuildings from './scenes/MyBuildings';

const Benchmarking = () => {
  return (
    <ContentWrapper style={{padding: '20px 20px 0', height: '100%'}}>
      <Tabs defaultActiveKey="myBuildings">
        <Tabs.TabPane tab="Moje budynki" key="myBuildings">
          <MyBuildings />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Baza danych" key="database">
          <Database />
        </Tabs.TabPane>
      </Tabs>
    </ContentWrapper>
  );
};

export default Benchmarking;
