import React, { useState, useEffect, useContext } from 'react';
import { InputNumber, Tabs, Button } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { AdministrationContext } from '../../AdministrationDataProvider';

import { mapRangeTypeToColor } from './formConfig';

function getInitialPostData(config) {
  const indicatorsList = config
    .flatMap(category => category.indicators)
    .flatMap(indicator =>
      indicator.minMax ? [indicator.min, indicator.max] : indicator.model,
    )
    .map(indicator => ({
      name: uncapitalize(indicator.name),
      value: indicator.value,
    }));

  return indicatorsList.reduce(
    (acc, curr) => ({
      [curr.name]: curr.value,
      ...acc,
    }),
    {},
  );
}

const IndicatorsForm = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [indicatorsPostData, setIndicatorsPostData] = useState({});
  const { fetchIndicators, updateIndicators, indicatorsCategories } = useContext(
    AdministrationContext,
  );

  const handleChange = fieldName => value => {
    const newIndicators = {
      ...indicatorsPostData,
      [fieldName]: value,
    };
    setIndicatorsPostData(newIndicators);
  };

  useEffect(() => {
    fetchIndicators();
  }, []);

  useEffect(() => {
    if (isEmpty(indicatorsPostData)) {
      const data = getInitialPostData(indicatorsCategories);
      setIndicatorsPostData(data);
    }
  }, [indicatorsCategories]);

  const handleSave = async () => {
    setIsEditing(!isEditing);
    if (isEditing) {
      updateIndicators(indicatorsPostData);
    }
  };

  const btnLabel = isEditing ? 'Zapisz' : 'Edytuj';
  return indicatorsCategories.length > 0 ? (
    <div>
      <Tabs defaultActiveKey={indicatorsCategories[0].categoryName}>
        {indicatorsCategories.map(category => (
          <Tabs.TabPane tab={category.categoryName} key={category.categoryName}>
            <Container>
              <tbody>
              {category.indicators &&
                category.indicators.map((indicator, idx) => (
                  <Indicator
                    key={`${idx}`}
                    isEditing={isEditing}
                    indicator={indicator}
                    onChange={handleChange}
                    indicatorsPostData={indicatorsPostData}
                  />
                ))}
              </tbody>
            </Container>
          </Tabs.TabPane>
        ))}
      </Tabs>
      <Button
        type="primary"
        style={{ marginTop: 30 }}
        onClick={handleSave}
      >
        {btnLabel}
      </Button>
    </div>
  ) : null;
};

const uncapitalize = str => str.charAt(0).toLowerCase() + str.slice(1);

const Indicator = ({ indicator, isEditing, onChange, indicatorsPostData }) => {
  const { label, unit, minMax, rangeType } = indicator;
  let inputs;
  if (minMax) {
    const { min, max } = indicator;
    const minFieldName = uncapitalize(min.name);
    const maxFieldName = uncapitalize(max.name);
    const minValue = indicatorsPostData[minFieldName] || min.value;
    const maxValue = indicatorsPostData[maxFieldName] || max.value;
    inputs = (
      <>
        <InputNumber
          disabled={!isEditing}
          style={{ margin: '0 12px' }}
          onChange={onChange(minFieldName)}
          step={0.01}
          value={minValue}
        />
        <span>min</span>
        <InputNumber
          disabled={!isEditing}
          style={{ margin: '0 12px' }}
          onChange={onChange(maxFieldName)}
          step={0.01}
          value={maxValue}
        />
        <span>max</span>
      </>
    );
  } else {
    const { model } = indicator;
    const fieldName = uncapitalize(model.name);
    const value = indicatorsPostData[fieldName] || model.value;
    inputs = (
      <InputNumber
        onChange={onChange(fieldName)}
        disabled={!isEditing}
        step={0.01}
        value={value}
      />
    );
  }

  const color = mapRangeTypeToColor(rangeType);
  return (
    <Row>
      <Cell>
        <Label color={color}>{label}</Label>
      </Cell>
      <Cell>
        {inputs}
      </Cell>
      <Cell>
        <Unit>{unit}</Unit>
      </Cell>
    </Row>
  );
};

const Container = styled.table`
  border-collapse: collapse;
  border: none;
  table-layout: auto;
  max-width: 100%;
`;

const Row = styled.tr``;
const Cell = styled.td`
  padding: 8px 0;
`;

const Label = styled.div`
  padding-right: 30px;
  font-weight: bold;
  color: ${p => p.color || 'black'};
`;

const Unit = styled.div`
  padding-left: 20px;
  font-weight: bold;
`;

export default IndicatorsForm;
