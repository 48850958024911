import React from 'react';
import { Form, InputNumber } from 'antd';
import DisabledInputNumber from '../DisabledInputNumber';

const NumberField = ({
  name,
  label,
  formData,
  onChange,
  editable,
  ...rest
}) => {
  return (
    <Form.Item label={label}>
      <CrudInputNumber
        value={formData[name]}
        onChange={e => onChange(name, e)}
        editable={editable}
        {...rest}
      />
    </Form.Item>
  );
};

const CrudInputNumber = ({ value, onChange, editable, ...rest }) => {
  return editable ? (
    <InputNumber value={value} onChange={onChange} {...rest} />
  ) : (
    <DisabledInputNumber {...rest} value={value || 'brak'} />
  );
};

export default NumberField;
