export default class Subscriber {
    constructor() {
        this.subscribed = false;
    }

    subscribed;

    subscribe = async (func, callback, unSubCallback) => {
        this.subscribed = true;
        const result = await func();
        if(this.subscribed) {
            callback(result);
        }
        else if(unSubCallback) {
            unSubCallback();
        }
    }

    unsubscribe = () => {
        this.subscribed = false;
    }
}
