import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { DatePicker } from 'antd';
import FormItemWrapper from './FormItemWrapper';

const { MonthPicker } = DatePicker;

export default ({onChange, value = [], formRules = {}}) => {
    const now = moment().startOf('month');

    const disabledDate = (time) => time.isSameOrAfter(now);

    const _onChange = ([first, second], isValid) => {
        if(!(first && second)) {
            onChange([first, second], isValid);
        }
        else {
            onChange(second.isBefore(first) ? [second, first] : [first, second], isValid);
        }
    }
    const onFirst = (v, isValid) => _onChange([v, value[1]], isValid);
    const onSecond = (v, isValid) => _onChange([value[0], v], isValid);

    return (
        <PeriodSelect>
            <Description>Od</Description>
            <FormItemWrapper
                value={value[0]}
                onChange={onFirst}
                {...formRules.from}
            >
                <MonthPicker disabledDate={disabledDate}/>
            </FormItemWrapper>
            <Description>Do</Description>
            <FormItemWrapper
                value={value[1]}
                onChange={onSecond}
                {...formRules.to}
            >
                <MonthPicker  disabledDate={disabledDate}/>
            </FormItemWrapper>
        </PeriodSelect>);
};

const PeriodSelect = styled.div`
    display: flex;
    margin-left: -24px;
`;

const Description = styled.span`
    margin: 0 8px 0 24px;
    font-weight: bold;
    line-height: 32px;
`;
