import React, { useState, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';
import { Button, DatePicker, Form } from 'antd';

import FormItemWrapper, { validate } from '../../../components/FormItemWrapper';

export default ({printRef, config = {}, onChange}) => {
    const [_date, setDate] = useState();

    useEffect(() => {
        setDate(config.date);
    }, [config.date]);

    const onSubmit = (event) => {
        event.preventDefault();
    }

    const isFormValid = () => {
        const toValidate = [
            {key: 'date', value: _date},
        ];
        return toValidate.map(({key, value}) => validate({
            ...formRules[key],
            value,
            rules: formRules[key] ? (formRules[key].rules || []) : [],
          })).reduce((prev, {validateStatus}) => prev && validateStatus !== 'error', true);
    }
    
    const onChangeDate = (per, isValid) => isValid
        ? onChange({...config, date: per})
        : setDate(per);

    return (
<ReportDowloadForm>
    <Form
        layout="vertical"
        hideRequiredMark={false}
        onSubmit={onSubmit}
    >
        <TopSelectItem>
            <FormItemWrapper
                onChange={onChangeDate}
                value={_date}
                {...formRules.date}
            >
                <DatePicker />
            </FormItemWrapper>
        </TopSelectItem>
        <TopSelectItem>
            <ButtonContainer>
                <ReactToPrint
                    trigger={() => (
                        <Button
                            disabled={!isFormValid()}
                            type="primary"
                        >Pobierz raport</Button>
                    )}
                    content={() => printRef}
                />
            </ButtonContainer>
        </TopSelectItem>
    </Form>
</ReportDowloadForm>);
}

const formRules = {
    date: {
        label: "Data powstania raportu",
        required: true,
    },
};

const ReportDowloadForm = styled.div`
    margin-bottom: -16px;
`;

const ButtonContainer = styled.div`
    padding: 0 0 0 16px;
`;

const TopSelectItem = styled.div`
    display: inline-block;
    vertical-align: middle;
    & + & {
        margin-left: 16px;
    }
`;
