import React, { useContext } from 'react';

import CrudList from '../../../../components/CRUDs/CrudList';
import { DbContext } from '../../DbDataProvider';

const BuildingsList = () => {
  const {
    setCurrentId,
    buildings,
    setMode,
    setKind,
    setIsModalVisible,
  } = useContext(DbContext);

  const showBuildingsModal = id => {
    setIsModalVisible(true);
    setCurrentId(id);
    setKind('Buildings');
  };

  const handleReadBuilding = id => {
    showBuildingsModal(id);
    setMode('read');
  };

  const handleUpdateBuilding = id => {
    showBuildingsModal(id);
    setMode('update');
  };

  return (
    <CrudList
      items={buildings.map(building => ({
        name: building.fullName,
        description: `${building.street}, ${building.city}`,
        id: building.id,
      }))}
      onRead={handleReadBuilding}
      onUpdate={handleUpdateBuilding}
    />
  );
};

export default BuildingsList;
