import React, { useContext } from 'react';
import styled from 'styled-components';
import { message } from 'antd';

import { BuildingSelect } from '../../../components/BuildingSelect';
import TimeSelect from '../components/TimeSelect';
import CurrentData from '../components/CurrentData';

import { AppContext } from '../../../AppDataProvider';
import { DashboardContext } from '../DashboardDataProvider';

import {
  getBuildingData,
  getBuildingTime,
} from '../services/api';

export default () => {
  const { wrapRequest } = useContext(AppContext);
  const {
    currentBuilding,
    currentBuildingId,
    currentTime,
    getBuilding,
    startFetchingData,
    startFetchingTime,
    endFetchingData,
    endFetchingTime,
    setCurrentBuilding,
    setCurrentTime,
  } = useContext(DashboardContext);

  const building = currentBuilding();

  const updateTime = async ({ target }) => {
    const { value } = target;
    if (!building[value]) {
      fetchBuildingTime(currentBuildingId, value);
    }
    setCurrentTime(value);
  };
  const updateBuilding = async id => {
    const selected = getBuilding(id);
    if (!selected.data) {
      fetchBuildingData(id);
    }
    if (!selected[currentTime]) {
      fetchBuildingTime(id, currentTime);
    }
    setCurrentBuilding(id);
  };

  async function fetchBuildingData(id) {
    startFetchingData(id);
    await wrapRequest(() => getBuildingData(id), {
      onError: () => {
        message.error('Nie udało się pobrać danych o zużyciach. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: (data) => {
        endFetchingData(id, data);
      },
    });
  }

  async function fetchBuildingTime(id, time) {
    startFetchingTime(id, time);
    await wrapRequest(() => getBuildingTime(id, time), {
      onError: () => {
        message.error('Nie udało się pobrać danych do wykresów. Jeśli sytuacja się będzie powtarzać, prośba o kontakt z administracją serwisu.');
      },
      onSuccess: (data) => {
        endFetchingTime(id, data, time);
      },
    });
  }

  const { isAdmin, weatherStationName, standardUsers } = useContext(AppContext);
  const selectedUser =
    currentBuildingId !== null
      ? standardUsers.find(e => e.buildingsIds.includes(currentBuildingId))
      : {};
  const fetchingData = !building.finished.data;
  return (
    <TopBar>
      <BuildingSelectContainer>
        <BuildingSelect
          onChange={updateBuilding}
          value={currentBuildingId}
        />
      </BuildingSelectContainer>
      <TimeSelectContainer>
        <TimeSelect onChange={updateTime} currentTime={currentTime} />
      </TimeSelectContainer>
      <CurrentDataContainer>
        <CurrentData
          weatherStationName={
            isAdmin ? selectedUser.weatherStationName : weatherStationName
          }
          buildingData={building.data}
          loading={fetchingData}
        />
      </CurrentDataContainer>
    </TopBar>
  );
};

const TimeSelectContainer = styled.div`
  padding: 0 24px;
`;

const CurrentDataContainer = styled.div`
  position: absolute;
  right: 20px;
  bottom: 12px;
`;

const BuildingSelectContainer = styled.div`
  width: 248px;
`;

const TopBar = styled.div`
  position: relative;
  height: 80px;
  padding: 24px 16px;
  display: flex;
  @media (max-height: 800px) {
    height: 64px;
    padding: 16px;
  }
`;
