import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { union, filter, sortBy } from "lodash";
import { Collapse, message } from "antd";

import LoadData from "./MenuElements/LoadData";
import UseData from "./MenuElements/UseData";
import ConfigChart from "./MenuElements/ConfigChart";

import {
  ConfigType,
  DataType,
  UsedDataType,
} from './utils/types';
import { extractEntry, generateDataSourcesSet } from "./utils/constants";
import { getUsedData, nextColorsPallete } from "./utils/defaults";

const Panel = Collapse.Panel;

const DisableLegendAlert = 7;

const getActiveStatus = ({ loadedData, usedData }) => {
  const isActiveUsedData = loadedData.length !== 0;
  const isActiveChartConfig = usedData.length !== 0;
  return {
    isActiveUsedData,
    isActiveChartConfig,
  };
};

class ChartSetup extends Component {
  // TODO przepisac na funkcjonalny
  constructor(props) {
    super(props);
    const { isActiveUsedData, isActiveChartConfig } = getActiveStatus(props);
    const activeKey = ["loadedData"];
    if (isActiveUsedData) {
      activeKey.push("usedData");
    }
    if (isActiveChartConfig) {
      activeKey.push("chartConfig");
    }
    this.state = { activeKey };
  }

  static propTypes = {
    chartConfig: PropTypes.shape(ConfigType).isRequired,
    loadedData: PropTypes.arrayOf(PropTypes.shape(DataType)).isRequired,
    update: PropTypes.func.isRequired,
    usedData: PropTypes.arrayOf(PropTypes.shape(UsedDataType)).isRequired,
  };

  componentDidUpdate(prevProps) {
    const updateActiveKeys = [];
    const removeActiveKeys = [];
    const activeStatus = getActiveStatus(this.props);
    const prevActiveStatus = getActiveStatus(prevProps);
    if (!prevActiveStatus.isActiveUsedData && activeStatus.isActiveUsedData) {
      updateActiveKeys.push("usedData");
    }
    if (prevActiveStatus.isActiveUsedData && !activeStatus.isActiveUsedData) {
      removeActiveKeys.push("usedData");
    }
    if (
      !prevActiveStatus.isActiveChartConfig &&
      activeStatus.isActiveChartConfig
    ) {
      updateActiveKeys.push("chartConfig");
    }
    if (
      prevActiveStatus.isActiveChartConfig &&
      !activeStatus.isActiveChartConfig
    ) {
      removeActiveKeys.push("chartConfig");
    }
    if (updateActiveKeys.length + removeActiveKeys.length > 0) {
      const result = union([...this.state.activeKey], updateActiveKeys);
      this.setState({
        activeKey: filter(result, e => !removeActiveKeys.includes(e))
      });
    }
  }

  changeActiveKey = newActiveKey => {
    this.setState({
      activeKey: newActiveKey,
    });
  };

  update = (key, newValue) => {
    this.setState({
      [key]: newValue,
    });
  };

  containsDataSource = (dataSource, loadedData) => {
    const [id, measurement] = extractEntry(dataSource);
    const fid = loadedData.findIndex(data => data.id === id);
    if(fid === -1) return false;
    return loadedData[fid].fields.measurements.includes(measurement);
  }

  updateLoadedData = newValue => {
    const filteredOld = filter(this.props.usedData,
      used => this.containsDataSource(used.dataSource, newValue));
    const allNew = newValue.reduce((prev, loaded) =>
    [...prev, ...generateDataSourcesSet(loaded)], []);
    const toAdd = filter(allNew,
      path => !filteredOld.find(({dataSource}) => path.dataSource === dataSource));
    const colorPallete = nextColorsPallete(filteredOld);
    const newUsedData = [...filteredOld, ...toAdd.map(
      ({dataSource, description}, idx) => getUsedData({
        dataSource,
        description,
        colorPallete,
        yAxises: this.props.chartConfig.yAxises,
      }, idx))];
    const canLegend = newUsedData.filter(e => e.show).length < DisableLegendAlert;
    if(!canLegend && this.props.chartConfig.settings) {
      message.warning('Ze względu na dużą liczbę danych ukryta została legenda. Można ją przywrócić w "Konfiguracji ogólnej wykresu"');
    }
    this.props.update({
      chartConfig: {
        ...this.props.chartConfig,
        settings: {
          ...this.props.chartConfig.settings,
          showLegend: this.props.chartConfig.settings && canLegend,
        },
      },
      loadedData: newValue,
      usedData: sortBy(newUsedData, ['dataSource']),
    });
  } 

  updateUsedData = newValue => this.props.update({
    usedData: newValue,
  });

  updateChartConfig = newValue => this.props.update({
    chartConfig: newValue,
  });

  render() {
    const { loadedData, usedData, chartConfig, now } = this.props;
    const { isActiveUsedData, isActiveChartConfig } = getActiveStatus(
      this.props,
    );
    return (
      <StyledChartSetup>
        <Collapse
          activeKey={this.state.activeKey}
          onChange={this.changeActiveKey}
          bordered={false}
        >
          <Panel header="Pobranie danych" key="loadedData">
            <LoadData
              update={this.updateLoadedData}
              usedData={usedData}
              data={loadedData}
              config={chartConfig}
              now={now}
            />
          </Panel>
          <Panel
            disabled={!isActiveUsedData}
            header="Załadowane dane"
            key="usedData"
          >
            <UseData
              update={this.updateUsedData}
              data={usedData}
              loadedData={loadedData}
              config={chartConfig}
            />
          </Panel>
          <Panel
            disabled={!isActiveChartConfig}
            header="Konfiguracja wykresu"
            key="chartConfig"
          >
            <ConfigChart
              update={this.updateChartConfig}
              loadedData={loadedData}
              usedData={usedData}
              config={chartConfig}
            />
          </Panel>
        </Collapse>
      </StyledChartSetup>
    );
  }
}

export default ChartSetup;

const StyledChartSetup = styled.div`
  & .ant-collapse-header {
    font-size: 1rem;
  }
`;
