import React from 'react';

import { Modal } from 'antd';

export default ({visible, onOk, onCancel, ...props}) =>
    (<Modal 
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        title="Czy chcesz wczytać dane?"
        {...props}
    >
        {`Wczytanie nowych danych spowoduje przeładowanie generacji raportu.
        Przy braku zapisu spowoduje to utratę wykonanej pracy.`}
    </Modal>);
